import React, { useState } from 'react';

function GenderSelection({handleChange,name,value}) {
  const [country, setCountry] = useState('');

  const handleCountryChange = (e) => {
    setCountry(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Selected country:', country);
  };

  return (
    <div >
      <form style={{display:"flex",gap:"14px",marginTop: "3px"}} onSubmit={handleSubmit}>
        <select style={{ border: "none",color: "#0041C4"}}
        id="countrySelect"
        value={value}
        onChange={handleChange}
        className="custom-select"
        name={name}
      >
        <option value="">-- Select --</option>
        <option value="Male"> <span role="img" aria-label="Smiling face with smiling eyes">👨</span> Male</option>
        <option value="Female"> <span role="img" aria-label="Smiling face with smiling eyes">👩</span> Female</option>
      </select>
      </form>
    </div>
  );
}

export default GenderSelection;