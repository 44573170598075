
import React from 'react';
import Ellipse from './../../../assets/Ellipse.png';
import Ellipse2 from './../../../assets/Ellipse2.png';
import Ellipse3 from './../../../assets/Ellipse3.png';
import Ellipse4 from './../../../assets/Ellipse4.png';
import Ellipse7 from './../../../assets/Ellipse7.png';
import Ellipse6 from './../../../assets/Ellipse6.png';
import Ellipse5 from './../../../assets/Ellipse5.png';
import option from './../../../assets/option.png';
import contact from './../../../assets/contact.png';
import zoomicon from './../../../assets/zoom-icon.png';
import './../../../../src/App.css';
import Sidebar from '../../../Components/Sidebar';
import Navbar from '../../../Components/Navbar';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

function MHCDashboard() {
    const navigate = useNavigate();
    const [sidemenu, setSidemenu] = useState(false);
  const signIn = (data) => {
    navigate(data);
  }
  const handleToggleSidemenu = () => {
    console.log("working");
    setSidemenu(!sidemenu);
  };
  return (
    <div className='main-section'>
        <div className={sidemenu ? '' : 'sidebar mob-resp'} >
            <Sidebar name={'MHC'} />
        </div>
        <div className='right-side'>
            <Navbar  navbarname={"Dashboard"} setSidemenu={handleToggleSidemenu}/>
                <div className='goat-boxes'>
                    <div className='goat-box'onClick={() => { signIn("/divisions") }}>
                        <div  className='icon-div' style={{ background: "#27AE60" }}>
                            <img  src={contact} alt="some text" />
                        </div>
                        <div className='box-text'>
                            <p>Divisons</p>
                            <h4>40</h4>
                        </div>
                    </div>
                    <div className='goat-box'onClick={() => { signIn("/providers") }}>
                        <div  className='icon-div' style={{ background: "#8E44AD" }}>
                            <img  src={contact} alt="some text" />
                        </div>
                        <div className='box-text'>
                            <p>Providers</p>
                            <h4>124</h4>
                        </div>
                    </div>
                    <div className='goat-box' onClick={() => { signIn("/client") }}>
                        <div className='icon-div' style={{ background: "#A2CA8A" }}>
                            <img  src={contact} alt="some text" />
                        </div>
                        <div className='box-text'>
                            <p>Client</p>
                            <h4>582</h4>
                        </div>
                    </div>
                    <div className='goat-box'  onClick={() => { signIn("/thirdparty") }}>
                        <div  className='icon-div' style={{ background: "rgba(235, 180, 73, 0.88)" }}>
                            <img src={contact} alt="some text" />
                        </div>
                        <div className='box-text'>
                            <p>Third Parties</p>
                            <h4>368</h4>
                        </div>
                    </div>
                    <div className='goat-box'onClick={() => { signIn("/businesses") }}>
                        <div  className='icon-div' style={{ background: "#FF6069" }}>
                            <img   src={contact} alt="some text" />
                        </div>
                        <div className='box-text'>
                            <p>Businesses</p>
                            <h4>124</h4>
                        </div>
                    </div>
                    <div className='goat-box'onClick={() => { signIn("/mhcadmin") }}>
                        <div className='icon-div' style={{ background: "rgb(102 39 43)" }}>
                            <img  src={contact} alt="some text" />
                        </div>
                        <div className='box-text'>
                            <p>MHC Admin</p>
                            <h4>224</h4>
                        </div>
                    </div>
                    <div className='goat-box'onClick={() => { signIn("/employees") }}>
                        <div  className='icon-div' style={{ background: "rgb(255 81 42)" }}>
                            <img  src={contact} alt="some text" />
                        </div>
                        <div className='box-text'>
                            <p>Employees</p>
                            <h4>524</h4>
                        </div>
                    </div>
                    <div className='goat-box'onClick={() => { signIn("/businessadmin") }}>
                        <div  className='icon-div' style={{ background: "oklab(0.54 0.21 0.04)" }}>
                            <img   src={contact} alt="some text" />
                        </div>
                        <div className='box-text'>
                            <p>Businesses Admin</p>
                            <h4>324</h4>
                        </div>
                    </div>
                    <div className='goat-box' style={{boxShadow:"none", background:"none",cursor:"auto"}}></div>
                </div>
            <div style={{display:"flex", marginTop:"18px",flexWrap:"wrap"}}>
                <div className='message-bluegoat'>
                    <div className='dot-message'>
                        <div style={{width:"16px",height:"16px",background:"#06D7A1",borderRadius:"20px"}}></div>
                        <p>New Messages</p>
                    </div>
                    <div className='messages-area'>
                        <img src={Ellipse} alt="some text" />
                        <div className='messages-text'>
                            <h4>Mike Mints</h4>
                            <p>Hi ! We are educational instituions. We are looking for a treatment for our school childrens and management course at the present......</p>
                        </div>
                        <button onClick={()=>{signIn("/message")}} className='password-btn' style={{width: "156px",height: "40px",fontSize: "14px"}}>Respond</button>
                    </div>
                    <div className='messages-area'>
                        <img src={Ellipse2} alt="some text" />
                        <div className='messages-text'>
                            <h4>Mike Mints</h4>
                            <p>Hi ! We are educational instituions. We are looking for a treatment for our school childrens and management course at the present......</p>
                        </div>
                        <button onClick={()=>{signIn("/message")}} className='password-btn' style={{width: "156px",height: "40px",fontSize: "14px"}}>Respond</button>
                    </div>
                    <div className='messages-area'>
                        <img src={Ellipse3} alt="some text" />
                        <div className='messages-text'>
                            <h4>Mike Mints</h4>
                            <p>Hi ! We are educational instituions. We are looking for a treatment for our school childrens and management course at the present......</p>
                        </div>
                        <button onClick={()=>{signIn("/message")}} className='password-btn' style={{width: "156px",height: "40px",fontSize: "14px"}}>Respond</button>
                    </div>
                    <div className='messages-area'>
                        <img src={Ellipse4} alt="some text" />
                        <div className='messages-text'>
                            <h4>Mike Mints</h4>
                            <p>Hi ! We are educational instituions. We are looking for a treatment for our school childrens and management course at the present......</p>
                        </div>
                        <button onClick={()=>{signIn("/message")}} className='password-btn' style={{width: "156px",height: "40px",fontSize: "14px"}}>Respond</button>
                    </div>
                </div>
                <div className='message-bluegoat' style={{width:"40%"}}>
                    <div className='dot-message' style={{padding:"0"}}>
                        <p>Appointments</p>
                    </div>
                    <div className='appointment'>
                        <div className='appointment-area'>
                        <span>30 Minute Call with Provider</span>
                        <p onClick={() => { signIn("/training-schedule") }}>+ Invite</p>
                        </div>
                        <div onClick={() => { signIn("/training-schedule") }} className='other-people'>
                            <div className='people'>
                                <img src={Ellipse7} alt="some text" />
                                <img style={{position:"relative",right:"11px"}} src={Ellipse6} alt="some text" />
                                <img style={{position:"relative",right:"22px"}} src={Ellipse5} alt="some text" />
                            <p>& 2 Others</p>
                            </div>
                            <img style={{width:"4px",height:"16px",position:"relative",right:"11px",top:"15px"}} src={option} alt="some text" />
                        </div>
                        <div onClick={() => { signIn("/training-schedule") }} className='zoom-btn'>
                            <img src={zoomicon} alt="some text" />
                            <h4>Connect With Zoom</h4>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{display:"flex", margin:"0 auto", width:"90%",justifyContent:"center",flexWrap:"wrap"}}>
                <div className='message-bluegoat'  style={{width:"50%"}}>
                    <div  className='dot-message' style={{padding:"0"}}>
                        <p>Clients</p>
                    </div>
                    <div className='clients-boxes'>
                        <div className='clients-area'>
                            <img src={Ellipse2} alt="some text" />
                            <div className='clients-text'>
                                <h4>Mike Mints</h4>
                                <p>Creates an account</p>
                            </div>
                            <button className='Approve-btn' ><FontAwesomeIcon icon={faCircleCheck} style={{fontSize:"13px"}} />  Approve</button>
                            <button className='Decline-btn' ><FontAwesomeIcon icon={faCircleXmark} style={{fontSize:"13px"}}/> Decline</button>
                        </div>
                        <div className='clients-area'>
                            <img src={Ellipse4} alt="some text" />
                            <div className='clients-text'>
                                <h4>Mike Mints</h4>
                                <p>Creates an account</p>
                            </div>
                            <button className='Approve-btn' ><FontAwesomeIcon icon={faCircleCheck} style={{fontSize:"13px"}} />  Approve</button>
                            <button className='Decline-btn' ><FontAwesomeIcon icon={faCircleXmark} style={{fontSize:"13px"}}/> Decline</button>
                        </div>
                        <div className='clients-area'>
                            <img src={Ellipse3} alt="some text" />
                            <div className='clients-text'>
                                <h4>Mike Mints</h4>
                                <p>Creates an account</p>
                            </div>
                            <button className='Approve-btn' ><FontAwesomeIcon icon={faCircleCheck} style={{fontSize:"13px"}} />  Approve</button>
                            <button className='Decline-btn' ><FontAwesomeIcon icon={faCircleXmark} style={{fontSize:"13px"}}/> Decline</button>
                        </div>
                    </div>
                </div>
                <div className='message-bluegoat' style={{width:"50%"}}>
                    <div className='dot-message' style={{padding:"0"}}>
                            <p>Providers</p>
                    </div>
                    <div className='clients-boxes'>
                        <div className='clients-area'>
                            <img src={Ellipse2} alt="some text" />
                            <div className='clients-text'>
                                <h4>Mike Mints</h4>
                                <p>Creates an account</p>
                            </div>
                            <button className='Approve-btn' ><FontAwesomeIcon icon={faCircleCheck} style={{fontSize:"13px"}} />  Approve</button>
                            <button className='Decline-btn' ><FontAwesomeIcon icon={faCircleXmark} style={{fontSize:"13px"}}/> Decline</button>
                        </div>
                        <div className='clients-area'>
                            <img src={Ellipse} alt="some text" />
                            <div className='clients-text'>
                                <h4>Mike Mints</h4>
                                <p>Creates an account</p>
                            </div>
                            <button className='Approve-btn' ><FontAwesomeIcon icon={faCircleCheck} style={{fontSize:"13px"}} />  Approve</button>
                            <button className='Decline-btn' ><FontAwesomeIcon icon={faCircleXmark} style={{fontSize:"13px"}}/> Decline</button>
                        </div>
                        <div className='clients-area'>
                            <img src={Ellipse3} alt="some text" />
                            <div className='clients-text'>
                                <h4>Mike Mints</h4>
                                <p>Creates an account</p>
                            </div>
                            <button className='Approve-btn' ><FontAwesomeIcon icon={faCircleCheck} style={{fontSize:"13px"}} />  Approve</button>
                            <button className='Decline-btn' ><FontAwesomeIcon icon={faCircleXmark} style={{fontSize:"13px"}}/> Decline</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}

export default MHCDashboard;
