// SearchBar.js
import React from 'react';
import { FormControl } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

const SearchBar = ({ onSearch }) => {
  const handleSearch = (e) => {
    e.preventDefault();
    const query = e.target.elements.query.value;
    onSearch(query);
  };

  return (
    <div inline onSubmit={handleSearch}>
      <FontAwesomeIcon style={{ position: "relative", left: "28px", height: "15px" }} icon={faSearch} className="search-icon" />
      <FormControl style={{ width: "55%", padding: "5px", borderRadius: "25px", paddingLeft: "30px", border: "2px solid #000" }} type="text" placeholder="Search..." name="query" className="mr-sm-2" />
    </div>
  );
};

export default SearchBar;
