import React, { useEffect, useMemo, useState } from 'react';
import Sidebar from '../../../Components/Sidebar';
import Navbar from '../../../Components/Navbar';
// import surveyimg from './../../../assets/survey-img.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical, faCopy, faTrash } from '@fortawesome/free-solid-svg-icons';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
// import { Form } from 'react-bootstrap';
// import SelectedComponet from "../../../Components/Select"
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { faAlignLeft, faCircle, faGripLines, faSquareCheck } from '@fortawesome/free-solid-svg-icons';
import { Button, Checkbox, Form, Input, Modal, Select, Spin, Table, Typography, notification } from 'antd';
import { FaCaretDown } from 'react-icons/fa';
import axiosInstance from '../../../services/axiosInstance';
// import axiosInstance from '../services/axiosInstance';




const namesWithIcons = [
    { name: 'Mental Health Company', icon: faGripLines },
    // { name: 'Client', icon: faAlignLeft },
    // { name: 'Provider', icon: faCircle },
    // { name: 'Checkboxes', icon: faSquareCheck },
    // Add more names with corresponding icons
];
const namesWithIconsselect = [
    { name: 'SingleLineText', icon: faGripLines },
    { name: 'MultiLineText', icon: faAlignLeft },
    { name: 'MCQ', icon: faCircle },
    // { name: 'Checkboxes', icon: faSquareCheck },
    // { name: 'Checkboxes', icon: faSquareCheck },
    // Add more names with corresponding icons
];

const SurveyManagmentView= () => {
    // const [selectedOption1, setSelectedOption1] = useState('')
    // const [selectedOption, setSelectedOption] = useState('');
    // const params = useParams();
    const [disabled, setDisabled] = useState(false);
    const [sidemenu, setSidemenu] = useState(false);
    const [questionData, setQuestionData] = useState();
    const [loading, setLoading] = useState(false)
    const [open, setOpen] = useState(true)
    const [mhc, setMhc] = useState([])
    const [selectMhc, setSelectMhc] = useState(false)
    const [role, setRole] = useState(false)
    const [subRole, setSubRole] = useState()
    const [mhcValue, setMhcValue] = useState()
    const [roleValue, setRoleValue] = useState()
    const [user, setUser] = useState()

    const userdata = localStorage.getItem('user')
    const getUser = JSON.parse(userdata)
    const roleName = getUser?.roles?.role
    const location = useLocation();
    const id = location.state?.data?.data?._id
    const idd=useParams()
    console.log(idd,'id')
    const navigate = useNavigate()
    const [form] = Form.useForm()


    // Access the state object containing getAllModuleData
    const getAllModuleData = location?.state?.data?.data
    const [questions, setQuestions] = useState([{ statement: '', type: 'singleline', options: [] }]);
    const { Option } = Select;
    // console.log(Option,'option')

    const handleSelectChange = (value, index) => {
        const updatedQuestions = [...questions];
        updatedQuestions[index].type = value;
        setQuestions(updatedQuestions);
    };

    const handleAddOption = (index) => {
        const updatedQuestions = [...questions];
        if (updatedQuestions[index].options.length < 3) {
            updatedQuestions[index].options.push('');
            setQuestions(updatedQuestions);
        }
    };

    const handleOptionChange = (index, optionIndex, value) => {
        const updatedQuestions = [...questions];
        updatedQuestions[index].options[optionIndex] = value;
        setQuestions(updatedQuestions);
    };

    const handleAddQuestion = () => {
        setQuestions([...questions, { question: '', type: 'singleline', options: [] }]);
    };

    //   sidebar start
    const handleToggleSidemenu = () => {
        setSidemenu(!sidemenu);
    };
    const getAllMHC = async () => {
        try {
            const res = await axiosInstance.get("user/getMhc")
            const data = res?.data?.data
            setMhc(data)

        } catch (error) {
            console.log(error)

        }


    }
    const getAllRole = async () => {
        try {
            const res = await axiosInstance.get("roles")
            const data = res?.data
            setSubRole(data)

        } catch (error) {
            console.log(error)

        }


    }
    const getModuleByid = async () => {
        try {

            const res = await axiosInstance.get(`survey/${idd?.id}`)
            const data = res?.data?.data
            setQuestions(data?.questions)
            // setPortal(data)

            form.setFieldsValue(data);
        } catch (error) {
            console.log(error)

        }

    }
    useEffect(() => {
        getAllMHC()
        getAllRole()
        getModuleByid()

    }, [])
    useEffect(() => {
        if (roleName !== 'BG Admin') {
            setSelectMhc(true)
        }

    }, [])
    const handleDuplicateQuestion = (index) => {
        const questionToDuplicate = questions[index];
        setQuestions([...questions, { ...questionToDuplicate }]);
      };
    const hanldeSubmit = async (value) => {



        // const questions = [
        //     { type: formData.type, statement: formData.statement },
        //     // Add more questions as needed
        // ];
        const updatedQuestions = value.questions.map(question => ({
            ...question, // Copy the existing question object
            options: questions.map(q => q.options).flat() // Add the empty 'option' array
        }));

        // Create the payload object with updated questions
        const payload = {
            assignTo: roleName === "BG Admin" ? value.assignTo[0] : value?.assignTo[0],
            questions: updatedQuestions
        };

        setDisabled(true)
        try {
            setLoading(true)

            const res = await axiosInstance.post(`module/createQuestion/${id}`, payload)
            if (res && res.data) {
                setLoading(false)
                setQuestionData(res?.data?.data)
                navigate('/module-management')
                notification.success({
                    message: 'Question Created Successfully',
                    // description: 'Welcome to BlueGoatDashboard!',
                });
                form.resetFields()


                // navigate("/module-management")
                setDisabled(false)
            }

        } catch (error) {
            const e=error?.response?.data?.message
            setLoading(false)
            notification.error({
                message: `${e}`,
                // description: 'Welcome to BlueGoatDashboard!',
            });
            setDisabled(false)

        }

    }

    const handleChangeMhc = (value) => {
        if (value) {
            setMhcValue(value)
            setSelectMhc(true)
        }
        if (selectMhc & role) {
            handleUser()
        }

    }
    const handleChangeSubRole = (value) => {

        if (value) {
            setRoleValue(value)
            setRole(true)
            if (selectMhc && role) {


                handleUser()
            }

        }


        // if(selectMhc&&role===true){
        //     console.log('userCall')
        //     handleUser()
        // }


    }
    useEffect(() => {
        if (selectMhc && role) {
            handleUser()
        }

    }, [selectMhc, role])
    const handleUser = async () => {
        let mhc = roleName === "BG Admin" ? mhcValue : getUser?.company?._id
        const payload = {
            roleId: roleValue,
            mhcId: mhc

        }
        try {
            const res = await axiosInstance.post("user/searchUser", payload)
            const data = res?.data?.data
            setUser(data)


        } catch (error) {
            console.log(error)

        }


    }
    const handleRemoveQuestion = (index) => {
        const updatedQuestions = [...questions];
         // Create a copy of the questions array
         updatedQuestions.splice(index, 1); // Remove the item at the specified index
         console.log(updatedQuestions,index,'u')
        setQuestions(updatedQuestions);
      };
    return (
        <Form onFinish={hanldeSubmit} form={form}>
            <Spin size='large' spinning={loading}>

                <div className='main-section'>
                    <div className={sidemenu ? '' : 'sidebar mob-resp'} >
                        <Sidebar />
                    </div>
                    <div className='right-side'>
                        <Navbar navbarname={"Module Management"} setSidemenu={handleToggleSidemenu} />
                        <div className='untitled-form'>
                            <div style={{ display: "flex", flexWrap: "wrap", flexDirection: "column", gap: '10px' }}>
                                <h4>{getAllModuleData?.name}</h4>
                                <p style={{ margin: '0px' }}>{getAllModuleData?.description}</p>
                                <div style={{ display: "flex", gap: "11px", flexWrap: "wrap", width: "50%", flexDirection: 'column', marginBottom: '20px' }}>
                                    <Typography style={{ margin: "0px" }}>Select Role</Typography>
                                    <Form.Item name='role' style={{ width: "100%", height: '3px' }} rules={[
                                        {
                                            required: true,
                                            whitespace: true,
                                            message: "Please enter AssignTo",
                                        },
                                    ]}>

                                        <Select
                                            suffixIcon={<FaCaretDown style={{ fontSize: "25px", color: "#000000" }} />}
                                            // placeholder="Asign To"
                                            style={{ width: "100%", height: "50px", borderRadius: "13px" }}>
                                            {namesWithIcons.map(
                                                (value) => (
                                                    <Select.Option
                                                        key={value.name}
                                                        value={value.name}
                                                    >
                                                        {value.name}
                                                    </Select.Option>
                                                )
                                            )}
                                        </Select>
                                    </Form.Item>
                                    {/* <SelectedComponet name="assignTo" value={formData.assignTo} handleChange={(e) => handleAssignToChange(e.target.value)} namesWithIcons={namesWithIcons} /> */}

                                    {/* <SelectedComponet name="assignTo" value={formData.assignTo} handleChange={handleChange} namesWithIcons={namesWithIcons} /> */}
                                </div>
                                <div style={{ display: "flex", gap: "11px", flexWrap: "wrap", width: "50%", flexDirection: 'column', marginBottom: "20px" }}>
                                    <Typography style={{ margin: "0px" }}>Select MHC</Typography>
                                    <Form.Item name='mhc' style={{ width: "100%", height: '3px' }}

                                        initialValue={roleName === 'BG Admin' ? '' : getUser?.company?._id}
                                        rules={[
                                            {
                                                required: true,
                                                whitespace: true,
                                                message: "Please enter AssignTo",
                                            },
                                        ]}>

                                        <Select

                                            // initialValue={roleName === 'BG Admin' ? '' : getUser?.company?._id}
                                            disabled={roleName === 'BG Admin' ? false : true}
                                            suffixIcon={<FaCaretDown style={{ fontSize: "25px", color: "#000000" }} />}
                                            onChange={handleChangeMhc}
                                            // placeholder="Asign To"
                                            style={{ width: "100%", height: "50px", borderRadius: "13px" }}>
                                            {mhc?.map(
                                                (value) => (
                                                    <Select.Option
                                                        key={value._id}
                                                        value={value._id}
                                                    >
                                                        {value.companyName}
                                                    </Select.Option>
                                                )
                                            )}
                                        </Select>
                                    </Form.Item>
                                    {/* <SelectedComponet name="assignTo" value={formData.assignTo} handleChange={(e) => handleAssignToChange(e.target.value)} namesWithIcons={namesWithIcons} /> */}

                                    {/* <SelectedComponet name="assignTo" value={formData.assignTo} handleChange={handleChange} namesWithIcons={namesWithIcons} /> */}
                                </div>
                                <div style={{ display: "flex", gap: "11px", flexWrap: "wrap", width: "50%", flexDirection: 'column', marginBottom: "20px" }}>
                                    <Typography style={{ margin: "0px" }}>Select Sub Role</Typography>
                                    <Form.Item name='subRole' style={{ width: "100%", height: '3px' }} rules={[
                                        {
                                            required: true,
                                            whitespace: true,
                                            message: "Please enter AssignTo",
                                        },
                                    ]}>

                                        <Select
                                            suffixIcon={<FaCaretDown style={{ fontSize: "25px", color: "#000000" }} />}
                                            onChange={handleChangeSubRole}
                                            // placeholder="Asign To"
                                            style={{ width: "100%", height: "50px", borderRadius: "13px" }}>
                                            {subRole?.map(
                                                (value) => (
                                                    <Select.Option
                                                        key={value._id}
                                                        value={value._id}
                                                    >
                                                        {value.role}
                                                    </Select.Option>
                                                )
                                            )}
                                        </Select>
                                    </Form.Item>
                                    {/* <SelectedComponet name="assignTo" value={formData.assignTo} handleChange={(e) => handleAssignToChange(e.target.value)} namesWithIcons={namesWithIcons} /> */}

                                    {/* <SelectedComponet name="assignTo" value={formData.assignTo} handleChange={handleChange} namesWithIcons={namesWithIcons} /> */}
                                </div>
                                <div style={{ display: "flex", gap: "11px", flexWrap: "wrap", width: "50%", flexDirection: 'column', marginBottom: "20px" }}>
                                    <Typography style={{ margin: "0px" }}>Select User</Typography>
                                    <Form.Item name='assignTo' style={{ width: "100%", height: '3px' }}
                                    // rules={[
                                    //     {
                                    //         required: true,
                                    //         whitespace: true,
                                    //         message: "Please enter AssignTo",
                                    //     },
                                    // ]}
                                    >

                                        <Select
                                        mode='multiple'
                                            suffixIcon={<FaCaretDown style={{ fontSize: "25px", color: "#000000" }} />}
                                            // onChange={handleChangeUser}
                                            disabled={selectMhc && role ? false : true}
                                            // placeholder="Asign To"
                                            style={{ width: "100%", height: "50px", borderRadius: "13px" }}>
                                            {user?.map(
                                                (value) => (
                                                    <Select.Option
                                                        key={value._id}
                                                        value={value._id}
                                                    >
                                                        {value.firstName}
                                                    </Select.Option>
                                                )
                                            )}
                                        </Select>
                                    </Form.Item>
                                    {/* <SelectedComponet name="assignTo" value={formData.assignTo} handleChange={(e) => handleAssignToChange(e.target.value)} namesWithIcons={namesWithIcons} /> */}

                                    {/* <SelectedComponet name="assignTo" value={formData.assignTo} handleChange={handleChange} namesWithIcons={namesWithIcons} /> */}
                                </div>
                            </div>

                        </div>



                        {questions?.map((question, index) => {
                            // Access type property from field
                            // Access type property from field


                            return (
                                <div className='untitled-question' key={index} style={{ marginBottom: "10px", height: 'auto' }}>
                                    <div className='untitled-input' style={{ marginBottom: "80px" }}>
                                        <Form.Item name={['questions', index, 'statement']} style={{ width: "70%" }} >
                                            <Input placeholder={`Question ${index + 1}`} style={{ height: '40px', border: '1px solid rgba(0, 0, 0, 0.50)' }} disabled={true}/>
                                        </Form.Item>
                                        <Form.Item

                                            name={['questions', index, 'type']}
                                            // fieldkey={[fieldkey, 'type']}
                                            style={{ width: "21%" }}
                                            rules={[
                                                {
                                                    required: true,
                                                    whitespace: true,
                                                    message: "Please Enter Type",
                                                },
                                            ]}
                                        >
                                            <Select
                                            disabled={true}
                                                onChange={(value) => handleSelectChange(value, index)}
                                                // onChange={(value) => {
                                                //     console.log(value, 'Selected type');
                                                //     const updatedFields = fields.map((f, i) => {
                                                //         if (i === index) {
                                                //             return {
                                                //                 ...f,
                                                //                 name: [f.name[0], { ...f.name[1], type: value }]
                                                //             };
                                                //         }
                                                //         return f;
                                                //     });
                                                //     setFields(updatedFields); // Update form fields
                                                // }}
                                                suffixIcon={<FaCaretDown style={{ fontSize: "25px", color: "#000000" }} />}
                                                placeholder={`Enter Question ${index + 1} Type`}
                                                style={{ width: "100%", height: "40px", borderRadius: "13px" }}
                                            >

                                                <Option value="SingleLineText">Single Line Text</Option>
                                                <Option value="MultiLineText">Multi Line Text</Option>
                                                <Option value="MCQ">MCQ</Option>

                                            </Select>
                                        </Form.Item>
                                        {question.type === 'MCQ' && (
                                            <div>
                                                <div style={{ display: 'flex',flexDirection:'column' }}>
                                                    {question.options?.map((option, optionIndex) => (
                                                        <Form.Item key={optionIndex} style={{ display: 'flex' }}>


                                                            <Input
                                                            disabled={true}
                                                                style={{ height: '40px', border: '1px solid rgba(0, 0, 0, 0.50)', width: "100%" }}
                                                                placeholder={`Option ${optionIndex + 1}`}
                                                                value={option}
                                                                onChange={(e) => handleOptionChange(index, optionIndex, e.target.value,)}
                                                            />

                                                        </Form.Item>
                                                    ))}


                                                </div>
                                            </div>

                                        )}




                                    </div>
                                    <div style={{ display: "flex", gap: "12px", fontSize: "28px", justifyContent: "flex-end" }}>
                                        <FontAwesomeIcon style={{ cursor: "pointer" }} icon={faCopy} onClick={() => handleDuplicateQuestion(index)}/>
                                        <FontAwesomeIcon style={{ cursor: "pointer" }} icon={faTrash} onClick={() => handleRemoveQuestion(index)} />
                                        <FormControlLabel
                                            value="start"
                                            control={<Switch color="primary" />}
                                            label="Required"
                                            labelPlacement="start"
                                        />
                                        <FontAwesomeIcon style={{ cursor: "pointer" }} icon={faEllipsisVertical} />
                                    </div>


                                </div>
                            )

                        })}
                        {/* <div className='untitled-btns'>
                            <Button onClick={handleAddQuestion} className='hover-button'>
                                Add new question
                            </Button>
                            <Button htmlType='submit' className='hover-button' disabled={disabled}>
                                Submit Module
                            </Button>
                        </div> */}



                        {/* <div style={{ marginLeft: '60px', width: "90%" }}>
                            <Table
                                columns={columns}
                                dataSource={questionData}

                            />

                        </div> */}

                    </div>


                </div>
            </Spin>
        </Form>
    );
};

export default SurveyManagmentView;

