import React, { useState, useRef } from 'react';
import Sidebar from '../../../Components/Sidebar';
import Navbar from '../../../Components/Navbar';
import plusicon from '../../../assets/plus-icon.png';
import uploadpic from '../../../assets/upload-pic.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faX } from '@fortawesome/free-solid-svg-icons';

library.add(faX);
const AddTraining = () => {
    const [popupUpload, setPopupUpload] = useState(false);
    const fileInputRef = useRef(null);

    const handleClosePopup = () => {
        setPopupUpload(false);
    };
    const handleClick = () => {
        fileInputRef.current.click();
    };
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            console.log('File selected:', file);
        }
    };
    //   sidebar start
    const [sidemenu, setSidemenu] = useState(false);
    const handleToggleSidemenu = () => {
        console.log("working");
        setSidemenu(!sidemenu);
    };
    //   side end
    return (
        <>
            <div className='main-section'>
                <div className={sidemenu ? '' : 'sidebar mob-resp'} >
                    <Sidebar />
                </div>
                <div className='right-side'>
                    <Navbar navbarname={"Training"} setSidemenu={handleToggleSidemenu} />
                    <div className='vedios-training'>
                        <div>
                            <video width="328" height="195" controls>
                                <source src="path_to_your_video.mp4" type="video/mp4" />
                            </video>
                            <h4>Completed</h4>
                        </div>
                        <div>
                            <video width="328" height="195" controls>
                                <source src="path_to_your_video.mp4" type="video/mp4" />
                            </video>
                            <h4>Incomplete</h4>
                        </div>
                        <div>
                            <video width="328" height="195" controls>
                                <source src="path_to_your_video.mp4" type="video/mp4" />
                            </video>
                            <h4>Completed</h4>
                        </div>
                        <div>
                            <video width="328" height="195" controls>
                                <source src="path_to_your_video.mp4" type="video/mp4" />
                            </video>
                            <h4>Incomplete</h4>
                        </div>
                        <div>
                            <video width="328" height="195" controls>
                                <source src="path_to_your_video.mp4" type="video/mp4" />
                            </video>
                            <h4>Completed</h4>
                        </div>
                        <div>
                            <video width="328" height="195" controls>
                                <source src="path_to_your_video.mp4" type="video/mp4" />
                            </video>
                            <h4>Incomplete</h4>
                        </div>
                    </div>
                   
                    <div className='addtraining-list'>
                        <ol>
                            <li>I understand the estimated daily charges, including an explanation of any services that may be billed separately to a third party or to me, based on the evaluation of my financial resources and insurance benefits. </li>
                            <li>I understand CAN BH services and treatment process.</li>
                            <li>I understand the opportunity for my family to be involved in the treatment process I have been given an  opportunity to list family members I would like involved in my treatment. </li>
                            <li>I give voluntary consent for treatment to CAN BH and its associates.</li>
                        </ol>
                    </div>
                    <div className='download-report' style={{ backgroundColor: "#27AE60" }} onClick={() => setPopupUpload(!popupUpload)}>
                        <img src={plusicon} alt="some text" />
                        <p>Upload File</p>
                    </div>
                </div>
            </div>
            {popupUpload && (
                <div className='blur-background'>
                    <div className='popup-uploadfile'>
                        <div className='popup-header'>
                            <p>Upload file</p>
                            <FontAwesomeIcon className='X' icon={faX} onClick={handleClosePopup} />
                        </div>
                        <div>
                            <div className='popup-inputs'>
                                <p>Title:</p>
                                <input type='text' />
                            </div>
                            <div className='popup-inputs'>
                                <p>Group:</p>
                                <input type='text' />
                            </div>
                            <div className='popup-inputs'>
                                <p>Type:</p>
                                <input type='text' />
                            </div>
                        </div>
                        <div >
                            <div className='ConactInformation' style={{ display: "flex", gap: "18px", margin:"0 auto", justifyContent:"center" }}>
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    <input
                                        type="file"
                                        accept="image/*"
                                        style={{ display: 'none' }}
                                        ref={fileInputRef}
                                        onChange={handleFileChange}
                                    />
                                    <img onClick={handleClick} style={{ width: "90px", height: "90px", cursor: "pointer" }} src={uploadpic} alt="some text" />

                                </div>
                                <div>
                                    <h4 style={{margin:"9px"}}>Drag & Drop file you want to upload</h4>
                                    <p  style={{margin:"6px"}}>Your file will be private before you make it publics </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            )}
        </>
    );
};

export default AddTraining;
