import googlebtn from './../../assets/google-btn.png';
import applebtn from './../../assets/apple-btn.png';
import fbbtn from './../../assets/fb-btn.png';
import logo from './../../assets/logo.png';
import signimg from './../../assets/sign-img.png';
import './../../App.css';
import PasswordInput from '../../Components/PasswordInput';
import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import axiosInstance from '../../services/axiosInstance';
import { Spin, notification } from 'antd';
function SignIn() {
  const [formData, setFormData] = useState({

    email: '',
    password: ""
  });
  const navigate = useNavigate();
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };
  // api call

  const submitData = async () => {

    try {
      // Show loading spinner while waiting for the response


      const response = await axiosInstance.post('user/sign-in', formData);
      if (response.data && response.data.token) {
        const user = response?.data?.user
        const permission=response?.data?.permission
        // console.log(response?.data?.user,'signindata')
        const setUser = JSON.stringify(user)
        const setPermission=JSON.stringify(permission)
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('user', setUser)
        localStorage.setItem('permission', setPermission)
        if (user.roles.role === 'BG Admin') {
          navigate("/BlueGoatDashboard")
        } else { 
          if (user.firstTimeLogin === false) {
            navigate("/change-password")

          }
          else{
            navigate("/BlueGoatDashboard")
          }
        } 
        // Optionally, you can also store other user-related data in local storage
        // localStorage.setItem('user', JSON.stringify(response.data.user));
        // hideLoading(); // Hide the loading spinner
        // Navigate to the dashboard after successful sign-up
        // Show success notification
        notification.success({
          message: 'Sign In Successful',
          description: 'Welcome to BlueGoatDashboard!',
        });
      }
    } catch (error) {
      console.log(error);
      // Hide the loading spinner
      // hideLoading();
      // // Show error notification
      const e=error.response.data.message
            // setLoading(false)
            notification.error({
                message: `${e}`||'Sign Fail',
                // description: 'Welcome to BlueGoatDashboard!',
            });
      // Handle error
    }
  }
  return (
    <div className='main-section'>
      <div className='sign-left'>
        <div className='sign'>
          <div className='nav-mobile'>
            <img src={logo} alt="some text" />
          </div>
          <div className='sign-text'>
            <h2>Welcome Back</h2>
            <p>Welcome back please enter your details</p>
          </div>
          <div className='email-area'>
            <p>Email</p>
            <input type='email' placeholder='Please enter your email here' name='email' value={formData.email} onChange={handleChange} />
          </div>
          <div className='email-area'>
            <PasswordInput handleChange={handleChange} name='password' value={formData.password} />
          </div>
          <div style={{ display: "flex", justifyContent: "space-between", width: "68%" }}>
            <div style={{ display: "flex" }}>
              <input type="radio" value="Male" name="gender" />
              <p className='forgot-p'>Remember Sign In</p>
            </div>
            <a href='/forgot-password' style={{ textDecoration: "none", color: "black" }}><p className='forgot-p'>Forgot Password?</p></a>

          </div>
          <div style={{ width: "72%", display: "flex", flexDirection: "column", gap: "18px", textAlign: "center" }}>
            <button onClick={submitData} className='sign-button'>Sign In</button>
            <img className='img-google' src={googlebtn} alt="some text" />
            <img className='img-google' src={applebtn} alt="some text" />
            <img className='img-google' src={fbbtn} alt="some text" />
            <p onClick={() => navigate('/sign-up')} style={{ fontWeight: "700", color: "#000", textDecoration: "none", cursor: 'pointer' }}>Doesn’t have an account?   Sign Up here</p>
          </div>
        </div>
      </div>
      <div className='sign-right'>
        <img className='img-logo' src={logo} alt="some text" />
        <img className='img-sign' src={signimg} alt="some text" />
        <div className='sign-text' style={{ color: "#fff", textAlign: "center", width: "100%" }}>
          <h2>Welcome Back</h2>
          <p>A Comprehensive solution to digitally <br /> empower and integrate your mental health care services.</p>
        </div>
      </div>
    </div>
  );
}

export default SignIn;
