import React, { useEffect, useState } from 'react';
import Sidebar from '../../../Components/Sidebar';
import Navbar from '../../../Components/Navbar';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faArrowDownLong, faPenToSquare, faEye, faUser } from '@fortawesome/free-solid-svg-icons';
import SearchBar from '../../../Components/SearchBar';
import AddNewModule from '../../../Components/AddNewModule';
import axiosInstance from '../../../services/axiosInstance';
// import TabContext from '@mui/lab/TabContext';
// import TabList from '@mui/lab/TabList';
// import TabPanel from '@mui/lab/TabPanel';
import { Box, Tab, Tabs } from '@mui/material';

library.add(faArrowDownLong, faPenToSquare, faUser);

const modulesData = [
    { name: 'Intake/Legal', creationTime: '6/4/2019 02:07 PM', Management: "Provider (James)" },
    { name: 'Evaluation/Assessment', creationTime: '6/4/2019 04:00 PM', Management: "MHC Admin (Smith)" },
    { name: 'Physician Order', creationTime: '6/4/2019 04:00 PM', Management: "BG Admin (S Warner) " },
    { name: 'Admission', creationTime: '6/4/2019 04:00 PM', Management: "BlueGoat" },
    { name: 'Physician Note', creationTime: '6/4/2019 04:00 PM', Management: "Provider (James)" },
    { name: 'Nurse Note', creationTime: '6/4/2019 04:00 PM', Management: "MHC Admin (Smith)" },
    { name: 'Vital Signs', creationTime: '6/4/2019 04:00 PM', Management: "BG Admin (S Warner) " },
    { name: 'Labs/Special Reports', creationTime: '6/4/2019 04:00 PM', Management: "BlueGoat" },
    { name: 'Medication Px-e prescribe', creationTime: '6/4/2019 04:00 PM', Management: "Provider (James)" },
    { name: 'Treatment Plan', creationTime: '6/4/2019 04:00 PM', Management: "MHC Admin (Smith)" },
    { name: 'Treatment Plan Review', creationTime: '6/4/2019 04:00 PM', Management: "BG Admin (S Warner) " },
    { name: 'Treatment Team Note', creationTime: '6/4/2019 04:00 PM', Management: "BlueGoat" },
    { name: 'Therapy Group Note', creationTime: '6/4/2019 04:00 PM', Management: "Provider (James)" },
    { name: 'Miscellaneous', creationTime: '6/4/2019 04:00 PM', Management: "MHC Admin (Smith)" },
    { name: 'Discharge Plan', creationTime: '6/4/2019 04:00 PM', Management: "BG Admin (S Warner) " },
    { name: 'Discharge Summary', creationTime: '6/4/2019 04:00 PM', Management: "BlueGoat" },
    { name: 'HR/Personnel', creationTime: '6/4/2019 04:00 PM', Management: "Provider (James)" },
];

function ModuleManagement() {
    // const [popupUpload, setPopupUpload] = useState(false);
    const [isVisib, setIsVisib] = useState(false);
    const [sidemenu, setSidemenu] = useState(false);
    const [moduleCreate, setModuleCreate] = useState()
    const [getAllModuleData, setGetAllModuleData] = useState()
    const [signed ,setSigned]=useState([])
    const [value, setValue] = useState('moduleManagement');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const navigate = useNavigate();
    const signIn = (data, name) => {
        const editData = getAllModuleData.find((item) => item.name === name);

        const moduleName = name.replace('/', '-');
        navigate(`${data}/${moduleName}`, { state: { editData } }); // Pass getAllModuleData in state object
    }
    const handleClick = () => {
        setIsVisib(!isVisib);
    };

    const setIsVisible = () => {
        setIsVisib(!isVisib);
    };

    const handleToggleSidemenu = () => {
        setSidemenu(!sidemenu);
    };
    const getAllModule = async (res) => {
        setModuleCreate(res)
        try {
            const res = await axiosInstance.get('module')
            if (res && res.data) {
                // console.log(res.data.data,'management data')
                setGetAllModuleData(res.data?.data)
            }

        } catch (error) {

        }

    }
    const getAllSignedModule = async (res) => {
        setModuleCreate(res)
        try {
            const res = await axiosInstance.get('moduleType')
            if (res && res?.data) {
                // console.log(res.data.data,'assign data')
                setSigned(res.data?.data)
            }

        } catch (error) {

        }

    }
    useEffect(() => {
        getAllModule()
        getAllSignedModule()

    }, [])
    return (
        <>
            <div className='main-section'>
                <div className={sidemenu ? '' : 'sidebar mob-resp'} >
                    <Sidebar />
                </div>
                <div className='right-side'>
                    <Navbar navbarname={"Module Management"} setSidemenu={handleToggleSidemenu} />
                    {/* <Box sx={{ width: '100%', typography: 'body1' }}>
                        <TabContext value={value}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList onChange={handleChange} aria-label="lab API tabs example">
                                    <Tab label="Item One" value="1" />
                                    <Tab label="Item Two" value="2" />
                                    <Tab label="Item Three" value="3" />
                                </TabList>
                            </Box>
                            <TabPanel value="1">Item One</TabPanel>
                            <TabPanel value="2">Item Two</TabPanel>
                            <TabPanel value="3">Item Three</TabPanel>
                        </TabContext>
                    </Box> */}
                    <Box sx={{ width: '100%', typography: 'body1', marginLeft: '30px' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="module tabs example">
                            <Tab label="Module Management" value="moduleManagement" style={{ borderBottom: 'none', borderRadius: '50px', border: '1px solid #B2B2B2', backgroundColor: '#B2B2B2', marginRight: '20px' }} />
                            <Tab label="Module Assigned" value="moduleAssigned" style={{ borderBottom: 'none', borderRadius: '50px', border: '1px solid #B2B2B2', backgroundColor: '#B2B2B2' }} />
                        </Tabs>
                    </Box>
                    {value === "moduleManagement" && (


                        <>

                            <div style={{ display: "flex", justifyContent: "space-between", margin: "40px" }}>
                                <button onClick={handleClick} className='Add-MHC'>
                                    <FontAwesomeIcon icon={faUser} style={{ marginRight: "10px" }} />Add Module
                                </button>
                                <SearchBar />
                                {isVisib && <AddNewModule setIsVisible={setIsVisible} handleGetAll={getAllModule} />}
                            </div>
                            <div style={{ width: "100%", overflow: "scroll" }}>
                                <div style={{ minWidth: "821px" }}>
                                    <div className='admin-heading'>
                                        <p className='role-p'>Module Name</p>
                                        <p className='role-p'> Created Date</p>
                                        <p className='role-p'> Created By</p>
                                        <p className='role-p'>Action</p>
                                    </div>
                                    {signed?.map((module, index) => (
                                        <div key={index} className='role-area'>
                                            <p className='admin-p'>{module.name}</p>
                                            <p className='admin-p'>{module.createdAt}</p>
                                            <div className='admin-p'>
                                                {/* <button onClick={() => { signIn("/assessment-form") }}className='edit-btn'> <FontAwesomeIcon icon={faPenToSquare} />  Edit</button>
                                        <button onClick={() => { signIn("/assessment-form") }} className='view-btn' style={{ marginRight: "-20px" }}><FontAwesomeIcon icon={faEye} /> View</button> */}
                                                {/* <p>{module.createdBy} </p> */}
                                            </div>
                                            <div className='admin-p' style={{ width: "25%", display: "flex", gap: "11px", justifyContent: "center" }}>
                                                {/* <button onClick={() => { navigate(`/assessment-form/${module._id}`) }} className='edit-btn'> <FontAwesomeIcon icon={faPenToSquare} />  Edit</button> */}
                                                {/* <button onClick={() => { signIn("/module-details", module.name) }} className='view-btn' style={{ marginRight: "-20px" }}><FontAwesomeIcon icon={faEye} /> View</button> */}
                                                <button onClick={() => { navigate(`/assessment-form/view/mlist/${module._id}`) }} className='view-btn' style={{ marginRight: "-20px" }}><FontAwesomeIcon icon={faEye} /> View</button>
                                                {/* <p> Provider (James)</p> */}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </>)}
                    {value === "moduleAssigned" && (


                        <>

                            <div style={{ display: "flex", justifyContent: "space-between", margin: "40px" }}>
                                {/* <button onClick={handleClick} className='Add-MHC'>
                                    <FontAwesomeIcon icon={faUser} style={{ marginRight: "10px" }} />Add Module
                                </button> */}
                                <SearchBar />
                                {isVisib && <AddNewModule setIsVisible={setIsVisible} handleGetAll={getAllModule} />}
                            </div>
                            <div style={{ width: "100%", overflow: "scroll" }}>
                                <div style={{ minWidth: "821px" }}>
                                    <div className='admin-heading'>
                                        <p className='role-p'>Module Name</p>
                                        <p className='role-p'> Created Date</p>
                                        <p className='role-p'> Created By</p>
                                        <p className='role-p'>Action</p>
                                    </div>
                                    {signed?.map((module, index) => (
                                        <div key={index} className='role-area'>
                                            <p className='admin-p'>{module.name}</p>
                                            <p className='admin-p'>{module.createdAt}</p>
                                            <div className='admin-p'>
                                                {/* <button onClick={() => { signIn("/assessment-form") }}className='edit-btn'> <FontAwesomeIcon icon={faPenToSquare} />  Edit</button>
                                        <button onClick={() => { signIn("/assessment-form") }} className='view-btn' style={{ marginRight: "-20px" }}><FontAwesomeIcon icon={faEye} /> View</button> */}
                                                {/* <p>{module.createdBy} </p> */}
                                            </div>
                                            <div className='admin-p' style={{ width: "25%", display: "flex", gap: "11px", justifyContent: "center" }}>
                                                {/* <button onClick={() => { navigate(`/assessment-form/${module._id}`) }} className='edit-btn'> <FontAwesomeIcon icon={faPenToSquare} />  Edit</button> */}
                                                {/* <button onClick={() => { signIn("/module-details", module.name) }} className='view-btn' style={{ marginRight: "-20px" }}><FontAwesomeIcon icon={faEye} /> View</button> */}
                                                <button onClick={() => { navigate(`/assessment-form/list/${module._id}`) }} className='view-btn' style={{ marginRight: "-20px" }}><FontAwesomeIcon icon={faEye} /> View</button>
                                                {/* <p> Provider (James)</p> */}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </>)}
                       

                </div>
            </div>
        </>
    );
};

export default ModuleManagement;
