import React, { useState } from 'react';
import { RiEyeFill, RiEyeOffFill } from 'react-icons/ri';

const PasswordInput = ({handleChange,name,value}) => {
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <div>
      <p>Password</p>
      <div style={{ position: 'relative' }}>
        <input
          type={showPassword ? 'text' : 'password'}
          value={value}
          onChange={handleChange}
          name={name}
        />
        <div
          style={{
            position: 'absolute',
            top: '50%',
            right: '27px',
            transform: 'translateY(-50%)',
            cursor: 'pointer',
          }}
          onClick={handleTogglePassword}
        >
          {showPassword ? <RiEyeOffFill /> : <RiEyeFill />}
        </div>
      </div>
    </div>
  );
};

export default PasswordInput;
