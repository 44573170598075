import React, { useEffect, useState } from 'react';
import Sidebar from '../../../Components/Sidebar';
import Navbar from '../../../Components/Navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { faArrowDownLong } from '@fortawesome/free-solid-svg-icons';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import SearchBar from '../../../Components/SearchBar';
import Admin from '../../../Components/Admin';
import NewUser from '../../../Components/NewUser';
import { useLocation, useNavigate } from 'react-router-dom';
import axiosInstance from '../../../services/axiosInstance';
import MHCAdmin from '../../../Components/MHCAdmin';

library.add(faUser);
library.add(faArrowDownLong);
library.add(faPenToSquare);
library.add(faEye);

const MHC = () => {
    const [selectedOption, setSelectedOption] = useState('');
    const [selectedOption1, setSelectedOption1] = useState('');
    const [selectedOption2, setSelectedOption2] = useState('');
    const [loading,setLoading]=useState(false)
    const [data,setData]=useState()
    const navigate=useNavigate()

    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
    };
    const handleSelectChange1 = (event) => {
        setSelectedOption1(event.target.value);
    };
    const handleSelectChange2 = (event) => {
        setSelectedOption2(event.target.value);
    };
    //  new user popup
    const [isVisib, setIsVisib] = useState(false);
    const location = useLocation();
    const pathname = location.pathname;
    const pathSegments = pathname.split('/');
    const dynamicRouteName = pathSegments[pathSegments.length - 1];
   

    const handleClick = () => {
        // setIsVisib(!isVisib)
        navigate('/user-details/name/MHC');
    };

    const setIsVisible = () => {
        setIsVisib(!isVisib)
    }
    //  new user popup end

    //   sidebar start
    const [sidemenu, setSidemenu] = useState(false);
    const handleToggleSidemenu = () => {
        setSidemenu(!sidemenu);
    };
    const getAllRole= async () => {
        try {

            const res = await axiosInstance.get(`user/getMhc`)
            const data = res?.data?.data
            setData(data)
       
        } catch (error) {
            const e=error?.response?.data?.message
            setLoading(false)
            notification.error({
                message: `${e}`,
                // description: 'Welcome to BlueGoatDashboard!',
            });

        }

    }
    useEffect(()=>{
        getAllRole()
    },[])
    //   side end
    return (
        <>
            <div className='main-section'>
                <div className={sidemenu ? '' : 'sidebar mob-resp'} >
                    <Sidebar />
                </div>
                <div className='right-side'>
                    <Navbar navbarname={"MHC"} setSidemenu={handleToggleSidemenu} />
                    <div style={{ display: "flex", justifyContent: "space-between", margin: "40px" }}>
                        <button onClick={handleClick} className='Add-MHC'>
                            <FontAwesomeIcon icon={faUser} style={{ marginRight: "10px" }} />Add New MHC
                        </button>
                        <SearchBar />
                        {isVisib && <NewUser setIsVisible={setIsVisible} />}
                    </div>
                    <div className='report-area' style={{justifyContent:"center" ,margin: "20px auto"}}>
                        <input type='text' />
                        <div>
                            <select value={selectedOption} onChange={handleSelectChange}>
                                <option value="">Select Group</option>
                                <option value="option1">Option 1</option>
                                <option value="option2">Option 2</option>
                                <option value="option3">Option 3</option>
                            </select>
                        </div>
                        <div>
                            <select value={selectedOption1} onChange={handleSelectChange1}>
                                <option value="">Select Progess</option>
                                <option value="option1">Option 1</option>
                                <option value="option2">Option 2</option>
                                <option value="option3">Option 3</option>
                            </select>
                        </div>
                        <div>
                            <select value={selectedOption2} onChange={handleSelectChange2}>
                                <option value="">From - To</option>
                                <option value="option1">Option 1</option>
                                <option value="option2">Option 2</option>
                                <option value="option3">Option 3</option>
                            </select>
                        </div>
                        <button className='filter-btn'>FILTER</button>
                    </div>
                    {
                        dynamicRouteName==='MHC'?<MHCAdmin name={'User Name'} email={'User Email'} mhcname={' MHC Name'} Provider={'User Role'} data={data}/>: <Admin name={'User Name'} email={'User Email'} mhcname={' MHC Name'} Provider={'User Role'} />
                    }
                   
                </div>
            </div>
        </>
    );
};

export default MHC;
