import React, { useState } from 'react';
import Sidebar from '../../../Components/Sidebar';
import Navbar from '../../../Components/Navbar';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faUser } from '@fortawesome/free-solid-svg-icons';
// import SearchBar from '../../../Components/SearchBar';
import DateTime from '../../../Components/DateTime';

library.add(faUser);

const TrainingSchedule = () => {
 //   sidebar start
 const [sidemenu, setSidemenu] = useState(false);
 const handleToggleSidemenu = () => {
     console.log("working");
     setSidemenu(!sidemenu);
 };
 //   side end
  return (
    <>
        <div className='main-section'>
             <div className={sidemenu ? '' : 'sidebar mob-resp'} >
            <Sidebar />
        </div>
        <div className='right-side'>
            <Navbar  navbarname={"Training Schedule"} setSidemenu={handleToggleSidemenu}/>
            <div>
                <DateTime />
            </div>
        </div>
    </div>
    </>
  );
};

export default TrainingSchedule;
