import React, { useState } from 'react';
import Sidebar from '../../../Components/Sidebar';
import Navbar from '../../../Components/Navbar';
import surveyimg from './../../../assets/survey-img.png';
const Client = () => {
    //   sidebar start
    const [sidemenu, setSidemenu] = useState(false);
    const handleToggleSidemenu = () => {
        console.log("working");
        setSidemenu(!sidemenu);
    };
    //   side end
    return (
        <>
            <div className='main-section'>
                <div className={sidemenu ? '' : 'sidebar mob-resp'} >
                    <Sidebar />
                </div>
                <div className='right-side'>
                    <Navbar navbarname={"Client"} setSidemenu={handleToggleSidemenu} />
                    <div className='client-area'>
                        <img src={surveyimg} alt="some text" />
                        <div className='client-text'>
                            <h4>Therapeutic or education session Survey</h4>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. <br />
                                Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea c ommodo consequat.</p>
                        </div>
                        <button className='Edit-Survey'>
                            Edit Survey
                        </button>
                        <button className='Submit-Survery'>
                            Submit Survery
                        </button>
                    </div>
                    <div className='client-area'>
                        <img src={surveyimg} alt="some text" />
                        <div className='client-text'>
                            <h4>Employer question Survey</h4>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. <br />
                                Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea c ommodo consequat.</p>
                        </div>
                        <button className='Edit-Survey'>
                            Edit Survey
                        </button>
                        <button className='Submit-Survery'>
                            Submit Survery
                        </button>
                    </div>
                    <div className='client-area'>
                        <img src={surveyimg} alt="some text" />
                        <div className='client-text'>
                            <h4>Listed</h4>
                            <h4 style={{ color: "rgba(22, 85, 143, 0.30)" }}>3. Educational program questions Surveys</h4>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. <br />
                                Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea c ommodo consequat.</p>
                        </div>
                        <div className='Submitted-mobile'>
                            <p style={{ color: "#0041C4", fontSize: "14px", width: "123px " }}>02-10-1998</p>
                            <button style={{ backgroundColor: "#FF2D2D" }} className='Submit-Survery'>
                                Already Submitted
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Client;
