import React, { useState } from 'react';
// import SearchBar from './../Components/SearchBar';
// import { RiEyeFill, RiEyeOffFill } from 'react-icons/ri';
import sidemenu from "./../assets/menu_icon_151204.png"
import { useNavigate } from 'react-router-dom';
import Notifications from './../Components/Notifications';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe, faChevronDown } from '@fortawesome/free-solid-svg-icons';

const Nav = ({ navbarname, setSidemenu }) => {
  const [sideme, setSideme] = useState(false);
  const [isVisib, setIsVisib] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState('English');
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleLanguageChange = (e) => {
    setSelectedLanguage(e.target.value);
  };
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };
  // const handleSearch = (query) => {
  //   console.log('Search query:', query);
  // };
  const navfunction = () => {
    setSidemenu()
    setSideme(!sideme)
  }
  const navigate = useNavigate();
  const signIn = (data) => {
    navigate(data);
  }
  // const [showNotifications, setShowNotifications] = useState(false);
  const handleClick = () => {
    setIsVisib(!isVisib)
  };
  const setIsVisible = () => {
    setIsVisib(!isVisib)
  }
  return (
    <>
      <img onClick={() => { navfunction() }} className={sideme ? 'sidemenu-img-lft' : 'sidemenu-img'} src={sidemenu}  alt="some text"/>
      <nav className='nav'>
        <div className='nav-area'>
          <h4>{navbarname}</h4>
          <div style={{ display: "flex", gap: "11px", alignItems: "center" }}>
            {navbarname === "MHC Admin" && <div>
            </div>}
            {navbarname === "MHC" &&
              <div >
                {/* <SearchBar onSearch={handleSearch} /> */}
              </div>}
            {navbarname === "Divisions" && <div>
            </div>}
            {navbarname === "Providers" && <div>
            </div>}
            {navbarname === "Training Report" && <div>
            </div>}
            {navbarname === "Module" && <div>
            </div>}
            {navbarname === "Client" && <div>
              <button className='survey-btn'>Add Survey</button></div>}
            <div className={`custom-dropdown ${dropdownOpen ? 'open' : ''}`}>
              <FontAwesomeIcon
                style={{ position: "relative", left: "23px" }}
                icon={faGlobe}
                className="globe-icon"
              />
              <select
                value={selectedLanguage}
                onChange={handleLanguageChange}
                onClick={toggleDropdown}
                onBlur={() => setDropdownOpen(false)}
              >
                <option value="English">English</option>
                <option value="Spanish">Spanish</option>
                <option value="French">French</option>
                {/* Add more languages as needed */}
              </select>
              <FontAwesomeIcon
                style={{ position: "relative", right: "22px" }}
                icon={faChevronDown}
                className="arrow-icon"
                onClick={toggleDropdown}
              />
            </div>
            <svg onClick={() => { signIn("/message") }} style={{ cursor: "pointer" }} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14 19C17.771 19 19.657 19 20.828 17.828C22 16.657 22 14.771 22 11C22 7.229 22 5.343 20.828 4.172C19.657 3 17.771 3 14 3H10C6.229 3 4.343 3 3.172 4.172C2 5.343 2 7.229 2 11C2 14.771 2 16.657 3.172 17.828C3.825 18.482 4.7 18.771 6 18.898" stroke="#070707" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M14 19C12.764 19 11.402 19.5 10.159 20.145C8.161 21.182 7.162 21.701 6.67 21.37C6.178 21.04 6.271 20.015 6.458 17.966L6.5 17.5M12 11V11.01V11ZM8 11V11.01V11ZM16 11V11.01V11Z" stroke="#070707" stroke-width="2" stroke-linecap="round" />
            </svg>
            <div>
              <svg onClick={handleClick} style={{ cursor: "pointer" }} width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M21.5303 13.8668L19.5 11.9212V9.34375C19.4977 7.56257 18.8063 5.84548 17.5595 4.52452C16.3127 3.20355 14.5991 2.37255 12.75 2.19219V0.71875H11.25V2.19219C9.40093 2.37255 7.68732 3.20355 6.44053 4.52452C5.19373 5.84548 4.50233 7.56257 4.5 9.34375V11.9212L2.46975 13.8668C2.32909 14.0016 2.25004 14.1844 2.25 14.375V16.5312C2.25 16.7219 2.32902 16.9047 2.46967 17.0395C2.61032 17.1743 2.80109 17.25 3 17.25H8.25V17.8085C8.23369 18.7203 8.56905 19.6058 9.19184 20.2954C9.81462 20.9849 10.681 21.43 11.625 21.5452C12.1464 21.5948 12.6728 21.5392 13.1704 21.3821C13.6681 21.225 14.1259 20.9698 14.5144 20.6329C14.9029 20.296 15.2136 19.885 15.4264 19.4261C15.6392 18.9673 15.7494 18.4709 15.75 17.9688V17.25H21C21.1989 17.25 21.3897 17.1743 21.5303 17.0395C21.671 16.9047 21.75 16.7219 21.75 16.5312V14.375C21.75 14.1844 21.6709 14.0016 21.5303 13.8668ZM14.25 17.9688C14.25 18.5406 14.0129 19.0891 13.591 19.4935C13.169 19.8978 12.5967 20.125 12 20.125C11.4033 20.125 10.831 19.8978 10.409 19.4935C9.98705 19.0891 9.75 18.5406 9.75 17.9688V17.25H14.25V17.9688ZM20.25 15.8125H3.75V14.6726L5.78025 12.7269C5.92091 12.5921 5.99996 12.4094 6 12.2188V9.34375C6 7.81876 6.63214 6.35622 7.75736 5.27789C8.88258 4.19955 10.4087 3.59375 12 3.59375C13.5913 3.59375 15.1174 4.19955 16.2426 5.27789C17.3679 6.35622 18 7.81876 18 9.34375V12.2188C18 12.4094 18.0791 12.5921 18.2197 12.7269L20.25 14.6726V15.8125Z" fill="black" />
              </svg>
              {isVisib && <Notifications setIsVisible={setIsVisible} />}
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Nav;
