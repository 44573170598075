import React, { useState } from 'react';
import Sidebar from '../../../Components/Sidebar';
import Navbar from '../../../Components/Navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faArrowDownLong } from '@fortawesome/free-solid-svg-icons';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { faEye } from '@fortawesome/free-solid-svg-icons';
library.add(faArrowDownLong);
library.add(faPenToSquare);
library.add(faEye);
function Attendance() {
    const [selectedOption, setSelectedOption] = useState('');
    const [selectedOption1, setSelectedOption1] = useState('');
    const [selectedOption2, setSelectedOption2] = useState('');

    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
    };
    const handleSelectChange1 = (event) => {
        setSelectedOption1(event.target.value);
    };
    const handleSelectChange2 = (event) => {
        setSelectedOption2(event.target.value);
    };
    //   sidebar start
    const [sidemenu, setSidemenu] = useState(false);
    const handleToggleSidemenu = () => {
        console.log("working");
        setSidemenu(!sidemenu);
    };
    //   side end
    return (
        <>
            <div className='main-section'>
                <div className={sidemenu ? '' : 'sidebar mob-resp'} >
                    <Sidebar />
                </div>
                <div className='right-side'>
                    <Navbar navbarname={"Attendance"} setSidemenu={handleToggleSidemenu} />
                    <div className='attendance-area'>
                        <h4>Attendance Overview </h4>
                        <div className='report-area' style={{ justifyContent: "center", margin: "20px auto" }}>
                            <input type='text' />
                            <div>
                                <select value={selectedOption} onChange={handleSelectChange}>
                                    <option value="">Select Group</option>
                                    <option value="option1">Option 1</option>
                                    <option value="option2">Option 2</option>
                                    <option value="option3">Option 3</option>
                                </select>
                            </div>
                            <div>
                                <select value={selectedOption1} onChange={handleSelectChange1}>
                                    <option value="">Select Progess</option>
                                    <option value="option1">Option 1</option>
                                    <option value="option2">Option 2</option>
                                    <option value="option3">Option 3</option>
                                </select>
                            </div>
                            <div>
                                <select value={selectedOption2} onChange={handleSelectChange2}>
                                    <option value="">From - To</option>
                                    <option value="option1">Option 1</option>
                                    <option value="option2">Option 2</option>
                                    <option value="option3">Option 3</option>
                                </select>
                            </div>
                            <button className='filter-btn'>FILTER</button>
                        </div>
                        <div style={{ width: "100%", overflow: "scroll" }}>
                            <div style={{ minWidth: "700px" }}>
                                <div className='attendance-heading'>
                                    <p className='attendance-p'>Name <FontAwesomeIcon style={{ fontSize: "13px" }} icon={faArrowDownLong} /></p>
                                    <p className='attendance-p'>Time <FontAwesomeIcon style={{ fontSize: "13px" }} icon={faArrowDownLong} /></p>
                                    <p className='attendance-p'>Date <FontAwesomeIcon style={{ fontSize: "13px" }} icon={faArrowDownLong} /></p>
                                    <p className='attendance-p'>Notes <FontAwesomeIcon style={{ fontSize: "13px" }} icon={faArrowDownLong} /></p>
                                    <p className='attendance-p'>Recording</p>
                                </div>
                                <div className='admin-area attendance-heading'>
                                    <p className='attendance-p'>Session 1  - Discussion</p>
                                    <p className='attendance-p'>07:05:35 pm</p>
                                    <p className='attendance-p'>10-05-2021</p>
                                    <p className='attendance-p'>Attachment</p>
                                    <div className='attendance-p'>
                                        <button className='view-btn'><FontAwesomeIcon icon={faEye} />  View</button>
                                    </div>
                                </div>
                                <div className='admin-area attendance-heading'>
                                    <p className='attendance-p'>Session 2  - Discussion</p>
                                    <p className='attendance-p'>07:05:35 pm</p>
                                    <p className='attendance-p'>10-05-2021</p>
                                    <p className='attendance-p'>Attachment</p>
                                    <div className='attendance-p'>
                                        <button className='view-btn'><FontAwesomeIcon icon={faEye} />  View</button>
                                    </div>
                                </div>
                                <div className='admin-area attendance-heading'>
                                    <p className='attendance-p'>Session 1  - Discussion</p>
                                    <p className='attendance-p'>07:05:35 pm</p>
                                    <p className='attendance-p'>10-05-2021</p>
                                    <p className='attendance-p'>Attachment</p>
                                    <div className='attendance-p'>
                                        <button className='view-btn'><FontAwesomeIcon icon={faEye} />  View</button>
                                    </div>
                                </div>
                                <div className='admin-area attendance-heading'>
                                    <p className='attendance-p'>Session 2  - Discussion</p>
                                    <p className='attendance-p'>07:05:35 pm</p>
                                    <p className='attendance-p'>10-05-2021</p>
                                    <p className='attendance-p'>Attachment</p>
                                    <div className='attendance-p'>
                                        <button className='view-btn'><FontAwesomeIcon icon={faEye} />  View</button>
                                    </div>
                                </div>
                                <div className='admin-area attendance-heading'>
                                    <p className='attendance-p'>Session 1  - Discussion</p>
                                    <p className='attendance-p'>07:05:35 pm</p>
                                    <p className='attendance-p'>10-05-2021</p>
                                    <p className='attendance-p'>Attachment</p>
                                    <div className='attendance-p'>
                                        <button className='view-btn'><FontAwesomeIcon icon={faEye} />  View</button>
                                    </div>
                                </div>
                                <div className='admin-area attendance-heading'>
                                    <p className='attendance-p'>Session 2  - Discussion</p>
                                    <p className='attendance-p'>07:05:35 pm</p>
                                    <p className='attendance-p'>10-05-2021</p>
                                    <p className='attendance-p'>Attachment</p>
                                    <div className='attendance-p'>
                                        <button className='view-btn'><FontAwesomeIcon icon={faEye} />  View</button>
                                    </div>
                                </div>
                                <div className='admin-area attendance-heading'>
                                    <p className='attendance-p'>Session 1  - Discussion</p>
                                    <p className='attendance-p'>07:05:35 pm</p>
                                    <p className='attendance-p'>10-05-2021</p>
                                    <p className='attendance-p'>Attachment</p>
                                    <div className='attendance-p'>
                                        <button className='view-btn'><FontAwesomeIcon icon={faEye} />  View</button>
                                    </div>
                                </div>
                                <div className='admin-area attendance-heading'>
                                    <p className='attendance-p'>Session 2  - Discussion</p>
                                    <p className='attendance-p'>07:05:35 pm</p>
                                    <p className='attendance-p'>10-05-2021</p>
                                    <p className='attendance-p'>Attachment</p>
                                    <div className='attendance-p'>
                                        <button className='view-btn'><FontAwesomeIcon icon={faEye} />  View</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Attendance;
