import React, { useState, useRef, useEffect } from 'react';
import GenderSelection from './GenderSelection';
// import Form from 'react-bootstrap/Form';
//import Button from 'react-bootstrap/Button';
import Maskgroup from '../assets/Ellipse8.png';
import { Button, Checkbox, Form, Radio, Select, Spin, Upload, notification, Input } from 'antd';
import axiosInstance from '../services/axiosInstance';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { UploadOutlined } from '@ant-design/icons';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { FaCaretDown } from 'react-icons/fa';
import Sidebar from '../Components/Sidebar'

function SurveyViewFillForm() {
    // const [date, setDate] = useState('');
    const [imageSrc, setImageSrc] = useState(null);
    const [imageUrl, setImageUrl] = useState(null);
    const dateInputRef = useRef(null);
    const [loading, setLoading] = useState(false)
    const [role, setRole] = useState()
    const [portal, setPortal] = useState()
    const [sidemenu, setSidemenu] = useState(false);
    const { TextArea } = Input;
    // const handleChange = (e) => {
    //     setDate(e.target.value);
    // };
    const navigate = useNavigate()
    const [form] = Form.useForm();
    const [isChecked, setIsChecked] = useState(false);
    const user = localStorage.getItem('user')
    const getUser = JSON.parse(user)


    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };
    const fileInputRef = useRef(null);
    const location = useLocation();
    const { id } = useParams()
    const idd = location.state;

    // const handleFileChange = (event) => {
    //     const file = event.target.files[0];

    //     if (file) {
    //         const reader = new FileReader();
    //         reader.onloadend = () => {
    //             setImageSrc(reader.result);
    //         };
    //         reader.readAsDataURL(file);
    //     }
    // };
    const handleChange = (info) => {
        if (info.file.status === 'done') {
            getBase64(info.file.originFileObj, (url) => {
                setLoading(false);
                setImageUrl(url); // Set the base64 encoded image URL in state
            });
        }
    };
    const handleFinish = async (value) => {
        const answers = role?.map(question => ({
            questionId: question._id,
            answer: value[`answer_${question._id}`]
          }));
        
        setLoading(true)
      
        try {
            if (id) {
                const res = await axiosInstance.post(`survey/answer/${id}`,{answers})
                if (res) {
                    setLoading(false)
                    notification.success({
                        message: `Answer Successfully`,
                        // description: 'Welcome to BlueGoatDashboard!',
                    });
                    form.resetFields()
                    navigate('/survey')
                }


            }

        } catch (error) {
            setLoading(false)
            notification.error({
                message: error?.response?.data?.message,
                // description: 'Welcome to BlueGoatDashboard!',
            });

        }

    }
   
  
   
    const getSurveyById = async () => {
        try {

            const res = await axiosInstance.get(`survey/getById/${id}`)
            const data = res?.data?.data?.questions
            setRole(data)
            // setPortal(data)

            // form.setFieldsValue(data);
        } catch (error) {
            console.log(error)

        }


    }
    
    useEffect(() => {
       
        // getAllPortal()
        // getAllRole()
        getSurveyById()
    }, [])
    const renderQuestion = (question) => {
        if (question.type === 'MCQ') {
            return (
                <Radio.Group direction="vertical" style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>

                    {question?.options?.map(option => (
                        <Radio key={option} value={option}>{option}</Radio>
                    ))}
                </Radio.Group>
            );
        } else {
            return <Input rows={4} style={{ width: '100%' }} />;
        }
    };
    return (
        <div className='main-section'>
            <div className={sidemenu ? '' : 'sidebar mob-resp'} >
                <Sidebar />
            </div>
            <div className='right-side'>

                <Form onFinish={handleFinish} form={form}>

                    <Spin size='large' spinning={loading}>


                        <div className='ContactInformation'>

                        </div>
                    <div className='email-area create-inputs employe-inputs' style={{ flexDirection: 'column', gap: '20px' }}>
                        {role?.map((question, index) => (
                            <div key={question._id}>
                                <p>{`Question ${index + 1}: ${question.statement}`}</p>
                                <Form.Item name={`answer_${question._id}`}>
                                    {renderQuestion(question, index)}
                                </Form.Item>
                            </div>
                        ))}
                    </div>
                       
                        <div>

                            <div className="profile-btn" style={{ marginTop: '20px' }}>
                                <Button className='sign-button' htmlType='submit'>Submit </Button>
                            </div>
                        </div>
                    </Spin>
                </Form>
            </div>
        </div>

    );
};

export default SurveyViewFillForm;
