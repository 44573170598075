import React, { useEffect, useState } from 'react';
import Vector from '../assets/Vector.png';
import { Button, Checkbox, Form, Spin, notification } from 'antd';
import axiosInstance from '../services/axiosInstance';
import { useNavigate } from 'react-router-dom';
// import { Form } from 'react-bootstrap';



const permissio = [
    {
        portal: "662bebaccffacccebf785b9d",
        portalName: "Admission",
        permission: false,
        // "__v": 0
    },
    {
        portal: "662bebc9cffacccebf785ba0",
        portalName: "Intake/Legal",
        permission: false,
        // "__v": 0
    },
    {
        portal: "662bebd7cffacccebf785ba3",
        portalName: "Physician Order",
        permission: false,
        // "__v": 0
    },
    {
        portal: "662bebe4cffacccebf785ba6",
        portalName: "Assessments/ Evalutions",
        permission: false,
        // "__v": 0
    },
    {
        portal: "662bebf6cffacccebf785ba9",
        portalName: "Physician note",
        permission: false,
        // "__v": 0
    },
    {
        portal: "662bec2fcffacccebf785bac",
        portalName: "THERAPY TAB Individual Note",
        permission: false,
        // "__v": 0
    },
    {
        portal: "662bec3ccffacccebf785baf",
        portalName: "THERAPY TAB Group Note",
        permission: false,
        // "__v": 0
    },
    {
        portal: "662bec4acffacccebf785bb2",
        portalName: "Discharge Plan",
        permission: false,
        // "__v": 0
    },
    {
        portal: "662bec56cffacccebf785bb5",
        portalName: "Medication Treatment",
        permission: false,
        // "__v": 0
    },
    {
        portal: "662bec65cffacccebf785bb8",
        portalName: "Group Progress Note",
        permission: false,
        // "__v": 0
    },
    {
        portal: "662bec76cffacccebf785bbb",
        portalName: "VITAL SIGNS TAB",
        permission: false,
        // "__v": 0
    },
    {
        portal: "662bec81cffacccebf785bbe",
        portalName: "MHC Management",
        permission: false,
        // "__v": 0
    },
    {
        portal: "662bec91cffacccebf785bc1",
        portalName: "MHC Admin Management",
        permission: false,
        // "__v": 0
    },
    {
        portal: "662bec9ccffacccebf785bc4",
        portalName: "Divisions Management",
        permission: false,
        // "__v": 0
    },
    {
        portal: "662beca8cffacccebf785bc7",
        portalName: "Divisions Admin Management",
        permission: false,
        // "__v": 0
    },
    {
        portal: "662becb3cffacccebf785bca",
        portalName: "Providers Management",
        permission: false,
        // "__v": 0
    },
    {
        portal: "662bfd05cffacccebf785bce",
        portalName: "Client Management",
        permission: false,
        // "__v": 0
    },
    {
        portal: "662bfd14cffacccebf785bd1",
        portalName: "Third-Party Management",
        permission: false,
        // "__v": 0
    },
    {
        portal: "662bfd2dcffacccebf785bd5",
        portalName: "Business Management",
        permission: false,
        // "__v": 0
    },
    {
        portal: "662bfd37cffacccebf785bd8",
        portalName: "Employees Management",
        permission: false,
        // "__v": 0
    }
]
const RoleManagementPopup = ({ handleClosePopup, value ,setIsVisible}) => {
    const [selectedOption, setSelectedOption] = useState('');
    const [selectedOption1, setSelectedOption1] = useState('');
    const [loading,setLoading]=useState(false)
    
    // const [popupUpload, setPopupUpload] = useState(false);
    const navigate=useNavigate()

    const handleSelectChange1 = (event) => {
        setSelectedOption1(event.target.value);
    };
    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
    };
    // const handleCheckboxChange = (event) => {
    //     const { name, checked } = event.target;
    //     setCheckboxValues(prevState => ({
    //         ...prevState,
    //         [name]: checked,
    //     }));
    // };
    // const handleFinish=(value)=>{
    //     console.log(value,'va;')

    // }
    const onFinish = async (values) => {
        setLoading(true)
       

        Object.entries(values).forEach(([portalName, permission]) => {
            // Find the corresponding permission in the static array
            const permissionIndex = permissio.findIndex(permission => permission.portalName === portalName);

            // If permission is found, update its permission state
            if (permissionIndex !== -1) {
                permissio[permissionIndex].permission = permission;
            }
        });
        permissio.forEach(permission => delete permission.portalName);
        // console.log(permissions);
        // const grantedPermissions = permissio.filter(permission => permission.portalName===values.portal);
        const payload = {
            ...value,
            permission: permissio
        }
        try { 
            
            const res=await axiosInstance.post('user/sign-up',payload)
            if(res){
                setLoading(false)
                notification.success({
                    message: 'Role created Successfully',
                    // description: 'Welcome to BlueGoatDashboard!',
                });
                handleClosePopup()
                navigate('/role-management')
                setIsVisible(false)
            }
        } catch (error) {
            const e=error.response.data.message
            setLoading(false)
            notification.error({
                message: `${e}`,
                // description: 'Welcome to BlueGoatDashboard!',
            });
            
        }

    };
   

    return (
        <Form onFinish={onFinish} style={{ maxHeight: "465px", overflowY: "auto", scrollbarWidth: "none", msOverflowStyle: "none" }}>
            <Spin size='large' spinning={loading}>


                <div className='role-mainpopup' >
                    <div className='role-selectbtn'>
                        <label>Role</label>
                        <select value={selectedOption} onChange={handleSelectChange}>
                            <option value="option1">MHC</option>
                            <option value="option2">Option 2</option>
                            <option value="option3">Option 3</option>
                        </select>
                    </div>
                    <div className='role-selectbtn'>
                        <label>MHC Name</label>
                        <select value={selectedOption1} onChange={handleSelectChange1}>
                            <option value="option1">MHC123</option>
                            <option value="option2">MHC1234</option>
                            <option value="option3">MHC12345</option>
                        </select>
                    </div>
                </div>
                <div>
                    <div className='role-list'>
                        <h2>Permissions</h2>
                        <h2>Grant</h2>
                    </div>
                    {permissio?.map(permission => (
                        <div className='role-list' key={permission.portal}>
                            <p>{permission.portalName}</p>
                            <div style={{ display: "flex", margin: "1px" }}>
                                <Form.Item style={{ marginBottom: "0px" }} name={permission.portalName} valuePropName='checked' initialValue={false}>
                                    <Checkbox type="checkbox" />
                                </Form.Item>
                            </div>
                        </div>
                    ))}

                </div>
                <div style={{ display: "flex", justifyContent: "center", gap: "26px", marginTop: "33px" }}>
                    <button onClick={handleClosePopup} className='cancel-btn'>Cancel</button>
                    <Button className='apply-btn' htmlType='submit' >Apply</Button>
                </div>
            </Spin>
        </Form>
    );
};

export default RoleManagementPopup;
