import React, { useState } from 'react';
import Sidebar from '../../../Components/Sidebar';
import Navbar from '../../../Components/Navbar';
// import surveyimg from './../../../assets/survey-img.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faEllipsisVertical,  faCopy, faTrash } from '@fortawesome/free-solid-svg-icons';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { Form } from 'react-bootstrap';
import SelectedComponet from "../../../Components/Select"

const UntitledFormSecond = () => {
    const [selectedOption1, setSelectedOption1] = useState('')
    // const [selectedOption, setSelectedOption] = useState('');
    // const handleSelectChange = (event) => {
    //     setSelectedOption(event.target.value);
    // };
    const handleRadioChange = (event) => {
        setSelectedOption1(event.target.value);
    };
    //   sidebar start
    const [sidemenu, setSidemenu] = useState(false);
    const handleToggleSidemenu = () => {
        console.log("working");
        setSidemenu(!sidemenu);
    };
    //   side end
    return (
        <>
            <div className='main-section'>
                <div className={sidemenu ? '' : 'sidebar mob-resp'} >
                    <Sidebar />
                </div>
                <div className='right-side'>
                    <Navbar navbarname={"Survey Form"} setSidemenu={handleToggleSidemenu} />
                    <div className='untitled-form'>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "11px", flexWrap: "wrap" }}>
                            <h4>Untitled form</h4>
                        </div>
                        <p>Form description</p>
                    </div>
                    <div className='untitled-question' style={{marginBottom:"22px"}}>
                        <div className='untitled-input'>
                            <input type='text' value="Untitled Question" />
                            <SelectedComponet />
                        </div>
                        <div style={{ marginBottom: "20px" }}>
                            <Form.Check style={{ fontSize: "20px", fontWeight: "500", fontStyle: "normal" }}
                                type="radio"
                                label="Option 1"
                                name="radioGroup"
                                value="option1"
                                checked={selectedOption1 === 'option1'}
                                onChange={handleRadioChange}
                            />
                            <Form.Check style={{ fontSize: "20px", fontWeight: "500", fontStyle: "normal" }}
                                type="radio"
                                label={
                                    <>
                                        Add option or{' '}
                                        <span style={{ color: 'blue' }}>add “Other”</span>
                                    </>
                                }
                                name="radioGroup"
                                value="option2"
                                checked={selectedOption1 === 'option2'}
                                onChange={handleRadioChange}
                            />
                        </div>
                        <hr />
                        <div style={{ float: "inline-end", display: "flex", gap: "12px", fontSize: "28px" }}>
                            <FontAwesomeIcon style={{ cursor: "pointer" }} icon={faCopy} />
                            <FontAwesomeIcon style={{ cursor: "pointer" }} icon={faTrash} />
                            <FormControlLabel
                                value="start"
                                control={<Switch color="primary" />}
                                label="Required"
                                labelPlacement="start"
                            />
                            <FontAwesomeIcon style={{ cursor: "pointer" }} icon={faEllipsisVertical} />
                        </div>
                    </div>
                    <div className='untitled-question'>
                        <div className='untitled-input'>
                            <input type='text' value="Untitled Question" />
                            <SelectedComponet />
                        </div>
                        <div style={{ marginBottom: "20px" }}>
                            <Form.Check style={{ fontSize: "20px", fontWeight: "500", fontStyle: "normal" }}
                                type="radio"
                                label="Option 1"
                                name="radioGroup"
                                value="option1"
                                checked={selectedOption1 === 'option1'}
                                onChange={handleRadioChange}
                            />
                            <Form.Check style={{ fontSize: "20px", fontWeight: "500", fontStyle: "normal" }}
                                type="radio"
                                label={
                                    <>
                                        Add option or{' '}
                                        <span style={{ color: 'blue' }}>add “Other”</span>
                                    </>
                                }
                                name="radioGroup"
                                value="option2"
                                checked={selectedOption1 === 'option2'}
                                onChange={handleRadioChange}
                            />
                        </div>
                        <hr />
                        <div style={{ float: "inline-end", display: "flex", gap: "12px", fontSize: "28px" }}>
                            <FontAwesomeIcon style={{ cursor: "pointer" }} icon={faCopy} />
                            <FontAwesomeIcon style={{ cursor: "pointer" }} icon={faTrash} />
                            <FormControlLabel
                                value="start"
                                control={<Switch color="primary" />}
                                label="Required"
                                labelPlacement="start"
                            />
                            <FontAwesomeIcon style={{ cursor: "pointer" }} icon={faEllipsisVertical} />
                        </div>
                    </div>
                    <div className='untitled-btns'>
                        <button>Add new question</button>
                        <button>Submit survey</button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default UntitledFormSecond;
