
import logo from './../../assets/logo.png';
import frame2 from './../../assets/frame2.png';
import './../../App.css';
import React from 'react';
function ForgotPassword() {
  return (
    <div className='main-section'>
      <div className='sign-left' >
        <div className='nav-mobile'>
          <img src={logo} alt="some text" />
        </div>
        <div className='sign'>
          <div className='sign-text'>
            <h2 style={{ marginTop: "154px" }}>Forgot <br />Password ?</h2>
          </div>
          <div className='email-area' style={{ marginTop: "24px" }}>
            <label style={{ fontSize: '14px', fontWeight: "500", marginTop: "20px" }}>Please enter your email address below</label>
            <input type='email' placeholder='Enter Email Address' />
            <button className='sign-button' style={{ marginTop: "15px" }}>Submit</button>
          </div>
        </div>
      </div>
      <div className='sign-right'>
        <img className='img-logo' src={logo} alt="some text" />
        <img className='img-sign' style={{ width: "62%", marginBottom: "73px" }} src={frame2} alt="some text" />
      </div>
    </div>
  );
}

export default ForgotPassword;
