import React, { useState} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { RiEyeFill, RiEyeOffFill } from 'react-icons/ri';

function NewUser({ setIsVisible }) {
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
  
    const handleTogglePassword = () => {
      setShowPassword((prevShowPassword) => !prevShowPassword);
    };

    const handleClose = () => {
        setIsVisible(false);
    };
    return (
        <>
            <div className='blur-background'>
                <div className='new-user'>
                    <div className='header'>
                        <h4>Add New</h4>
                        <FontAwesomeIcon style={{ cursor: "pointer" }} icon={faTimes} onClick={handleClose} />
                    </div>
                    <hr style={{ margin: "0" }} />
                    <div>
                        <div className='email-area employe-inputs'>
                            <div className='user-inputs'>
                                <p>First Name</p>
                                <input type='text' placeholder='Please enter your First Name' />
                            </div>
                            <div className='user-inputs'>
                                <p>Email</p>
                                <input type='text' placeholder='Please enter your Email' />
                            </div>
                            <div className='user-inputs'>
                                <p>Password</p>
                                <div style={{ position: 'relative' }}>
                                    <input
                                        type={showPassword ? 'text' : 'password'}
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                    <div
                                        style={{
                                            position: 'absolute',
                                            top: '50%',
                                            right: '16px',
                                            transform: 'translateY(-50%)',
                                            cursor: 'pointer',
                                        }}
                                        onClick={handleTogglePassword}
                                    >
                                        {showPassword ? <RiEyeOffFill /> : <RiEyeFill />}
                                    </div>
                                </div>
                            </div>
                            <div style={{ display: "flex", justifyContent: "center", gap: "11px", margin: "25px auto" }}>
                                <button onClick={handleClose} style={{ width: "35%", height: "42px" }} className='cancel-btn'>Cancel</button>
                                <button style={{ width: "35%", height: "42px" }} className='apply-btn'>Add New</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default NewUser;
