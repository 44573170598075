import React, { useState } from 'react';
import Sidebar from '../../../Components/Sidebar';
import Navbar from '../../../Components/Navbar';
import Table from 'react-bootstrap/Table';
import summaryimg from './../../../assets/summary-img.png';

const NarrativeSummary = () => {
    const customCellStyle = {
        border: '1px solid #dddddd',
        padding: '8px',
        fontSize: '18px',
        fontWeight: '500'
    };
    const headingStyle = {
        border: '1px solid #dddddd',
        padding: '8px',
        fontSize: '24px',
        fontWeight: '500',
        background: '#E8E8E8',
    };
    const tableStyle = {
        borderCollapse: 'collapse',
        width: '100%',
    };
    //   sidebar start
    const [sidemenu, setSidemenu] = useState(false);
    const handleToggleSidemenu = () => {
        console.log("working");
        setSidemenu(!sidemenu);
    };
    //   side end
    return (
        <>
            <div className='main-section'>
                <div className={sidemenu ? '' : 'sidebar mob-resp'} >
                    <Sidebar />
                </div>
                <div className='right-side'>
                    <Navbar navbarname={"Narrative Summary"} setSidemenu={handleToggleSidemenu} />
                    <div className='Summary-profile'>
                        <div>
                            <img src={summaryimg} alt="some text" />
                        </div>
                        <Table style={tableStyle}>
                            <thead>
                                <tr>
                                    <th colSpan={12} style={headingStyle}>Patient Information</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td colSpan={4} style={customCellStyle}>Patient</td>
                                    <td style={customCellStyle}>S00040 - Paltrow, Mary m. -Office Incident</td>
                                </tr>
                                <tr>
                                    <td colSpan={4} style={customCellStyle}>Date of Birth</td>
                                    <td style={customCellStyle}>10/20/1980</td>
                                </tr>
                                <tr>
                                    <td colSpan={4} style={customCellStyle}>Patient Gender</td>
                                    <td style={customCellStyle}>
                                        Female
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={4} style={customCellStyle}>Social Security</td>
                                    <td style={customCellStyle}>
                                        674-55-4444
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={4} style={customCellStyle}>Marital Status</td>
                                    <td style={customCellStyle}>
                                        Married
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={4} style={customCellStyle}>Occupation</td>
                                    <td style={customCellStyle}>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                    <div className='Summary-detail'>
                        <h4>Chief Complaint</h4>
                        <ul>
                            <li>Aching.</li>
                            <li>Ear drainage.</li>
                        </ul>
                        <h4>History of present Illness</h4>
                        <ul>
                            <li>The patient present with aching. Symptom status: increasing. The payient indicates severity which is increassing.
                                The patient’s symptoms have persisted for 2 days. The approximate length of time since the onset of
                                symptoms is 3 days.</li>
                            <li>The patient presents with ear drainage. The patient reports dizziness with sensation of spinning.</li>
                        </ul>
                        <h4>Examination</h4>
                        <ul>
                            <li> <span>Height.</span> Height 70 inches.</li>
                            <li> <span>Weight.</span> Weight 141 pounds.</li>
                            <li><span>Body Mass Index.</span> BMI 20.2.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
};

export default NarrativeSummary;
