import React, { useState, useRef } from 'react';
import profile from '../assets/traning-profile.jpg';
import BasicTab from '../Components/BaiscTab';

function TraningProfile() {
   // const [date, setDate] = useState('');
    const [imageSrc, setImageSrc] = useState(null);
   // const dateInputRef = useRef(null);
    // const handleChange = (e) => {
    //     setDate(e.target.value);
    // };
   // const [isChecked, setIsChecked] = useState(false);

    // const handleCheckboxChange = () => {
    //     setIsChecked(!isChecked);
    // };
    const fileInputRef = useRef(null);

    // const handleClick = () => {
    //     fileInputRef.current.click();
    // };

    const handleFileChange = (event) => {
        const file = event.target.files[0];

        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImageSrc(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };
    return (
        <>
            <div className='ContactInformation'>
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <img className='upload-img' src={imageSrc || profile} alt="some text" />
                    <input
                        type="file"
                        accept="image/*"
                        style={{ display: 'none' }}
                        ref={fileInputRef}
                        onChange={handleFileChange}
                    />
                </div>
            </div>
            <div style={{ display: "flex" }}>
                <div style={{ width: "90%", }}>
                    <div style={{ gap: "20px", alignItems: "center" }} className=' create-inputs employe-inputs'>
                        <h4 style={{ width: "21%" }}>Training Name :</h4>
                        <p>Anatomical models</p>
                    </div>
                    <div style={{ gap: "20px", alignItems: "center" }} className='create-inputs employe-inputs'>
                        <h4 style={{ width: "33%" }}>Training Description :</h4>
                        <p>Medical education is education related to the practice of being a medical practitioner, including the initial training to become a physician</p>
                    </div>
                    <div style={{ gap: "20px", alignItems: "center" }} className='create-inputs employe-inputs'>
                        <h4 style={{ width: "21%" }}>Training Duration :</h4>
                        <p>1 Month</p>
                    </div>
                    <div style={{ gap: "20px", alignItems: "center" }} className='create-inputs employe-inputs'>
                        <h4 style={{ width: "33%" }}>Training Details :</h4>
                        <p>Medical education is education related to the practice of being a medical practitioner, including the initial training to become a physician</p>
                    </div>
                    <BasicTab />
                    <div className=' employe-inputs'>
                        <h5>Users Enrolled in Training :</h5>
                    </div>
                </div>
            </div>
            
        </>
    );
};

export default TraningProfile;
