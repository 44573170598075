
import logo from './../../assets/logo.png';
import frame1 from './../../assets/frame1.png';
import './../../App.css';
import ChangePasswordComponents from '../../Components/ChangePasswordComponents';
import React from 'react';

function ChangePassword() {
  return (
    <div style={{ display: "flex" }}>
      <div className='sign-left' >
        <div className='nav-mobile'>
          <img src={logo} alt="some text" />
        </div>
        <div className='sign'>
          <div className='sign-text'>
            <h2>Change Password</h2>
          </div>
          <div className='email-area'>
            <ChangePasswordComponents />
          </div>
        </div>
      </div>
      <div className='sign-right'>
        <img className='img-logo' src={logo} alt="some text" />
        <img className='img-sign' style={{ width: "55%", marginBottom: "24px" }} src={frame1} alt="some text" />
      </div>
    </div>
  );
}

export default ChangePassword;
