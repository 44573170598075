import React, { useState } from 'react';
import Sidebar from '../../../Components/Sidebar';
import Navbar from '../../../Components/Navbar';
import Table from 'react-bootstrap/Table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faX } from '@fortawesome/free-solid-svg-icons';
// import { Select } from '@mui/material';
import SelectedComponet from "../../../Components/Select"

const ModuleCreation = () => {
    const [popupUpload, setPopupUpload] = useState(false);
    const handleClosePopup = () => {
        setPopupUpload(false);
    };
    // const [selectedOption, setSelectedOption] = useState('');
    // const handleSelectChange = (event) => {
    //     setSelectedOption(event.target.value);
    // };
    const customCellStyle = {
        border: '1px solid #dddddd',
        padding: '8px',
        fontSize: '16px',
        fontWeight: '400',
        color: '#00B3EB',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    };
    const headingStyle = {
        border: '1px solid #dddddd',
        padding: '8px',
        fontSize: '18px',
        fontWeight: '500',
        background: '#E8E8E8',
        textAlign: 'start'
    };
    const tableStyle = {
        borderCollapse: 'collapse',
        width: '100%',
    };
    //   sidebar start
    const [sidemenu, setSidemenu] = useState(false);
    const handleToggleSidemenu = () => {
        console.log("working");
        setSidemenu(!sidemenu);
    };
    //   side end
    return (
        <>
            <div className='main-section'>
                <div className={sidemenu ? '' : 'sidebar mob-resp'} >
                    <Sidebar />
                </div>
                <div className='right-side'>
                    <Navbar navbarname={"Module Creation"} setSidemenu={handleToggleSidemenu} />
                    <div className='main-patient' >
                        <div className='Patient-detail' style={{ width: "25%", padding: "20px" }}>
                            <Table style={tableStyle}>
                                <thead>
                                    <tr>
                                        <th colSpan={12} style={headingStyle}>Form Elements</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={customCellStyle}>Single Line Text Field <FontAwesomeIcon onClick={() => setPopupUpload(!popupUpload)} icon={faPlus} style={{ color: "#1fdb22", cursor: "pointer" }} /></td>
                                    </tr>
                                    <tr>
                                        <td style={customCellStyle}>Multi Line Text Field <FontAwesomeIcon onClick={() => setPopupUpload(!popupUpload)} icon={faPlus} style={{ color: "#1fdb22", cursor: "pointer" }} /></td>
                                    </tr>
                                    <tr>
                                        <td style={customCellStyle}>Radio Buttons
                                            <FontAwesomeIcon onClick={() => setPopupUpload(!popupUpload)} icon={faPlus} style={{ color: "#1fdb22", cursor: "pointer" }} /></td>
                                    </tr>
                                    <tr>
                                        <td style={customCellStyle}>Checkboxes <FontAwesomeIcon onClick={() => setPopupUpload(!popupUpload)} icon={faPlus} style={{ color: "#1fdb22", cursor: "pointer" }} /></td>
                                    </tr>
                                    <tr>
                                        <td style={customCellStyle}>Dropdown
                                            <FontAwesomeIcon onClick={() => setPopupUpload(!popupUpload)} icon={faPlus} style={{ color: "#1fdb22", cursor: "pointer" }} /></td>
                                    </tr>
                                    <tr>
                                        <td style={customCellStyle}>Multi Select
                                            <FontAwesomeIcon onClick={() => setPopupUpload(!popupUpload)} icon={faPlus} style={{ color: "#1fdb22", cursor: "pointer" }} /></td>
                                    </tr>
                                    <tr>
                                        <td style={customCellStyle}>Date/Time Picker
                                            <FontAwesomeIcon onClick={() => setPopupUpload(!popupUpload)} icon={faPlus} style={{ color: "#1fdb22", cursor: "pointer" }} /></td>
                                    </tr>
                                    <tr>
                                        <td style={customCellStyle}>Instructional text <FontAwesomeIcon onClick={() => setPopupUpload(!popupUpload)} icon={faPlus} style={{ color: "#1fdb22", cursor: "pointer" }} /></td>
                                    </tr>
                                    <tr>
                                        <td style={customCellStyle}>E-Sign Field <FontAwesomeIcon onClick={() => setPopupUpload(!popupUpload)} icon={faPlus} style={{ color: "#1fdb22", cursor: "pointer" }} /></td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                        <div className='Patient-detail'>
                            <div style={{ display: "flex", gap: "11px", justifyContent: "end", marginBottom: "17px" }}>
                                <h5>Module:</h5>
                                <SelectedComponet />
                            </div>
                            <div className='Patient-information'>
                                <div className='Patient-inputs'>
                                    <p>Patient’s Legal Name: </p>
                                    <input type='text' />
                                </div>
                                <div className='Patient-inputs'>
                                    <p>Description: </p>
                                    <textarea type='text' />
                                </div>
                                <div className='Patient-inputs'>
                                    <p>Patient’s Street Address:  </p>
                                    <textarea type='text' />
                                </div>
                                <div className='Patient-inputs'>
                                    <p>Social Security:  </p>
                                    <textarea type='text' />
                                </div>
                                <div style={{ display: "flex", width: '100%', justifyContent: "end" }}>
                                    <div className='Patient-inputs'>
                                        <p>Employer:  </p>
                                        <input type='text' />
                                    </div><div className='Patient-inputs'>
                                        <p>Adress:  </p>
                                        <input type='text' />
                                    </div>
                                </div>
                                <div style={{ display: "flex", width: '100%', justifyContent: "end" }}>
                                    <div className='Patient-inputs'>
                                        <p>City:  </p>
                                        <textarea style={{ width: "72%" }} type='text' />
                                    </div><div className='Patient-inputs'>
                                        <p>State:  </p>
                                        <textarea type='text' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {popupUpload && (
                    <div className='blur-background'>
                        <div className='Patient-popup'>
                            <FontAwesomeIcon onClick={handleClosePopup} icon={faX} style={{ color: "#f50000", cursor: "pointer", float: "right" }} />
                            <div>
                                <p>Field Name: </p>
                                <input type='text' />
                            </div>
                            <div>
                                <p>Required: </p>
                                <input style={{ width: "auto", height: "auto" }} type='checkbox' />
                            </div>
                            <button>Add</button>
                        </div>
                    </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default ModuleCreation;
