
import Ellipse from './../../../assets/Ellipse.png';
import Ellipse2 from './../../../assets/Ellipse2.png';
import Ellipse3 from './../../../assets/Ellipse3.png';
import Ellipse4 from './../../../assets/Ellipse4.png';
import Ellipse7 from './../../../assets/Ellipse7.png';
import Ellipse6 from './../../../assets/Ellipse6.png';
import Ellipse5 from './../../../assets/Ellipse5.png';
import option from './../../../assets/option.png';
//import adminpanel from './../../../assets/admin-panel.png';
import zoomicon from './../../../assets/zoom-icon.png';
import './../../../../src/App.css';
import Sidebar from '../../../Components/Sidebar';
import Navbar from '../../../Components/Navbar';
import React from 'react';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';

function AdminDashboard() {
    //   sidebar start
 const [sidemenu, setSidemenu] = useState(false);
 const handleToggleSidemenu = () => {
     console.log("working");
     setSidemenu(!sidemenu);
 };
 //   side end
  return (
    <div className='main-section'>
        <div className={sidemenu ? '' : 'sidebar mob-resp'} >
            <Sidebar />
        </div>
        <div className='right-side'>
            <Navbar  navbarname={"Dashboard"} setSidemenu={handleToggleSidemenu}/>
            <div style={{display:"flex", marginTop:"18px",flexWrap:"wrap"}}>
                <div className='message-bluegoat'>
                    <div className='dot-message'>
                        <div style={{width:"16px",height:"16px",background:"#06D7A1",borderRadius:"20px"}}></div>
                        <p>New Messages</p>
                    </div>
                    <div style={{ margin:"12px auto"}} className='messages-area'>
                        <img src={Ellipse} alt="some text" />
                        <div className='messages-text'>
                            <h4>Mike Mints</h4>
                            <p>Hi ! We are educational instituions. We are looking for a treatment for our school childrens and management course at the present......</p>
                        </div>
                        <button className='password-btn' style={{width: "156px",height: "40px",fontSize: "14px"}}>Respond</button>
                    </div>
                    <div style={{ margin:"12px auto"}} className='messages-area'>
                        <img src={Ellipse2} alt="some text" />
                        <div className='messages-text'>
                            <h4>Mike Mints</h4>
                            <p>Hi ! We are educational instituions. We are looking for a treatment for our school childrens and management course at the present......</p>
                        </div>
                        <button className='password-btn' style={{width: "156px",height: "40px",fontSize: "14px"}}>Respond</button>
                    </div>
                    <div style={{ margin:"12px auto"}} className='messages-area'>
                        <img src={Ellipse} alt="some text" />
                        <div className='messages-text'>
                            <h4>Mike Mints</h4>
                            <p>Hi ! We are educational instituions. We are looking for a treatment for our school childrens and management course at the present......</p>
                        </div>
                        <button className='password-btn' style={{width: "156px",height: "40px",fontSize: "14px"}}>Respond</button>
                    </div>
                    <div style={{ margin:"12px auto"}} className='messages-area'>
                        <img src={Ellipse2} alt="some text" />
                        <div className='messages-text'>
                            <h4>Mike Mints</h4>
                            <p>Hi ! We are educational instituions. We are looking for a treatment for our school childrens and management course at the present......</p>
                        </div>
                        <button className='password-btn' style={{width: "156px",height: "40px",fontSize: "14px"}}>Respond</button>
                    </div>
                    {/* <div className='dot-message'>
                        <p>Admin Note</p>
                    </div>
                    <div style={{height:"64px", margin:"0 auto"}} className='messages-area'>
                        <img style={{width:"64px", height:"67px"}} src={adminpanel} alt="some text" />
                        <div className='messages-text'>
                            <p>Hi ! We are educational instituions. We are looking for a treatment for our school childrens and management course at the present......</p>
                        </div>
                        <button className='password-btn' style={{width: "156px",height: "40px",fontSize: "14px"}}>Publish</button>
                    </div> */}
                </div>
                <div className='message-bluegoat' style={{width:"40%"}}>
                    <div className='dot-message' style={{padding:"0"}}>
                        <p>Appointments</p>
                    </div>
                    <div className='appointment'>
                        <div className='appointment-area'>
                        <span>30 Minute Call with Provider</span>
                        <p>+ Invite</p>
                        </div>
                        <div className='other-people'>
                            <div className='people'>
                                <img src={Ellipse7} alt="some text" />
                                <img style={{position:"relative",right:"11px"}} src={Ellipse6} alt="some text" />
                                <img style={{position:"relative",right:"22px"}} src={Ellipse5} alt="some text" />
                            <p>& 2 Others</p>
                            </div>
                            <img style={{width:"4px",height:"16px",position:"relative",right:"11px",top:"15px"}} src={option} alt="some text" />
                        </div>
                        <div className='zoom-btn'>
                            <img src={zoomicon} alt="some text" />
                            <h4>Connect With Zoom</h4>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{display:"flex", margin:"0 auto", width:"90%",justifyContent:"center",flexWrap:"wrap"}}>
                <div className='message-bluegoat' style={{width:"50%"}}>
                    <div className='dot-message' style={{padding:"0"}}>
                        <p>Clients</p>
                    </div>
                    <div className='clients-boxes'>
                        <div className='clients-area'>
                            <img src={Ellipse2} alt="some text" />
                            <div className='clients-text'>
                                <h4>Mike Mints</h4>
                                <p>Creates an account</p>
                            </div>
                            <button className='password-btn' style={{width: "112px",height: "34px",fontSize: "10px"}}><FontAwesomeIcon icon={faCircleCheck} style={{fontSize:"13px"}} />  Approve</button>
                            <button className='Decline-btn' ><FontAwesomeIcon icon={faCircleXmark} style={{fontSize:"13px"}}/> Decline</button>
                        </div>
                        <div className='clients-area'>
                            <img src={Ellipse4} alt="some text" />
                            <div className='clients-text'>
                                <h4>Mike Mints</h4>
                                <p>Creates an account</p>
                            </div>
                            <button className='password-btn' style={{width: "112px",height: "34px",fontSize: "10px"}}><FontAwesomeIcon icon={faCircleCheck} style={{fontSize:"13px"}} />  Approve</button>
                            <button className='Decline-btn' ><FontAwesomeIcon icon={faCircleXmark} style={{fontSize:"13px"}}/> Decline</button>
                        </div>
                        <div className='clients-area'>
                            <img src={Ellipse3} alt="some text" />
                            <div className='clients-text'>
                                <h4>Mike Mints</h4>
                                <p>Creates an account</p>
                            </div>
                            <button className='password-btn' style={{width: "112px",height: "34px",fontSize: "10px"}}><FontAwesomeIcon icon={faCircleCheck} style={{fontSize:"13px"}} />  Approve</button>
                            <button className='Decline-btn' ><FontAwesomeIcon icon={faCircleXmark} style={{fontSize:"13px"}}/> Decline</button>
                        </div>
                    </div>
                </div>
                <div className='message-bluegoat' style={{width:"50%"}}>
                    <div className='dot-message' style={{padding:"0"}}>
                            <p>Providers</p>
                    </div>
                    <div className='clients-boxes'>
                        <div className='clients-area'>
                            <img src={Ellipse2} alt="some text" />
                            <div className='clients-text'>
                                <h4>Mike Mints</h4>
                                <p>Creates an account</p>
                            </div>
                            <button className='password-btn' style={{width: "112px",height: "34px",fontSize: "10px"}}><FontAwesomeIcon icon={faCircleCheck} style={{fontSize:"13px"}} />  Approve</button>
                            <button className='Decline-btn' ><FontAwesomeIcon icon={faCircleXmark} style={{fontSize:"13px"}}/> Decline</button>
                        </div>
                        <div className='clients-area'>
                            <img src={Ellipse} alt="some text" />
                            <div className='clients-text'>
                                <h4>Mike Mints</h4>
                                <p>Creates an account</p>
                            </div>
                            <button className='password-btn' style={{width: "112px",height: "34px",fontSize: "10px"}}><FontAwesomeIcon icon={faCircleCheck} style={{fontSize:"13px"}} />  Approve</button>
                            <button className='Decline-btn' ><FontAwesomeIcon icon={faCircleXmark} style={{fontSize:"13px"}}/> Decline</button>
                        </div>
                        <div className='clients-area'>
                            <img src={Ellipse3} alt="some text" />
                            <div className='clients-text'>
                                <h4>Mike Mints</h4>
                                <p>Creates an account</p>
                            </div>
                            <button className='password-btn' style={{width: "112px",height: "34px",fontSize: "10px"}}><FontAwesomeIcon icon={faCircleCheck} style={{fontSize:"13px"}} />  Approve</button>
                            <button className='Decline-btn' ><FontAwesomeIcon icon={faCircleXmark} style={{fontSize:"13px"}}/> Decline</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}

export default AdminDashboard;
