import React, { useState,  } from 'react';
import Sidebar from '../../../Components/Sidebar';
import Navbar from '../../../Components/Navbar';
import ConactInformation from '../../../Components/ConactInformation';

function EditEmployer() {
  //   sidebar start
  const [sidemenu, setSidemenu] = useState(false);
  const handleToggleSidemenu = () => {
    console.log("working");
    setSidemenu(!sidemenu);
  };
  //   side end
  return (
    <>
      <div className='main-section'>
        <div className={sidemenu ? '' : 'sidebar mob-resp'} >
          <Sidebar />
        </div>
        <div className='right-side'>
          <Navbar navbarname={"Edit Employer"} setSidemenu={handleToggleSidemenu} />
          <ConactInformation />
        </div>
      </div>
    </>
  );
};

export default EditEmployer;
