import React, { useEffect, useState } from 'react';
import Sidebar from '../../../Components/Sidebar';
import Navbar from '../../../Components/Navbar';
import RoleManagementPopup from '../../../Components/RoleManagementPopup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faArrowDownLong } from '@fortawesome/free-solid-svg-icons';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import SearchBar from '../../../Components/SearchBar';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import NewUserRole from '../../../Components/NewUserRole';
import Ellipse5 from '../../../assets/Ellipse5.png';
import Ellipse3 from '../../../assets/Ellipse3.png';
import Ellipse4 from '../../../assets/Ellipse4.png';
import axiosInstance from '../../../services/axiosInstance';

library.add(faArrowDownLong, faPenToSquare, faUser);

function RoleManagement() {
    const [popupUpload, setPopupUpload] = useState(false);
   const [userData,setUserData]=useState()

    //  new user popup
    const [isVisib, setIsVisib] = useState(false);
    const handleClick = () => {
        setIsVisib(!isVisib)
    };
    const setIsVisible = () => {
        setIsVisib(!isVisib)
    }
    //  new user popup end
    //   sidebar start
    const [sidemenu, setSidemenu] = useState(false);
    const handleToggleSidemenu = () => {
        setSidemenu(!sidemenu);
    };
    //   side end
    const roles = [
        {
            id: 1,
            name: "Mike Mints",
            role: "Employer Admin",
            creationTime: "6/4/2019 02:07 PM",
            image: Ellipse5,
        },
        {
            id: 2,
            name: "Mike Mints",
            role: "MHC Admin",
            creationTime: "6/4/2019 02:07 PM",
            image: Ellipse3,
        },
        {
            id: 3,
            name: "Mike Mints",
            role: "MHC",
            creationTime: "6/4/2019 02:07 PM",
            image: Ellipse4,
        },
        {
            id: 4,
            name: "Mike Mints",
            role: "Division",
            creationTime: "6/4/2019 02:07 PM",
            image: Ellipse5,
        },
        {
            id: 5,
            name: "Mike Mints",
            role: "MHC Admin",
            creationTime: "6/4/2019 02:07 PM",
            image: Ellipse3,
        },
    ];
    const handleClosePopup = () => {
        setPopupUpload(false);
    };
    const getAllUser= async ()=>{
        try {
            const res= await axiosInstance.get('user/getAllUser')
            if(res&&res.data){
                setUserData(res?.data?.data)

            }
            
        } catch (error) {
            
        }

    }
    useEffect(()=>{
        getAllUser()
    },[])
    return (
        <>
            <div className='main-section'>
                <div className={sidemenu ? '' : 'sidebar mob-resp'} >
                    <Sidebar />
                </div>
                <div className='right-side'>
                    <Navbar navbarname={"Role Management"} setSidemenu={handleToggleSidemenu} />
                    <div style={{ display: "flex", justifyContent: "space-between", margin: "40px" }}>
                        <button onClick={handleClick} className='Add-MHC'>
                            <FontAwesomeIcon icon={faUser} style={{ marginRight: "10px" }} />Add Role
                        </button>
                        <SearchBar />
                        {isVisib && <NewUserRole setIsVisible={setIsVisible} />}
                    </div>
                    <div style={{ width: "100%", overflow: "scroll" }}>
                        <div style={{ minWidth: "821px" }}>
                            <div className='admin-heading'>
                                <p className='role-p'>Name</p>
                                <p className='role-p'>Role</p>
                                <p className='role-p'>Creation time</p>
                                <p className='role-p'>Action</p>
                            </div>
                            <div>
                                {userData?.map((role) => (
                                    <div key={role.id} className='role-area'>
                                        <div className='rola-img-area'>
                                            <img src={role.image} alt="some text" />
                                            <p style={{ width: "39%" }} className='admin-role'>{`${role.firstName} ${role.lastName}`}</p>
                                        </div>
                                        <p className='admin-role'>{role?.roles?.role}</p>
                                        <p className='admin-role'>{role.createdAt}</p>
                                        <div className='admin-role' style={{ display: "flex", gap: "11px", justifyContent: "center" }}>
                                            <button className='edit-btn'> <FontAwesomeIcon icon={faPenToSquare} />  Edit</button>
                                            <button onClick={() => setPopupUpload(!popupUpload)} className='view-btn' style={{ marginRight: "-20px" }}><FontAwesomeIcon icon={faCircleCheck} /> Permission</button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {popupUpload && (
                <div className='blur-background'>
                    <div className='role-popup '>
                        <RoleManagementPopup handleClosePopup={handleClosePopup} />
                        {/* <div style={{ display: "flex", justifyContent: "center", gap: "26px", marginTop: "33px" }}>
                            <button onClick={handleClosePopup} className='cancel-btn'>Cancel</button>
                            <button className='apply-btn' onClick={handleApply}>Apply</button>
                        </div> */}

                    </div>
                </div>
            )}

        </>
    );
};

export default RoleManagement;
