import React, { useState } from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLessThan, faGreaterThan } from '@fortawesome/free-solid-svg-icons';
//import { Form } from 'react-bootstrap';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import Box from '@mui/material/Box';
//import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function () {
  const [age, setAge] = React.useState('');

  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const [popupUpload, setPopupUpload] = useState(false);
  const handleClosePopup = () => {
    setPopupUpload(false);
  };
  return (
    <>
      <div style={{ overflow: "scroll" }}>
        <div style={{ minWidth: "548px" }}>
          <div className='datetime-button'>
            <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
              <h4>April’ 2022</h4>
              <FontAwesomeIcon style={{ cursor: "pointer" }} icon={faLessThan} />
              <FontAwesomeIcon style={{ cursor: "pointer" }} icon={faGreaterThan} />
            </div>
            <div style={{ display: "flex", gap: "11px" }}>
              <Button variant="contained">Today</Button>
              <ButtonGroup variant="contained" aria-label="outlined primary button group">
                <Button>Month</Button>
                <Button>Week</Button>
                <Button>Day</Button>
              </ButtonGroup>
            </div>
          </div>
          <div className='header-datetime'>
            <div className='date-header'>
              MON
            </div>
            <div className='date-header'>
              TUE
            </div>
            <div className='date-header'>
              WED
            </div>
            <div className='date-header'>
              THU
            </div>
            <div className='date-header'>
              FRI
            </div>
            <div className='date-header'>
              SAT
            </div>
            <div className='date-header'>
              SUN
            </div>
          </div>
          <div className='date-table'>
            <div onClick={() => setPopupUpload(!popupUpload)} className='date-list'>
              <spam>1</spam>
            </div>
            <div onClick={() => setPopupUpload(!popupUpload)} className='date-list'>
              <spam>2</spam>
            </div>
            <div onClick={() => setPopupUpload(!popupUpload)} className='date-list'>
              <spam>3</spam>
            </div>
            <div onClick={() => setPopupUpload(!popupUpload)} className='date-list'>
              <spam>4</spam>
            </div>
            <div onClick={() => setPopupUpload(!popupUpload)} className='date-list'>
              <spam>5</spam>
            </div>
            <div onClick={() => setPopupUpload(!popupUpload)} className='date-list'>
              <spam>6</spam>
            </div>
            <div onClick={() => setPopupUpload(!popupUpload)} className='date-list'>
              <spam>7</spam>
            </div>
          </div>
          <div className='date-table'>
            <div onClick={() => setPopupUpload(!popupUpload)} className='date-list'>
              <spam>8</spam>
            </div>
            <div onClick={() => setPopupUpload(!popupUpload)} className='date-list'>
              <spam>9</spam>
            </div>
            <div onClick={() => setPopupUpload(!popupUpload)} className='date-list'>
              <spam>10</spam>
            </div>
            <div onClick={() => setPopupUpload(!popupUpload)} className='date-list'>
              <spam>11</spam>
            </div>
            <div onClick={() => setPopupUpload(!popupUpload)} className='date-list'>
              <spam>12</spam>
            </div>
            <div onClick={() => setPopupUpload(!popupUpload)} className='date-list'>
              <spam>13</spam>
            </div>
            <div onClick={() => setPopupUpload(!popupUpload)} className='date-list'>
              <spam>14</spam>
            </div>
          </div>
          <div className='date-table'>
            <div onClick={() => setPopupUpload(!popupUpload)} className='date-list'>
              <spam>15</spam>
            </div>
            <div onClick={() => setPopupUpload(!popupUpload)} className='date-list'>
              <spam>16</spam>
            </div>
            <div onClick={() => setPopupUpload(!popupUpload)} className='date-list'>
              <spam>17</spam>
            </div>
            <div onClick={() => setPopupUpload(!popupUpload)} className='date-list'>
              <spam>18</spam>
            </div>
            <div onClick={() => setPopupUpload(!popupUpload)} className='date-list'>
              <spam>19</spam>
            </div>
            <div onClick={() => setPopupUpload(!popupUpload)} className='date-list'>
              <spam>20</spam>
            </div>
            <div onClick={() => setPopupUpload(!popupUpload)} className='date-list'>
              <spam>21</spam>
            </div>
          </div>
          <div className='date-table'>
            <div onClick={() => setPopupUpload(!popupUpload)} className='date-list'>
              <spam>22</spam>
            </div>
            <div onClick={() => setPopupUpload(!popupUpload)} className='date-list'>
              <spam>23</spam>
            </div>
            <div onClick={() => setPopupUpload(!popupUpload)} className='date-list'>
              <spam>24</spam>
            </div>
            <div onClick={() => setPopupUpload(!popupUpload)} className='date-list'>
              <spam>25</spam>
            </div>
            <div onClick={() => setPopupUpload(!popupUpload)} className='date-list'>
              <spam>26</spam>
            </div>
            <div onClick={() => setPopupUpload(!popupUpload)} className='date-list'>
              <spam>27</spam>
            </div>
            <div onClick={() => setPopupUpload(!popupUpload)} className='date-list'>
              <spam>28</spam>
            </div>
          </div>
          <div style={{ marginBottom: "25px" }} className='date-table'>
            <div onClick={() => setPopupUpload(!popupUpload)} className='date-list'>
              <spam>29</spam>
            </div>
            <div onClick={() => setPopupUpload(!popupUpload)} className='date-list'>
              <spam>30</spam>
            </div>
            <div onClick={() => setPopupUpload(!popupUpload)} className='date-list'>
              <spam>31</spam>
            </div>
            <div onClick={() => setPopupUpload(!popupUpload)} className='date-list'>
              <spam>1</spam>
            </div>
            <div onClick={() => setPopupUpload(!popupUpload)} className='date-list'>
              <spam>2</spam>
            </div>
            <div onClick={() => setPopupUpload(!popupUpload)} className='date-list'>
              <spam>3</spam>
            </div>
            <div onClick={() => setPopupUpload(!popupUpload)} className='date-list'>
              <spam>4</spam>
            </div>
          </div>
          {popupUpload && (
            <div className='blur-background'>
              <div className='new-user '>
                <div className='header'>
                  <h4>Book an appointment</h4>
                </div>
                <hr style={{ margin: "0" }} />
                <div className=' employe-inputs'>
                  <div className='user-inputs'>
                    <p>Select appointment type</p>
                    <div >
                      <Box sx={{ minWidth: 120 }}>
                        <FormControl className='select-module' fullWidth>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={age}
                            onChange={handleChange}
                          >
                            <MenuItem value={10}>Training</MenuItem>
                            <MenuItem value={20}>Session</MenuItem>
                            <MenuItem value={30}>Meeting</MenuItem>
                          </Select>
                        </FormControl>
                      </Box>
                    </div>
                    <div>
                      <p>Select Time</p>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['TimePicker']}>
                          <TimePicker />
                        </DemoContainer>
                      </LocalizationProvider>
                    </div>
                    <p>Select Provider</p>
                    <div >
                      <Box sx={{ minWidth: 120 }}>
                        <FormControl className='select-module' fullWidth>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={age}
                            onChange={handleChange}
                          >
                            <MenuItem value={10}>James Smith</MenuItem>
                            <MenuItem value={20}>John Gold</MenuItem>
                            <MenuItem value={30}>Chris Downey</MenuItem>
                          </Select>
                        </FormControl>
                      </Box>
                    </div>
                  </div>
                  <div style={{ display: "flex", justifyContent: "center", gap: "11px", margin: "25px auto" }}>
                    <button onClick={handleClosePopup} style={{ width: "30%", height: "40px" }} className='cancel-btn'>Cancel</button>
                    <button style={{ width: "30%", height: "40px" }} className='apply-btn'>Confirm</button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

    </>
  );
}