import React, { useEffect, useState, } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { RiEyeFill, RiEyeOffFill } from 'react-icons/ri';
import RoleManagementPopup from './RoleManagementPopup';
import { Button, Form, Select } from 'antd';
import axiosInstance from '../services/axiosInstance';
import { FaCaretDown } from 'react-icons/fa';
const namesWithIcons = [
    { name: 'BG Admin' },
    { name: 'Client' },
    { name: 'Provider' },
    { name: 'MHC' },
    { name: 'MHC Admin' },
    { name: 'Patient' },
    { name: 'Third Party' },
    { name: 'Business' },
    { name: 'Business Admin' },
    { name: 'Division Admin' },
    { name: 'Division' },
    // { name: 'Checkboxes', icon: faSquareCheck },
    // Add more names with corresponding icons
];

function NewUserRole({ setIsVisible }) {
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [popupUpload, setPopupUpload] = useState(false);
    const [value, setValue] = useState()
    const [role, setRole] = useState()
    const [roleC, setRoleC] = useState()
    const user=localStorage.getItem('user')
    const getUser = JSON.parse(user)
    
    const roleName=getUser?.roles?.role
    const handleClosePopup = () => {
        setPopupUpload(false);
    };
    const handleTogglePassword = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };

    const handleClose = () => {
        setIsVisible(false);
    };

    const handleNext = (value) => {

        const payload = {
            ...value
            // lastName: "test", company: 'test', phoneNumber: "234343", insuranceProvider: "test provideer",
            // insuranceMemberId: "9343",
            // address: "test address",
            // insuranceCompanyId: "0000",
            // title: "dummy",
            // username: "mhjnloi",
            // religion: "Test",
            // ethnicity: "test authentic",
            // terms: "these test term",
            // paymentStatus: "false",
            // website: "test.com",
            // gender: "Male",
            // dateOfBirth: "2024-04-20T12:30:45.678Z",
            // status: "true",
        }

        // console.log('call')
        setPopupUpload(true)
        // console.log(popupUpload,'popupupload')
        // setIsVisible(false)

        setValue(payload)

    }
    // const handleFinish = (value) => {
    //     console.log(value, 'v')

    // }
    const getRole = async () => {
        try {
            const res = await axiosInstance.get('roles')
            setRole(res.data)

        } catch (error) {

        }
    }
   
    const getRoleById = async () => {
        try {

            const res = await axiosInstance.get(`user/getMhc`)
            const data = res?.data?.data
            setRoleC(data)
            // setPortal(data)

            // form.setFieldsValue(data);
        } catch (error) {
            console.log(error)

        }


    }
    useEffect(() => {
        getRole()
        getRoleById()
    }, [])
    return (
        <>

            <Form onFinish={handleNext} style={{ }}>
                <div className='blur-background'>
                    <div style={{ margin: "70px auto", maxHeight: "465px", overflowY: "auto", scrollbarWidth: "none", msOverflowStyle: "none" }} className='new-user'>
                        <div className='header'>
                            <h4>Add Role</h4>
                            <FontAwesomeIcon style={{ cursor: "pointer" }} icon={faTimes} onClick={handleClose} />
                        </div>
                        <hr style={{ margin: "0" }} />
                        <div>
                            <div className='email-area employe-inputs'>
                                <div className='user-inputs'>
                                    <p>Name</p>
                                    <Form.Item name='firstName' rules={[{ required: true, message: "Please enter First Name!" }]}>

                                        <input type='text' placeholder='Please enter your First Name' name='firstName' />
                                    </Form.Item>
                                </div>
                                <div className='user-inputs'>
                                    <p>Role</p>

                                    <Form.Item name='roleId' rules={[{ required: true, message: "Please Select Role" }]}>
                                        <Select
                                            placeholder="Please Enter Your Role"
                                            style={{ width: "100%", height: "50px", borderRadius: "13px" }}>
                                            {role?.map(
                                                (value) => (
                                                    <Select.Option style={{ borderRadius: '10px' }}
                                                        key={value._id}
                                                        value={value.roleId}
                                                    >
                                                        {value.role}
                                                    </Select.Option>
                                                )
                                            )}
                                        </Select>

                                        {/* <input type='text' placeholder='Please enter your Module Name'  /> */}
                                    </Form.Item>

                                    {/* <input type='text' placeholder='Please enter your Role' name='role' value={formData.role} onChange={handleChange} /> */}

                                </div>
                                <div className='user-inputs'>
                                    <p>Company</p>

                                    <Form.Item name='company' key="company" initialValue={roleName === "BG Admin" ? '' : getUser?.company?._id}>

                                        {/* <input type='text' value="Blue Goat" /> */}
                                        <Select
                                            suffixIcon={<FaCaretDown style={{ fontSize: "25px", color: "#000000" }} />}
                                            initialValue={roleName === "BG Admin" ? '' : getUser?.company?._id}
                                            disabled={roleName === 'BG Admin' ? false : true}
                                            placeholder="Please Enter Your Company"
                                            style={{ width: "100%", height: "50px", borderRadius: "13px" }}>
                                            {roleC?.map(
                                                (value) => (
                                                    <Select.Option style={{ borderRadius: '10px' }}
                                                        key={value._id}
                                                        value={value._id}
                                                    >
                                                        {value.companyName}
                                                    </Select.Option>
                                                )
                                            )}
                                        </Select>
                                    </Form.Item>

                                    {/* <input type='text' placeholder='Please enter your Role' name='role' value={formData.role} onChange={handleChange} /> */}

                                </div>
                                <div className='user-inputs'>
                                    <p>Email</p>
                                    <Form.Item name='email' rules={[{ required: true, message: "Please Enter Email" }]}>

                                        <input type='text' placeholder='Please enter your Email' />
                                    </Form.Item>
                                </div>
                                <div className='user-inputs'>
                                    <p>Password</p>
                                    <div style={{ position: 'relative' }}>
                                        <Form.Item name='password' rules={[{ required: true, message: "Please Enter Password" }]}>

                                            <input
                                                type={showPassword ? 'text' : 'password'}


                                            />
                                        </Form.Item>
                                        <div
                                            style={{
                                                position: 'absolute',
                                                top: '50%',
                                                right: '16px',
                                                transform: 'translateY(-50%)',
                                                cursor: 'pointer',
                                            }}
                                            onClick={handleTogglePassword}
                                        >
                                            {showPassword ? <RiEyeOffFill /> : <RiEyeFill />}
                                        </div>

                                    </div>
                                </div>
                                <div style={{ display: "flex", justifyContent: "center", gap: "11px", margin: "25px auto" }}>
                                    {/* <button onClick={() => setPopupUpload(!popupUpload)} style={{ width: "35%", height: "42px" }} className='apply-btn'>Permissions</button> */}
                                    <button onClick={handleClose} style={{ width: "35%", height: "42px" }} className='cancel-btn'>Cancel</button>
                                    <Button style={{ width: "35%", height: "42px" }} className='apply-btn' htmlType='submit'>Next</Button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </Form>
            {popupUpload && (
                <div className='blur-background'>
                    <div className='role-popup '>
                        <RoleManagementPopup handleClosePopup={handleClosePopup} value={value} setIsVisible={setIsVisible} />


                    </div>
                </div>
            )}
        </>
    );
};

export default NewUserRole;
