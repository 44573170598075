
import logo from './../../assets/logo.png';
import signimg from './../../assets/sign-img.png';
import './../../App.css';
import GenderSelection from '../../Components/GenderSelection';
import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../services/axiosInstance';
import{Spin} from 'antd'


function SignUp() {
  //const [date, setDate] = useState('');
  const dateInputRef = useRef(null);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    address: '',
    email: '',
    gender: 'male',
    dateOfBirth: '',
    roleId :"1",
    password:"12345"
  });

  // const handleChange = (e) => {
  //   setDate(e.target.value);
  // };
  const navigate = useNavigate();
  const submitData = async () => {

    try {
      // Show loading spinner while waiting for the response
      Spin.setDefaultIndicator(<Spin size="large" />);
      // Spin.setDefaultIndicator(<Spin size="large" />);
      // const hideLoading = Spin.show();
  
      const response = await axiosInstance.post('user/sign-up', formData);
      if (response.data && response.data.token) {
        localStorage.setItem('token', response.data.token);
        // Optionally, you can also store other user-related data in local storage
        // localStorage.setItem('user', JSON.stringify(response.data.user));
        // hideLoading(); // Hide the loading spinner
        navigate("/BlueGoatDashboard"); // Navigate to the dashboard after successful sign-up
        // Show success notification
        // notification.success({
        //   message: 'Sign Up Successful',
        //   description: 'Welcome to BlueGoatDashboard!',
        // });
      }
    } catch (error) {
      console.log(error);
      // Hide the loading spinner
      // hideLoading();
      // // Show error notification
      // notification.error({
      //   message: 'Sign Up Failed',
      //   description: 'Please try again later.',
      // });
      // Handle error
    }
  }
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };
  return (
    <div className='main-section'>
      <div className='sign-left'>
        <div className='sign'>
          <div className='nav-mobile'>
            <img src={logo} alt="some text" />
          </div>
          <div className='sign-text'>
            <h2>Create Account</h2>
            <p>Welcome back please enter your details</p>
          </div>
          <div className='email-area create-inputs'>
            <div className='First-Name'>
              <p>First Name</p>
              <input type='text' placeholder='Please enter your First Name'  value={formData.firstName} onChange={handleChange} name='firstName'/>
            </div>
            <div className='First-Name'>
              <p>Last Name</p>
              <input type='text' placeholder='Please enter your Last Name' value={formData.lastName} onChange={handleChange} name='lastName'/>
            </div>
          </div>
          <div className='email-area'>
            <p>Phone Number </p>
            <input type="tel" id="phoneNumber" name="phone" placeholder="Enter your mobile number" value={formData.phoneNumber} onChange={handleChange} />
          </div>
          <div className='email-area'>
            <p>Address</p>
            <input type='text' name="address" placeholder="Enter your full address" value={formData.address} onChange={handleChange}/>
          </div>
          <div className='email-area'>
            <p>Email</p>
            <input type='email' placeholder='Please enter your email here' value={formData.email} onChange={handleChange} name='email'/>
          </div>
          <div className='date-section'>

            <label style={{ fontSize: "14px", fontWeight: "500" }} htmlFor="countrySelect">Gender</label>
            <GenderSelection handleChange={handleChange} name='gender' value={formData.gender}/>
            <label style={{ fontSize: "14px", fontWeight: "500", marginTop: "3px" }} htmlFor="countrySelect">Date of Birth</label>
            <div>
              <input style={{ color: "#0041C4", border: "none" }}
                type="date"
                value={formData.dob} onChange={handleChange}
                name='dateOfBirth'
              />
            </div>
          </div>
          <div style={{ width: "70%", display: "flex", flexDirection: "column", textAlign: "center" }}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <input type="radio" />
              <p style={{ fontSize: "12px", fontWeight: "500" }}>I've read and accept the Terms & Conditions</p>
            </div>
            <button onClick={submitData} className='sign-button'>Create my Account</button>
            <p>Doesn’t have an account? <a href='example.com' style={{ fontWeight: "700", color: "#000", textDecoration: "none" }}>Sign Up here</a></p>
          </div>
        </div>
      </div>
      <div className='sign-right'>
        <img className='img-logo' src={logo} alt="some text" />
        <img className='img-sign' src={signimg} alt="some text" />
        <div className='sign-text' style={{ color: "#fff", textAlign: "center", width: "100%" }}>
          <h2>Welcome Back</h2>
          <p>A Comprehensive solution to digitally <br /> empower and integrate your mental health care services.</p>
        </div>
      </div>
    </div>
  );
}

export default SignUp;
