import React, { useEffect, useState } from 'react';
import Sidebar from '../../../Components/Sidebar';
import Navbar from '../../../Components/Navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import SearchBar from '../../../Components/SearchBar';
import Admin from '../../../Components/Admin';
import NewUser from '../../../Components/NewUser';
import { faArrowDownLong } from '@fortawesome/free-solid-svg-icons';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../../services/axiosInstance';
//import { useNavigate } from 'react-router-dom';

library.add(faUser);
library.add(faArrowDownLong);
library.add(faPenToSquare);
library.add(faEye);

const ThirdParty = () => {
    // const userDataArray = [
    //     {
    //         id: 1,
    //         name: 'Mike Mints',
    //         email: 'mikemints@gmail.com',
    //         mhcname: 'MHC Ltd',
    //         MHC: 'Jennifer ',
    //         phoneNumber: '+1 58749849848',
    //         image: Ellipse8,
    //     },
    //     {
    //         id: 1,
    //         name: 'Mike Mints',
    //         email: 'mikemints@gmail.com',
    //         mhcname: 'Mental Relaxation Company',
    //         MHC: ' Angela ',
    //         phoneNumber: '+1 58749849848',
    //         image: Ellipse9,
    //     },
    //     {
    //         id: 1,
    //         name: 'Mike Mints',
    //         email: 'mikemints@gmail.com',
    //         mhcname: 'Bosch Ltd',
    //         MHC: 'Jennifer ',
    //         phoneNumber: '+1 58749849848',
    //         image: Ellipse3,
    //     },
    //     {
    //         id: 1,
    //         name: 'Mike Mints',
    //         email: 'mikemints@gmail.com',
    //         mhcname: 'MHC Ltd',
    //         MHC: 'Angela ',
    //         phoneNumber: '+1 58749849848',
    //         image: Ellipse4,
    //     },
    //     {
    //         id: 1,
    //         name: 'Mike Mints',
    //         email: 'mikemints@gmail.com',
    //         mhcname: 'Mental Relaxation Company',
    //         MHC: 'Jennifer ',
    //         phoneNumber: '+1 58749849848',
    //         image: Ellipse8,
    //     },
    //     {
    //         id: 1,
    //         name: 'Mike Mints',
    //         email: 'mikemints@gmail.com',
    //         mhcname: 'Bosch Ltd',
    //         MHC: 'Angela',
    //         phoneNumber: '+1 58749849848',
    //         image: Ellipse4,
    //     },
    //     {
    //         id: 1,
    //         name: 'Mike Mints',
    //         email: 'mikemints@gmail.com',
    //         mhcname: 'MHC Ltd',
    //         MHC: 'Jennifer',
    //         phoneNumber: '+1 58749849848',
    //         image: Ellipse3,
    //     },

    // ];
    const [selectedOption, setSelectedOption] = useState('');
    const [selectedOption1, setSelectedOption1] = useState('');
    const [selectedOption2, setSelectedOption2] = useState('');
    const[userData,setUserData]=useState()
    const navigate=useNavigate()

    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
    };
    const handleSelectChange1 = (event) => {
        setSelectedOption1(event.target.value);
    };
    const handleSelectChange2 = (event) => {
        setSelectedOption2(event.target.value);
    };
    //   sidebar start
    const [sidemenu, setSidemenu] = useState(false);
    const handleToggleSidemenu = () => {
        console.log("working");
        setSidemenu(!sidemenu);
    };
    //   side end
    const [isVisib, setIsVisib] = useState(false);
    const handleClick = () => {
        navigate('/user-details/name/thirdparty');
        // setIsVisib(!isVisib)
    };
    const setIsVisible = () => {
        setIsVisib(!isVisib)
    }
    // const navigate = useNavigate();
    // const signIn = (data) => {
    //     navigate(data);
    // }
    const getAllUser= async ()=>{
        try {
            // const res= await axiosInstance.get('user/getByRole/66271114043997f9db27c19c')
            const res= await axiosInstance.get('user/getByRole/662c05d90a775f5b72ebe9c6')
            if(res&&res.data){
                setUserData(res?.data?.data)

            }
            
        } catch (error) {
            
        }

    }
    useEffect(()=>{
        getAllUser()
       

    },[])
    return (
        <>
            <div className='main-section'>
                <div className={sidemenu ? '' : 'sidebar mob-resp'} >
                    <Sidebar />
                </div>
                <div className='right-side'>
                    <Navbar navbarname={"Third-Party"} setSidemenu={handleToggleSidemenu} />
                    <div style={{ display: "flex", justifyContent: "space-between", margin: "40px" }}>
                        <button onClick={handleClick} className='Add-MHC'>
                            <FontAwesomeIcon icon={faUser} style={{ marginRight: "10px" }} />Add New Third Party
                        </button>
                        <SearchBar />
                        {isVisib && <NewUser setIsVisible={setIsVisible} />}
                    </div>
                    <div className='report-area' style={{ justifyContent: "center", margin: "20px auto" }}>
                        <input type='text' />
                        <div>
                            <select value={selectedOption} onChange={handleSelectChange}>
                                <option value="">Select Group</option>
                                <option value="option1">Option 1</option>
                                <option value="option2">Option 2</option>
                                <option value="option3">Option 3</option>
                            </select>
                        </div>
                        <div>
                            <select value={selectedOption1} onChange={handleSelectChange1}>
                                <option value="">Select Progess</option>
                                <option value="option1">Option 1</option>
                                <option value="option2">Option 2</option>
                                <option value="option3">Option 3</option>
                            </select>
                        </div>
                        <div>
                            <select value={selectedOption2} onChange={handleSelectChange2}>
                                <option value="">From - To</option>
                                <option value="option1">Option 1</option>
                                <option value="option2">Option 2</option>
                                <option value="option3">Option 3</option>
                            </select>
                        </div>
                        <button className='filter-btn'>FILTER</button>
                    </div>
                    <Admin name={'Third Party Name'} email={'Third Party Email'}mhcname={' MHC Name'} Provider={'Client'} data={userData} update={'thirdparty'}/>
                </div>
            </div>
        </>
    );
};

export default ThirdParty;
