import React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
//import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAlignLeft, faCircle, faGripLines,faSquareCheck } from '@fortawesome/free-solid-svg-icons';



export default function SingleSelectWithIcon({name,value,handleChange,namesWithIcons}) {
    const [personName, setPersonName] = React.useState('');

    // const handleChange = (event) => {
    //     setPersonName(event.target.value);
    // };

    return (
        <div>
            <FormControl sx={{ m: 1, width: 300 }}>
                <Select
                    labelId="demo-single-select-label"
                    id="demo-single-select"
                    value={value}
                    onChange={handleChange}
                    name={name}
                    input={<OutlinedInput  />}
                required={true}
                    renderValue={(selected) => {
                        const selectedOption = namesWithIcons.find(
                            (option) => option.name === selected
                        );
                        return (
                            <div>
                                {selectedOption && (
                                    <FontAwesomeIcon
                                        icon={selectedOption.icon}
                                        style={{ marginRight: '10px' }}
                                    />
                                )}
                                {selected}
                            </div>
                        );
                    }}
                >
                    {namesWithIcons?.map(({ name, icon }) => (
                        <MenuItem key={name} value={name}>
                            <FontAwesomeIcon icon={icon} style={{ marginRight: '10px' }} />
                            <ListItemText primary={name} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
}
