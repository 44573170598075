import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { faArrowDownLong } from '@fortawesome/free-solid-svg-icons';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import Ellipse8 from './../assets/Ellipse8.png';
import Ellipse9 from './../assets/Ellipse9.png';
import Ellipse3 from './../assets/Ellipse3.png';
import Ellipse4 from './../assets/Ellipse4.png';
import { useNavigate, useParams } from 'react-router-dom';

library.add(faUser);
library.add(faArrowDownLong);
library.add(faPenToSquare);
library.add(faEye);

const MHCAdmin = ({ data, name, email, Provider, mhcname }) => {
    const navigate = useNavigate();
    const signIn = (data) => {
        navigate(data);
    }
const nam=useParams()

    const data1 = [
        {
            id: 1,
            name: 'Mike Mints',
            email: 'mikemints@gmail.com',
            mhcname: 'MHC Ltd',
            Provider: 'Jennifer (Provider)',
            phoneNumber: '+1 58749849848',
            image: Ellipse8,
        },
        {
            id: 1,
            name: 'Mike Mints',
            email: 'mikemints@gmail.com',
            mhcname: 'HealthCare Ltd',
            Provider: ' Angela (Third Party)',
            phoneNumber: '+1 58749849848',
            image: Ellipse9,
        },
        {
            id: 1,
            name: 'Mike Mints',
            email: 'mikemints@gmail.com',
            mhcname: 'Bosch Ltd',
            Provider: 'Jennifer (Provider)',
            phoneNumber: '+1 58749849848',
            image: Ellipse3,
        },
        {
            id: 1,
            name: 'Mike Mints',
            email: 'mikemints@gmail.com',
            mhcname: 'MHC Ltd',
            Provider: 'Angela (Third Party)',
            phoneNumber: '+1 58749849848',
            image: Ellipse4,
        },
        {
            id: 1,
            name: 'Mike Mints',
            email: 'mikemints@gmail.com',
            mhcname: 'HealthCare Ltd',
            Provider: 'Jennifer (Provider)',
            phoneNumber: '+1 58749849848',
            image: Ellipse8,
        },
        {
            id: 1,
            name: 'Mike Mints',
            email: 'mikemints@gmail.com',
            mhcname: 'Bosch Ltd',
            Provider: 'Angela (Third Party)',
            phoneNumber: '+1 58749849848',
            image: Ellipse4,
        },
        {
            id: 1,
            name: 'Mike Mints',
            email: 'mikemints@gmail.com',
            mhcname: 'MHC Ltd',
            Provider: 'Jennifer (Provider)',
            phoneNumber: '+1 58749849848',
            image: Ellipse3,
        },

    ];

    const data2 = [
        {
            id: 1,
            name: 'Mike Mints',
            email: 'mikemints@gmail.com',
            mhcname: 'MediCorp Ltd',
            Provider: 'Jennifer (Provider)',
            phoneNumber: '+1 58749849848',
            image: Ellipse8,
        },
        {
            id: 1,
            name: 'Mike Mints',
            email: 'mikemints@gmail.com',
            mhcname: 'HealthCare Ltd',
            Provider: ' Angela (Third Party)',
            phoneNumber: '+1 58749849848',
            image: Ellipse9,
        },
        {
            id: 1,
            name: 'Mike Mints',
            email: 'mikemints@gmail.com',
            mhcname: 'Bosch Ltd',
            Provider: 'Jennifer (Provider)',
            phoneNumber: '+1 58749849848',
            image: Ellipse3,
        },
        {
            id: 1,
            name: 'Mike Mints',
            email: 'mikemints@gmail.com',
            mhcname: 'MHC Ltd',
            Provider: 'Angela (Third Party)',
            phoneNumber: '+1 58749849848',
            image: Ellipse4,
        },
        {
            id: 1,
            name: 'Mike Mints',
            email: 'mikemints@gmail.com',
            mhcname: 'MediLife Ltd',
            Provider: 'Jennifer (Provider)',
            phoneNumber: '+1 58749849848',
            image: Ellipse8,
        },
        {
            id: 1,
            name: 'Mike Mints',
            email: 'mikemints@gmail.com',
            mhcname: 'Bosch Ltd',
            Provider: 'Angela (Third Party)',
            phoneNumber: '+1 58749849848',
            image: Ellipse4,
        },
        {
            id: 1,
            name: 'Mike Mints',
            email: 'mikemints@gmail.com',
            mhcname: 'MHC Ltd',
            Provider: 'Jennifer (Provider)',
            phoneNumber: '+1 58749849848',
            image: Ellipse3,
        },

    ];
    const data3 = [
        {
            id: 1,
            name: 'Mike Mints',
            email: 'mikemints@gmail.com',
            mhcname: 'MediCorp Ltd',
            Provider: 'Jennifer',
            phoneNumber: '+1 58749849848',
            image: Ellipse8,
        },
        {
            id: 1,
            name: 'Mike Mints',
            email: 'mikemints@gmail.com',
            mhcname: 'HealthCare Ltd',
            Provider: ' Jennifer',
            phoneNumber: '+1 58749849848',
            image: Ellipse9,
        },
        {
            id: 1,
            name: 'Mike Mints',
            email: 'mikemints@gmail.com',
            mhcname: 'Bosch Ltd',
            Provider: 'Angela',
            phoneNumber: '+1 58749849848',
            image: Ellipse3,
        },
        {
            id: 1,
            name: 'Mike Mints',
            email: 'mikemints@gmail.com',
            mhcname: 'MHC Ltd',
            Provider: 'Angela',
            phoneNumber: '+1 58749849848',
            image: Ellipse4,
        },
        {
            id: 1,
            name: 'Mike Mints',
            email: 'mikemints@gmail.com',
            mhcname: 'MediLife Ltd',
            Provider: 'Jennifer ',
            phoneNumber: '+1 58749849848',
            image: Ellipse8,
        },
        {
            id: 1,
            name: 'Mike Mints',
            email: 'mikemints@gmail.com',
            mhcname: 'Bosch Ltd',
            Provider: 'Angela ',
            phoneNumber: '+1 58749849848',
            image: Ellipse4,
        },
        {
            id: 1,
            name: 'Mike Mints',
            email: 'mikemints@gmail.com',
            mhcname: 'MHC Ltd',
            Provider: 'Jennifer',
            phoneNumber: '+1 58749849848',
            image: Ellipse3,
        },

    ];
    const data4 = [
        {
            id: 1,
            name: 'Mike Mints',
            email: 'mikemints@gmail.com',
            mhcname: 'Jennifer',
            Provider: 'Bosch Ltd',
            phoneNumber: '+1 58749849848',
            image: Ellipse8,
        },
        {
            id: 1,
            name: 'Mike Mints',
            email: 'mikemints@gmail.com',
            mhcname: 'Jennifer',
            Provider: 'HealthCare Ltd',
            phoneNumber: '+1 58749849848',
            image: Ellipse9,
        },
        {
            id: 1,
            name: 'Mike Mints',
            email: 'mikemints@gmail.com',
            mhcname: 'Angela',
            Provider: 'Bosch Ltd',
            phoneNumber: '+1 58749849848',
            image: Ellipse3,
        },
        {
            id: 1,
            name: 'Mike Mints',
            email: 'mikemints@gmail.com',
            mhcname: 'Angela',
            Provider: 'MHC Ltd',
            phoneNumber: '+1 58749849848',
            image: Ellipse4,
        },
        {
            id: 1,
            name: 'Mike Mints',
            email: 'mikemints@gmail.com',
            mhcname: 'Jennifer',
            Provider: 'MediLife Ltd ',
            phoneNumber: '+1 58749849848',
            image: Ellipse8,
        },
        {
            id: 1,
            name: 'Mike Mints',
            email: 'mikemints@gmail.com',
            mhcname: 'Angela',
            Provider: 'Bosch Ltd',
            phoneNumber: '+1 58749849848',
            image: Ellipse4,
        },
        {
            id: 1,
            name: 'Mike Mints',
            email: 'mikemints@gmail.com',
            mhcname: 'Jennifer',
            Provider: 'MHC Ltd',
            phoneNumber: '+1 58749849848',
            image: Ellipse3,
        },

    ];

    var userDataArray = [];
    // if(props.mhcname === "Business Name"){
    //     userDataArray = data2
    // }else if(props.Provider === "Client"){
    //     userDataArray = data3
    // }else if(props.mhcname === "User Name"){
    //     userDataArray = data4
    // }else{
    //     userDataArray = data1
    // }

    return (
        <>
            <div className='admin-responsive' >
                <div style={{ minWidth: "873px" }}>
                    <div className='admin-heading'>
                        <p className='admin-p w-15' >{name} <FontAwesomeIcon className='font-13' icon={faArrowDownLong} /></p>
                        <p className='admin-p w-16' >{email} <FontAwesomeIcon className='font-13' icon={faArrowDownLong} /></p>
                        <p className='admin-p w-17' >{mhcname} <FontAwesomeIcon className='font-13' icon={faArrowDownLong} /></p>
                        <p className='admin-p w-13' >{Provider} <FontAwesomeIcon className='font-13' icon={faArrowDownLong} /></p>
                        <p className='admin-p w-17' >Phone Number <FontAwesomeIcon className='font-13' icon={faArrowDownLong} /></p>
                        <p className='admin-p w-17'  >Action</p>
                    </div>
                    {data?.map((user) => (
                        <div key={user.id} className='admin-area'>
                            <div onClick={() => { signIn("/user-details") }} className='admin-text admin-p w-17'>
                                <img src={user.image} alt="some text" />
                                <p>{user.companyOfficerName}</p>
                            </div>
                            <p className='admin-p w-17' >{user.
                                primaryEmail
                            }</p>
                            <p className='admin-p w-16' >{user.companyName}</p>
                            <p className='admin-p w-14' >{user.Provider}</p>
                            <p className='admin-p w-17' >{user.
mobilePhone
}</p>
                            <div className='admin-p' style={{ display: "flex", gap: "11px", justifyContent: "center" }}>
                                <button onClick={() => { signIn(`/user-details/MHC/${user._id}`) }} className='edit-btn'> <FontAwesomeIcon icon={faPenToSquare} /> Edit</button>
                                <button onClick={() => { signIn(`/user-details-mhc/MHC/${user._id}`) }} className='view-btn' style={{ marginRight: "-20px" }}><FontAwesomeIcon icon={faEye} /> View</button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default MHCAdmin;
