import React, { useState, useRef, useEffect } from 'react';
import GenderSelection from './GenderSelection';
// import Form from 'react-bootstrap/Form';
//import Button from 'react-bootstrap/Button';
import Maskgroup from '../assets/Ellipse8.png';
import { Button, Checkbox, Form, Spin, notification } from 'antd';
import axiosInstance from '../services/axiosInstance';
import { useNavigate } from 'react-router-dom';


function ProfileComponentView({ id }) {
    // const [date, setDate] = useState('');
    const [imageSrc, setImageSrc] = useState(null);
    const dateInputRef = useRef(null);
    // const [loading, setLoading] = useState(false)
    // // const handleChange = (e) => {
    // //     setDate(e.target.value);
    // // };
    // const navigate = useNavigate()
    const [form] = Form.useForm();
    // const [isChecked, setIsChecked] = useState(false);

    // const handleCheckboxChange = () => {
    //     setIsChecked(!isChecked);
    // };
    const fileInputRef = useRef(null);



    const handleFileChange = (event) => {
        const file = event.target.files[0];

        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImageSrc(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };
    // const handleFinish = async (value) => {
    //     setLoading(true)
    //     const payload = {
    //         ...value, roleId: "1",
    //     }
    //     try {
    //         if (id) {
    //             const res = await axiosInstance.post(`user/updateUser/${id}`, payload)
    //             if (res) {
    //                 setLoading(false)
    //                 notification.success({
    //                     message: 'Role created  Successful',
    //                     // description: 'Welcome to BlueGoatDashboard!',
    //                 });
    //                 form.resetFields()
    //                 navigate('/MHCAdmin')
    //             }


    //         } else {


    //             const res = await axiosInstance.post('user/sign-up', payload)
    //             if (res) {
    //                 setLoading(false)
    //                 notification.success({
    //                     message: 'Role created  Successful',
    //                     // description: 'Welcome to BlueGoatDashboard!',
    //                 });
    //                 form.resetFields()
    //                 navigate('/MHCAdmin')
    //             }
    //         }

    //     } catch (error) {
    //         setLoading(false)
    //         notification.error({
    //             message: 'Role Not Created  Successful',
    //             // description: 'Welcome to BlueGoatDashboard!',
    //         });

    //     }

    // }
    const getUserById = async () => {
        try {

            const res = await axiosInstance.get(`user/userById/${id}`)
            const data = res?.data?.data
            console.log(data,'dalata view')
            form.setFieldsValue(data);
        } catch (error) {
            console.log(error)

        }

    }
    useEffect(() => {
        getUserById()
    }, [])
    return (
        <Form  form={form}>
           


                <div className='ContactInformation'>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <img className='upload-img' src={imageSrc || Maskgroup} alt="some text" />
                        <input
                            type="file"
                            accept="image/*"
                            style={{ display: 'none' }}
                            ref={fileInputRef}
                            onChange={handleFileChange}
                        />
                    </div>
                </div>
                <div style={{ display: "flex" }}>
                    <div style={{ width: "50%", }}>
                        <div className='email-area create-inputs employe-inputs'>
                            <div className='First-Name'>
                                <p>First Name</p>
                                <Form.Item name='firstName'>

                                    <input type='text' disabled/>
                                </Form.Item>
                            </div>
                            <div className='First-Name'>
                                <p>Last Name</p>
                                <Form.Item name='lastName'>

                                    <input type='text' disabled/>
                                </Form.Item>
                            </div>
                        </div>
                        <div className='email-area employe-inputs'>
                            <p>Phone Number </p>
                            <Form.Item name='phoneNumber'>

                                <input type="tel" id="phoneNumber" name="phoneNumber" value="232423214" disabled/>
                            </Form.Item>
                        </div>
                        <div className='email-area employe-inputs'>
                            <p>Address</p>
                            <Form.Item name='address'>

                                <input type='text' name="address" value="Abc" disabled/>
                            </Form.Item>
                        </div>
                        <div className='email-area employe-inputs'>
                            <p>Email</p>
                            <Form.Item name='email'>

                                <input type='email' value="MikeMints@gmail.com" disabled/>
                            </Form.Item>
                        </div>
                        <div className='email-area employe-inputs'>
                            <p>Username</p>
                            <Form.Item name='username'>

                                <input type='text' value="MikeMints9910" disabled/>
                            </Form.Item>
                        </div>
                        <div className='email-area employe-inputs'>
                            <p>Religion</p>
                            <Form.Item name='religion'>

                                <input type='text' value="Christian" disabled/>
                            </Form.Item>
                        </div>
                        <div className='email-area employe-inputs'>
                            <p>Terms:</p>
                            <Form.Item name='terms'>

                                <input type='text' value="Abc" disabled/>
                            </Form.Item>
                        </div>


                    </div>
                    <div style={{ width: "50%" }}>
                        <div className='email-area employe-inputs'>
                            <p>Company</p>
                            <Form.Item name='company'>

                                <input type='text' value="Blue Goat" disabled/>
                            </Form.Item>
                        </div>
                        <div className='email-area create-inputs employe-inputs'>
                            <div className='First-Name'>
                                <p>Insurance provider</p>
                                <Form.Item name='insuranceProvider'>

                                    <input type='text' value="James" disabled/>
                                </Form.Item>
                            </div>
                            <div className='First-Name'>
                                <p>Insurance Member Id</p>
                                <Form.Item name='insuranceMemberId'>

                                    <input type='text' value="112200" disabled/>
                                </Form.Item>
                            </div>
                        </div>
                        <div className='email-area employe-inputs'>
                            <p>Insurance Company Id</p>
                            <Form.Item name='insuranceCompanyId'>

                                <input type='text' value="33333" disabled/>
                            </Form.Item>
                        </div>
                        <div className='email-area employe-inputs'>
                            <p>Title</p>
                            <Form.Item name='title'>

                                <input type='text' value="blue" disabled/>
                            </Form.Item>
                        </div>
                        {/* <div className='email-area employe-inputs'>
                            <p>Password</p>
                            <Form.Item name='password'>

                                <input type='Password' value="112212111" disabled/>
                            </Form.Item>
                        </div> */}
                        {/* <div className='email-area employe-inputs'>
                        <p>Ethnicity</p>
                        <Form.Item name='ethnicity'>
                        <input type='text'  />

                        </Form.Item>
                    </div> */}
                        <div className='email-area employe-inputs'>
                            <p>Payment Stutas</p>
                            <Form.Item name='paymentStatus'>

                                <input type='text' value="2114411$" disabled/>
                            </Form.Item>
                        </div>
                        <div className='email-area employe-inputs'>
                            <p>Website:</p>
                            <Form.Item name='website'>

                                <input
                                    type='text'
                                    value="www.abc.com"
                                    disabled
                                />
                            </Form.Item>
                        </div>
                        <div className='email-area employe-inputs'>
                            <p>Ethnicity</p>
                            <Form.Item name='ethnicity'>
                                <input type='text' disabled/>

                            </Form.Item>
                        </div>
                    </div>
                </div>
                <div>
                    <div className='date-section ' style={{ margin: "40px auto" }}>
                        <div style={{ display: "flex", gap: "20px" }}>
                            <label style={{ fontSize: "14px", fontWeight: "500", marginTop: '4px' }} htmlFor="countrySelect">Gender</label>
                            <form style={{ display: "flex", gap: "14px" }} >
                                <Form.Item name="gender">
                                   <input disabled></input>

                                    {/* <GenderSelection /> */}
                                </Form.Item>
                            </form>
                        </div>
                        <div style={{ display: "flex", gap: "20px" }}>
                            <label style={{ fontSize: "14px", fontWeight: "500", marginTop: "3px" }} htmlFor="countrySelect">Date of Birth</label>
                            <Form.Item name='dateOfBirth'>

                                <input style={{ color: "#0041C4", border: "none" }}
                                   disabled />
                            </Form.Item>
                        </div>
                        <div style={{ display: "flex", gap: "20px" }}>
                            <label style={{ fontSize: "14px", fontWeight: "500" }}>Status:</label>
                            <Form.Item valuePropName="checked" initialValue={false} name='status'>

                                <Checkbox style={{ fontSize: "14px", fontWeight: "500" }}

                                />
                            </Form.Item>
                        </div>


                    </div>
                    {/* <div className="profile-btn">
                        <Button className='sign-button' htmlType='submit'>Update </Button>
                    </div> */}
                </div>
            
        </Form>
    );
};

export default ProfileComponentView;
