import React, { useState } from 'react';
import Sidebar from '../../../Components/Sidebar';
import Navbar from '../../../Components/Navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faUser,faArrowDownLong,faPenToSquare,faEye } from '@fortawesome/free-solid-svg-icons';
import SearchBar from '../../../Components/SearchBar';
import TraningComponent from '../../../Components/TraningComponent';
import AddUserTraning from '../../../Components/AddUserTraning';

library.add(faUser,faArrowDownLong,faPenToSquare,faEye);

const Training = () => {
    const [selectedOption, setSelectedOption] = useState('');
    const [selectedOption1, setSelectedOption1] = useState('');
    const [selectedOption2, setSelectedOption2] = useState('');

    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
    };
    const handleSelectChange1 = (event) => {
        setSelectedOption1(event.target.value);
    };
    const handleSelectChange2 = (event) => {
        setSelectedOption2(event.target.value);
    };
    //  new user popup
    const [isVisib, setIsVisib] = useState(false);
    const handleClick = () => {
        setIsVisib(!isVisib)
    };
    const setIsVisible = () => {
        setIsVisib(!isVisib)
    }
    //  new user popup end

    //   sidebar start
    const [sidemenu, setSidemenu] = useState(false);
    const handleToggleSidemenu = () => {
        console.log("working");
        setSidemenu(!sidemenu);
    };
    //   side end
    return (
        <>
            <div className='main-section'>
                <div className={sidemenu ? '' : 'sidebar mob-resp'} >
                    <Sidebar />
                </div>
                <div className='right-side'>
                    <Navbar navbarname={"Training"} setSidemenu={handleToggleSidemenu} />
                    <div style={{ display: "flex", justifyContent: "space-between", margin: "40px" }}>
                        <button onClick={handleClick} className='Add-MHC'>
                            <FontAwesomeIcon icon={faUser} style={{ marginRight: "10px" }} />Add Training
                        </button>
                        <SearchBar />
                        {isVisib && <AddUserTraning setIsVisible={setIsVisible} />}
                    </div>
                    <div className='report-area' style={{justifyContent:"center" ,margin: "20px auto"}}>
                        <input type='text' />
                        <div>
                            <select value={selectedOption} onChange={handleSelectChange}>
                                <option value="">Select Group</option>
                                <option value="option1">Option 1</option>
                                <option value="option2">Option 2</option>
                                <option value="option3">Option 3</option>
                            </select>
                        </div>
                        <div>
                            <select value={selectedOption1} onChange={handleSelectChange1}>
                                <option value="">Select Progess</option>
                                <option value="option1">Option 1</option>
                                <option value="option2">Option 2</option>
                                <option value="option3">Option 3</option>
                            </select>
                        </div>
                        <div>
                            <select value={selectedOption2} onChange={handleSelectChange2}>
                                <option value="">From - To</option>
                                <option value="option1">Option 1</option>
                                <option value="option2">Option 2</option>
                                <option value="option3">Option 3</option>
                            </select>
                        </div>
                        <button className='filter-btn'>FILTER</button>
                    </div>
                    <TraningComponent />
                </div>
            </div>
        </>
    );
};

export default Training;
