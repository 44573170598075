import React, { useEffect, useState } from 'react';
import Ellipse5 from './../assets/Ellipse5.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileLines, faUser, faUserCheck, faTv, faFileInvoice, faMessage } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
//import { RiEyeFill, RiEyeOffFill } from 'react-icons/ri';
import { Link, useLocation } from 'react-router-dom';
import axiosInstance from '../services/axiosInstance';
//import sidemenu from "./../assets/269dd16fa1f5ff51accd09e7e1602267.png"
library.add(faFileLines, faUser, faUserCheck, faTv, faFileInvoice, faMessage);

const Sidebar1 = (props) => {

  // const currentUrl = window.location.href;
  const location = useLocation();
  const currentUrl = location.pathname;
  // const currentUrl=''

  const [activeMenuItem, setActiveMenuItem] = useState(null);
  const [portal, setPortal] = useState()
  const user = localStorage.getItem('user')
  const permission = localStorage.getItem('permission')
  const getUser = JSON.parse(user)
  const per = JSON.parse(permission)

  const roleName = getUser?.roles?.role
  const permissionData = per.data
  const active = permissionData.filter(item =>
    portal?.some(portalItem => portalItem._id === item.portal._id)
  );
  //   const a =active.filter(item =>
  //     portal?.some(portalItem => portalItem._id === item.portal._id)
  //   );
  // console.log(a,'a')
  
  const handleClick = (menu) => {
    setActiveMenuItem(menu);
  };
  const getAllPortal = async () => {
    try {

      const res = await axiosInstance.get('portal')
      setPortal(res?.data)
    } catch (error) {

    }
  }
  useEffect(() => {
    getAllPortal()

  }, [])
  useEffect(() => {
    // Extract menu item from current URL
    const menuItem = getMenuFromUrl(currentUrl);
    console.log("Active menu item:", menuItem);
    // Set active menu item
    setActiveMenuItem(menuItem);
  }, [currentUrl]);
  const getMenuFromUrl = (url) => {
    // Logic to extract menu item from URL
    // You may need to adjust this based on your routing structure
    // For example, if URL is "/MHCAdmin", return "MHCAdmin"
    // If URL is "/otherRoute", return "otherRoute"
    return url.replace('/', '');
  };
  return (
    <>
      {/* <img className='sidemenu-img' src={sidemenu}/> */}
      <div className='bg'>
        <Link to='/BlueGoatDashboard' style={{ textDecoration: "none" }} >
          <div className='profile-photo'>
            <img src={Ellipse5} alt="some text" />
            <div className='name'>
              <h6>
                {`${getUser?.firstName} ${getUser.lastName}`}
              </h6>
              <p>
                {roleName}
              </p>
            </div>
          </div>
        </Link>
        <div className='side-menu' >
          {
            roleName === 'BG Admin' && <Link to='/MHC' onClick={() => handleClick('MHC')} style={{backgroundColor:activeMenuItem === 'MHC' ? '#ffffff' : '',}}>
              <ul style={{ margin: "0 auto" }}>
                <li>
                  <FontAwesomeIcon icon={faUser} style={{color:activeMenuItem === 'MHC' ?"#0041C4":''}}/>
                  <p style={{color:activeMenuItem === 'MHC' ?"#0041C4":''}}>
                    Mental Health Company
                  </p>
                </li>
              </ul>
            </Link>
          }

          {active.some((item) => item.permission && item.portal._id === "662bec91cffacccebf785bc1") && currentUrl !== "https://emr.instantsolutionslab.site/admindashboard" && (
            <Link to="/MHCAdmin" onClick={() => handleClick('MHCAdmin')} style={{backgroundColor:activeMenuItem === 'MHCAdmin' ? '#ffffff' : ''}}>
              <ul style={{ margin: "0 auto" }}>
                <li>
                  <FontAwesomeIcon icon={faFileLines} style={{color:activeMenuItem === 'MHCAdmin' ?"#0041C4":''}}/>
                  <p style={{color:activeMenuItem === 'MHCAdmin' ?"#0041C4":''}}>
                    MHC Admin
                  </p>
                </li>
              </ul>
            </Link>
          )}
          {
            active.some(item => item.permission && item.portal._id === '62beca8cffacccebf785bc7') && <Link to="/divisions" onClick={() => handleClick('divisions')} style={{backgroundColor:activeMenuItem === 'divisions' ? '#ffffff' : ''}}>
              <ul style={{ margin: "0 auto" }}>
                <li>
                  <FontAwesomeIcon icon={faFileInvoice} style={{color:activeMenuItem === 'divisions' ?"#0041C4":''}}/>
                  <p style={{color:activeMenuItem === 'divisions' ?"#0041C4":''}}>
                    Divisions
                  </p>
                </li>
              </ul>
            </Link>
          }
          {
            active.some(item => item.
              permission && item.portal._id === '662becb3cffacccebf785bca'
            ) && <Link to="/providers" onClick={() => handleClick('providers')} style={{backgroundColor:activeMenuItem === 'providers' ? '#ffffff' : ''}}>
              <ul style={{ margin: "0 auto" }}>
                <li>
                  <FontAwesomeIcon icon={faFileInvoice} style={{color:activeMenuItem === 'providers' ?"#0041C4":''}}/>
                  <p style={{color:activeMenuItem === 'providers' ?"#0041C4":''}}>
                    Providers
                  </p>
                </li>
              </ul>
            </Link>
          }
          {

            active.some(item => item.
              permission && item.portal._id === '662bfd05cffacccebf785bce'
            ) && <Link to="/client-information" onClick={() => handleClick('client-information')} style={{backgroundColor:activeMenuItem === 'client-information' ? '#ffffff' : ''}}>
              <ul style={{ margin: "0 auto" }}>
                <li>
                  <FontAwesomeIcon icon={faFileLines} style={{color:activeMenuItem === 'client-information' ?"#0041C4":''}}/>
                  <p style={{color:activeMenuItem === 'client-information' ?"#0041C4":''}}>
                    Clients
                  </p>
                </li>
              </ul>
            </Link>
          }
          {
            active.some(item => item.
              permission && item.portal._id === '662bfd14cffacccebf785bd1'
            ) && <Link to="/thirdparty" onClick={() => handleClick('thirdparty')} style={{backgroundColor:activeMenuItem === 'thirdparty' ? '#ffffff' : ''}}>
              <ul style={{ margin: "0 auto" }}>
                <li>
                  <FontAwesomeIcon icon={faFileInvoice} style={{color:activeMenuItem === 'thirdparty' ?"#0041C4":''}}/>
                  <p style={{color:activeMenuItem === 'thirdparty' ?"#0041C4":''}}>
                    Third Parties
                  </p>
                </li>
              </ul>
            </Link>
          }
          {
            active.some(item => item.
              permission && item.portal._id === '662bfd2dcffacccebf785bd5'
            ) &&
            <Link to="/businesses" onClick={() => handleClick('businesses')} style={{backgroundColor:activeMenuItem === 'businesses' ? '#ffffff' : ''}}>
              <ul style={{ margin: "0 auto" }}>
                <li>
                  <FontAwesomeIcon icon={faFileInvoice} style={{color:activeMenuItem === 'businesses' ?"#0041C4":''}}/>
                  <p style={{color:activeMenuItem === 'businesses' ?"#0041C4":''}}>
                    Businesses
                  </p>
                </li>
              </ul>
            </Link>
          }
          {

            active.some(item => item.
              permission && item.portal._id === '662bfd37cffacccebf785bd8'
            ) && <Link to="/employees" onClick={() => handleClick('employees')} style={{backgroundColor:activeMenuItem === 'employees' ? '#ffffff' : ''}}>
              <ul style={{ margin: "0 auto" }}>
                <li>
                  <FontAwesomeIcon icon={faFileInvoice} style={{color:activeMenuItem === 'employees' ?"#0041C4":''}}/>
                  <p style={{color:activeMenuItem === 'employees' ?"#0041C4":''}}>
                    Employees
                  </p>
                </li>
              </ul>
            </Link>
          }
          <Link to="/businessadmin" onClick={() => handleClick('businessadmin')} style={{backgroundColor:activeMenuItem === 'businessadmin' ? '#ffffff' : ''}}>
            <ul style={{ margin: "0 auto" }}>
              <li>
                <FontAwesomeIcon icon={faFileLines} style={{color:activeMenuItem === 'businessadmin' ?"#0041C4":''}}/>
                <p style={{color:activeMenuItem === 'businessadmin' ?"#0041C4":''}}>
                  Business Admin
                </p>
              </li>
            </ul>
          </Link>
          <Link to="/message" onClick={() => handleClick('message')} style={{backgroundColor:activeMenuItem === 'message' ? '#ffffff' : ''}}>
            <ul style={{ margin: "0 auto" }}>
              <li>
                <FontAwesomeIcon icon={faMessage} style={{color:activeMenuItem === 'message' ?"#0041C4":''}}/>

                <p style={{color:activeMenuItem === 'message' ?"#0041C4":''}}>
                  Messages
                </p>
              </li>
            </ul>
          </Link>
          <Link to="/module-management" onClick={() => handleClick('module-management')} style={{backgroundColor:activeMenuItem === 'module-management' ? '#ffffff' : ''}}>
            <ul style={{ margin: "0 auto" }}>
              <li>
                <FontAwesomeIcon icon={faFileLines} style={{color:activeMenuItem === 'module-management' ?"#0041C4":''}}/>
                <p style={{color:activeMenuItem === 'module-management' ?"#0041C4":''}}>
                  Modules
                </p>
              </li>
            </ul>
          </Link>
          <Link to="/survey" onClick={() => handleClick('survey')} style={{backgroundColor:activeMenuItem === 'survey' ? '#ffffff' : ''}}>
            <ul style={{ margin: "0 auto" }}>
              <li>
                <FontAwesomeIcon icon={faFileLines} style={{color:activeMenuItem === 'survey' ?"#0041C4":''}}/>
                <p style={{color:activeMenuItem === 'survey' ?"#0041C4":''}}>
                  Survey
                </p>
              </li>
            </ul>
          </Link>
          {/* <Link to="">
            <ul style={{ margin: "0 auto" }}>
              <li>
                <FontAwesomeIcon icon={faFileLines} />
                <p>
                  System Emails
                </p>
              </li>
            </ul>
          </Link> */}
          <Link to="/payment" onClick={() => handleClick('payment')} style={{backgroundColor:activeMenuItem === 'payment' ? '#ffffff' : ''}}>
            <ul style={{ margin: "0 auto" }}>
              <li>
                <FontAwesomeIcon icon={faFileLines} style={{color:activeMenuItem === 'MHC' ?"#0041C4":''}}/>
                <p style={{color:activeMenuItem === 'payment' ?"#0041C4":''}}>
                  Payments Setup
                </p>
              </li>
            </ul>
          </Link>
          <Link to="/library" onClick={() => handleClick('library')} style={{backgroundColor:activeMenuItem === 'library' ? '#ffffff' : ''}}>
            <ul style={{ margin: "0 auto" }}>
              <li>
                <FontAwesomeIcon icon={faFileLines} style={{color:activeMenuItem === 'library' ?"#0041C4":''}}/>
                <p style={{color:activeMenuItem === 'library' ?"#0041C4":''}}>
                  Library
                </p>
              </li>
            </ul>
          </Link>
          <Link to="/training" onClick={() => handleClick('training')} style={{backgroundColor:activeMenuItem === 'training' ? '#ffffff' : ''}}>
            <ul style={{ margin: "0 auto" }}>
              <li>
                <FontAwesomeIcon icon={faTv} style={{color:activeMenuItem === 'training' ?"#0041C4":''}}/>
                <p style={{color:activeMenuItem === 'training' ?"#0041C4":''}}>
                  Training
                </p>
              </li>
            </ul>
          </Link>
          <Link to="/role-management" onClick={() => handleClick('role-management')} style={{backgroundColor:activeMenuItem === 'role-management' ? '#ffffff' : ''}}>
            <ul style={{ margin: "0 auto" }}>
              <li>
                <FontAwesomeIcon icon={faFileLines} style={{color:activeMenuItem === 'role-management' ?"#0041C4":''}}/>
                <p style={{color:activeMenuItem === 'role-management' ?"#0041C4":''}}>
                  Role Management
                </p>
              </li>
            </ul>
          </Link>
          <Link to="/attendance" onClick={() => handleClick('attendance')} style={{backgroundColor:activeMenuItem === 'attendance' ? '#ffffff' : ''}}>
            <ul style={{ margin: "0 auto" }}>
              <li>
                <FontAwesomeIcon icon={faUserCheck} style={{color:activeMenuItem === 'attendance' ?"#0041C4":''}}/>
                <p style={{color:activeMenuItem === 'attendance' ?"#0041C4":''}}>
                  Attendance
                </p>
              </li>
            </ul>
          </Link>
        </div>
        <div className='side-logo'>
          <h3 style={{ color: "#fff", fontWeight: "700", fontSize: "36px" }}>
            Blue Goat
          </h3>
        </div>
      </div>

    </>
  );
};

export default Sidebar1;
