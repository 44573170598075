import React, { useState } from 'react';
import Sidebar from '../../../Components/Sidebar';
import Navbar from '../../../Components/Navbar';
import { Form, FormControl } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

const Library = () => {
    const navigate = useNavigate();
    const signIn = (data) => {
      navigate(data);
    }
    //   sidebar start
    const [sidemenu, setSidemenu] = useState(false);
    const handleToggleSidemenu = () => {
        console.log("working");
        setSidemenu(!sidemenu);
    };
    //   side end
    return (
        <>
            <div className='main-section'>
                <div className={sidemenu ? '' : 'sidebar mob-resp'} >
                    <Sidebar />
                </div>
                <div className='right-side'>
                    <Navbar navbarname={"Library"} setSidemenu={handleToggleSidemenu} />
                    <div className='library-sreach'>
                        <h1>Search DX Codes</h1>
                        <div className='library-heading' >
                            <Form className='form-mobile' >
                                <FontAwesomeIcon style={{ position: "relative", left: "35px", height: "20px" }} icon={faSearch} className="search-icon" />
                                <FormControl type="text" placeholder="Search..."  className="mr-sm-2 sreach-library" />
                            </Form>
                            <button onClick={()=>{signIn("/addnew-library")}} style={{width:"18%", backgroundColor:"#00C495"}} className='progress-btn'>Add New Library</button>
                        </div>
                    </div>
                    <div className='library-list'>
                        <div style={{ minWidth: "432px" }}>
                            <div className='library-box'>
                                <div style={{ width: "35%" }}>
                                    <h4>Diagnostic Code</h4>
                                    <h6>F32.9 Major depressive disorder, single episode</h6>
                                </div>
                                <div style={{ width: "50%" }}>
                                    <p>"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                        incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                                        exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                </div>
                                <div style={{ width: "22%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <button onClick={()=>{signIn("/diagnostic-code")}} className='view-btn'>View License</button>
                                </div>
                            </div>
                            <div className='library-box'>
                                <div style={{ width: "35%" }}>
                                    <h4>Diagnostic Code</h4>
                                    <h6>F32.9 Major depressive disorder, single episode</h6>
                                </div>
                                <div style={{ width: "50%" }}>
                                    <p>"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                        incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                                        exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                </div>
                                <div style={{ width: "22%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <button onClick={()=>{signIn("/diagnostic-code")}} className='view-btn'>View License</button>
                                </div>
                            </div>
                            <div className='library-box'>
                                <div style={{ width: "35%" }}>
                                    <h4>Diagnostic Code</h4>
                                    <h6>F32.9 Major depressive disorder, single episode</h6>
                                </div>
                                <div style={{ width: "50%" }}>
                                    <p>"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                        incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                                        exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                </div>
                                <div style={{ width: "22%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <button onClick={()=>{signIn("/diagnostic-code")}} className='view-btn'>View License</button>
                                </div>
                            </div>
                            <div className='library-box'>
                                <div style={{ width: "35%" }}>
                                    <h4>Diagnostic Code</h4>
                                    <h6>F32.9 Major depressive disorder, single episode</h6>
                                </div>
                                <div style={{ width: "50%" }}>
                                    <p>"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                        incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                                        exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                </div>
                                <div style={{ width: "22%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <button onClick={()=>{signIn("/diagnostic-code")}} className='view-btn'>View License</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Library;
