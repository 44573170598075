import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { faArrowDownLong } from '@fortawesome/free-solid-svg-icons';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import traning1 from './../assets/traning1.jpg';
import traning2 from './../assets/traning2.jpg';
import traning3 from './../assets/traning3.jpg';
import traning4 from './../assets/traning4.jpg';
import traning5 from './../assets/traning5.jpg';
import traning6 from './../assets/traning6.jpg';
import traning7 from './../assets/traning7.jpg';
import { useNavigate } from 'react-router-dom';

library.add(faUser);
library.add(faArrowDownLong);
library.add(faPenToSquare);
library.add(faEye);

const TraningComponent = () => {
    const navigate = useNavigate();
    const signIn = (data) => {
        navigate(data);
    }

    const userDataArray = [
        {
            id: 1,
            name: 'Anatomical models',
            creator: 'Taylor Adamss',
            role: 'Bluegoat Admin',
            email: 'mikemints@gmail.com',
            phoneNumber: '+1 58749849848',
            image: traning1, 
        },
        {
            id: 1,
            name: 'Training simulators',
            creator: 'Johnathon',
            role: 'MHC  Admin',
            email: 'mikemints@gmail.com',
            phoneNumber: '+1 58749849848',
            image: traning2, 
        },
        {
            id: 1,
            name: 'Patient simulators',
            creator: 'Tom Willey',
            role: 'Provider',
            email: 'mikemints@gmail.com',
            phoneNumber: '+1 58749849848',
            image: traning3, 
        },
        {
            id: 1,
            name: 'Training manikins',
            creator: 'Jim Gardener',
            role: 'MHC  Admin',
            email: 'mikemints@gmail.com',
            phoneNumber: '+1 58749849848',
            image: traning4,
        },
        {
            id: 1,
            name: 'Simulation suits',
            creator: 'James Smith',
            role: 'Bluegoat Admin',
            email: 'mikemints@gmail.com',
            phoneNumber: '+1 58749849848',
            image: traning5,
        },
        {
            id: 1,
            name: 'Teaching stethoscopes',
            creator: 'Jennifer',
            role: 'Provider',
            email: 'mikemints@gmail.com',
            phoneNumber: '+1 58749849848',
            image: traning6,
        },
        {
            id: 1,
            name: 'Teaching microscopes',
            creator: 'John',
            role: 'Bluegoat Admin',
            email: 'mikemints@gmail.com',
            phoneNumber: '+1 58749849848',
            image: traning7,
        },
        
    ];
    return (
        <>
            <div style={{ width: "100%", overflow: "scroll" }}>
                <div style={{ minWidth: "1022px", overflow: "scroll" }}>
                    <div className='admin-heading'>
                        <p className='admin-p w-15'>Training Name <FontAwesomeIcon style={{ fontSize: "13px" }} icon={faArrowDownLong} /></p>
                        <p className='admin-p w-16'>Training Creator <FontAwesomeIcon style={{ fontSize: "13px" }} icon={faArrowDownLong} /></p>
                        <p className='admin-p w-17'>User Role <FontAwesomeIcon style={{ fontSize: "13px" }} icon={faArrowDownLong} /></p>
                        <p className='admin-p w-13'>User Email <FontAwesomeIcon style={{ fontSize: "13px" }} icon={faArrowDownLong} /></p>
                        <p className='admin-p w-17'>Phone Number <FontAwesomeIcon style={{ fontSize: "13px" }} icon={faArrowDownLong} /></p>
                        <p className='admin-p w-17'>Action</p>
                    </div>
                    {userDataArray.map((user) => (
                        <div key={user.id} className='admin-area'>
                            <div onClick={() => { signIn("") }} className='admin-text admin-p w-17' style={{gap:"0px"}}>
                                <img src={user.image} alt="some text" />
                                <p>{user.name}</p>
                            </div>
                            <p className='admin-p w-17'>{user.creator}</p>
                            <p className='admin-p w-16'>{user.role}</p>
                            <p className='admin-p w-14'>{user.email}</p>
                            <p className='admin-p w-17'>{user.phoneNumber}</p>
                            <div className='admin-p' style={{ display: "flex", gap: "11px", justifyContent: "center" }}>
                                <button onClick={() => { signIn("/user-traning") }} className='edit-btn'> <FontAwesomeIcon icon={faPenToSquare} /> Edit</button>
                                <button onClick={() => { signIn("/user-traning") }} className='view-btn' style={{ marginRight: "-20px" }}><FontAwesomeIcon icon={faEye} /> View</button>
                            </div>
                        </div>
                    ))}
                    
                </div>
            </div>
        </>
    );
};

export default TraningComponent;
