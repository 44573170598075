import React, { useState } from 'react';
import Sidebar from '../../../Components/Sidebar';
import Navbar from '../../../Components/Navbar';
import { Form, FormControl } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

const AddNewLibrary = () => {
    const [selectedOption, setSelectedOption] = useState('');
    const [selectedOption1, setSelectedOption1] = useState('');
    const navigate = useNavigate();
    const signIn = (data) => {
        navigate(data);
    }
    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
    };
    const handleSelectChange1 = (event) => {
        setSelectedOption1(event.target.value);
    };
    //   sidebar start
    const [sidemenu, setSidemenu] = useState(false);
    const handleToggleSidemenu = () => {
        console.log("working");
        setSidemenu(!sidemenu);
    };
    //   side end
    return (
        <>
            <div className='main-section'>
                <div className={sidemenu ? '' : 'sidebar mob-resp'} >
                    <Sidebar />
                </div>
                <div className='right-side'>
                    <Navbar navbarname={"Library"} setSidemenu={handleToggleSidemenu} />
                    <div className='library-sreach'>
                        <h1>Search DX Codes</h1>
                        <div className='library-heading' >
                            <Form className='form-mobile' >
                                <FontAwesomeIcon style={{ position: "relative", left: "35px", height: "20px" }} icon={faSearch} className="search-icon" />
                                <FormControl type="text" placeholder="Search..." name="query" className="mr-sm-2 sreach-library" />
                            </Form>
                            <select style={{ width: "18%", padding: "8px" }} className='progress-btn' value={selectedOption} onChange={handleSelectChange}>
                                <option value="">Library Dropdown</option>
                                <option value="option1">Option 1</option>
                                <option value="option2">Option 2</option>
                                <option value="option3">Option 3</option>
                            </select>
                            <button onClick={()=>{signIn("/library-management")}} style={{ width: "18%", }} className='progress-btn'>Add New Library</button>
                        </div>
                    </div>
                    <div className='library-diagnostic'>
                        <h1 style={{ fontSize: "32px" }}>Add New Library</h1>
                        <div className='new-library'>
                            <h4>Treatment Plans</h4>
                            <input type='text' />
                            <h4>Attach Document to Library</h4>
                            <select className='select-library' value={selectedOption1} onChange={handleSelectChange1}>
                                <option value="">Intake Legal Form</option>
                                <option value="option1">Option 1</option>
                                <option value="option2">Option 2</option>
                                <option value="option3">Option 3</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AddNewLibrary;
