import React, { useState, useRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Maskgroup from '../assets/Mask-group.png';
import Button from 'react-bootstrap/Button';

function AddUserTraning({ setIsVisible }) {
    // const [password, setPassword] = useState('');
    // const [showPassword, setShowPassword] = useState(false);
    const [imageSrc, setImageSrc] = useState(null);
    // const handleTogglePassword = () => {
    //     setShowPassword((prevShowPassword) => !prevShowPassword);
    // };

    const handleClose = () => {
        setIsVisible(false);
    };
    const fileInputRef = useRef(null);

    const handleClick = () => {
        fileInputRef.current.click();
    };
    const handleFileChange = (event) => {
        const file = event.target.files[0];

        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImageSrc(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };
    return (
        <>
            <div className='blur-background'>
                <div style={{ margin: "8px auto" }} className='new-user'>
                    <div className='header'>
                        <h4>Add Training</h4>
                        <FontAwesomeIcon style={{ cursor: "pointer" }} icon={faTimes} onClick={handleClose} />
                    </div>
                    <hr style={{ margin: "0" }} />
                    <div>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <img style={{ width: "97px", height: "97px" }} className='upload-img' src={imageSrc || Maskgroup} alt="some text" />
                            <input
                                type="file"
                                accept="image/*"
                                style={{ display: 'none' }}
                                ref={fileInputRef}
                                onChange={handleFileChange}
                            />
                            <Button style={{ top: "98px" }} onClick={handleClick}>
                                +
                            </Button>
                        </div>
                        <div className='email-area employe-inputs'>
                            <div className='user-inputs'>
                                <p>Training Name :</p>
                                <input type='text' placeholder='Please enter your Name' />
                            </div>
                            <div className='user-inputs'>
                                <p>Training Description :</p>
                                <input type='text' placeholder='Please enter your Description' />
                            </div>
                            <div className='user-inputs'>
                                <p>Training Duration :</p>
                                <input type='text' placeholder='Please enter your Duration' />
                            </div>
                            <div className='user-inputs'>
                                <p>Training Details :</p>
                                <input type='text' placeholder='Please enter your Details' />
                            </div>
                            {/* <div className='user-inputs'>
                                <p>Password</p>
                                <div style={{ position: 'relative' }}>
                                    <input
                                        type={showPassword ? 'text' : 'password'}
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                    <div
                                        style={{
                                            position: 'absolute',
                                            top: '50%',
                                            right: '16px',
                                            transform: 'translateY(-50%)',
                                            cursor: 'pointer',
                                        }}
                                        onClick={handleTogglePassword}
                                    >
                                        {showPassword ? <RiEyeOffFill /> : <RiEyeFill />}
                                    </div>
                                </div>
                            </div> */}
                            <div style={{ display: "flex", justifyContent: "center", gap: "11px", margin: "25px auto" }}>
                                <button onClick={handleClose} style={{ width: "35%", height: "42px" }} className='cancel-btn'>Cancel</button>
                                <button style={{ width: "40%", height: "42px" }} className='apply-btn'>Add New</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default AddUserTraning;
