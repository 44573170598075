import React, { useEffect, useState } from 'react';
import Sidebar from '../../../Components/Sidebar';
import Navbar from '../../../Components/Navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import SearchBar from '../../../Components/SearchBar';
import Admin from '../../../Components/Admin';
import NewUser from '../../../Components/NewUser';
import axiosInstance from '../../../services/axiosInstance';
import { useNavigate } from 'react-router-dom';

library.add(faUser);

const Businesses = () => {
    const [selectedOption, setSelectedOption] = useState('');
    const [selectedOption1, setSelectedOption1] = useState('');
    const [selectedOption2, setSelectedOption2] = useState('');
    const[userData,setUserData]=useState()
    const navigate=useNavigate()
    //  new user popup
    const [isVisib, setIsVisib] = useState(false);
    const handleClick = () => {
        // setIsVisib(!isVisib)
        navigate('/user-details/name/businesses');
    };
    const setIsVisible = () => {
        setIsVisib(!isVisib)

    }
    //  new user popup end
    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
    };
    const handleSelectChange1 = (event) => {
        setSelectedOption1(event.target.value);
    };
    const handleSelectChange2 = (event) => {
        setSelectedOption2(event.target.value);
    };
    //   sidebar start
    const [sidemenu, setSidemenu] = useState(false);
    const handleToggleSidemenu = () => {
        console.log("working");
        setSidemenu(!sidemenu);
    };
    const getAllUser= async ()=>{
        try {
            // const res= await axiosInstance.get('user/getByRole/66271114043997f9db27c19c')
            const res= await axiosInstance.get('user/getByRole/662c06070a775f5b72ebe9cc')
            if(res&&res.data){
                setUserData(res?.data?.data)

            }
            
        } catch (error) {
            
        }

    }
    useEffect(()=>{
        getAllUser()
       

    },[])
    //   side end
    return (
        <>
            <div className='main-section'>
                <div className={sidemenu ? '' : 'sidebar mob-resp'} >
                    <Sidebar />
                </div>
                <div className='right-side'>
                    <Navbar navbarname={"Business"} setSidemenu={handleToggleSidemenu} />
                    <div style={{ display: "flex", justifyContent: "space-between", margin: "40px" }}>
                        <button onClick={handleClick} className='Add-MHC'>
                            <FontAwesomeIcon icon={faUser} style={{ marginRight: "10px" }} />Add New Business
                        </button>
                        <SearchBar />
                        {isVisib && <NewUser setIsVisible={setIsVisible} />}
                    </div>
                    <div className='report-area' style={{ justifyContent: "center", margin: "20px auto" }}>
                        <input type='text' />
                        <div>
                            <select value={selectedOption} onChange={handleSelectChange}>
                                <option value="">Select Group</option>
                                <option value="option1">Option 1</option>
                                <option value="option2">Option 2</option>
                                <option value="option3">Option 3</option>
                            </select>
                        </div>
                        <div>
                            <select value={selectedOption1} onChange={handleSelectChange1}>
                                <option value="">Select Progess</option>
                                <option value="option1">Option 1</option>
                                <option value="option2">Option 2</option>
                                <option value="option3">Option 3</option>
                            </select>
                        </div>
                        <div>
                            <select value={selectedOption2} onChange={handleSelectChange2}>
                                <option value="">From - To</option>
                                <option value="option1">Option 1</option>
                                <option value="option2">Option 2</option>
                                <option value="option3">Option 3</option>
                            </select>
                        </div>
                        <button className='filter-btn'>FILTER</button>
                    </div>
                    <Admin name={'User Name'} email={'User Email'} mhcname={'Business Name'} Provider={'User Role'} data={userData} update={'businesses'}/>
                </div>
            </div>
        </>
    );
};

export default Businesses;
