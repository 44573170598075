import React, { useState, useRef, useEffect } from 'react';
import GenderSelection from './GenderSelection';
// import Form from 'react-bootstrap/Form';
//import Button from 'react-bootstrap/Button';
import Maskgroup from '../assets/Ellipse8.png';
import { Button, Checkbox, Form, Select, Spin, Upload, notification } from 'antd';
import axiosInstance from '../services/axiosInstance';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { UploadOutlined } from '@ant-design/icons';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import Sidebar from '../Components/Sidebar'

function FormViewMhc() {


    // const [date, setDate] = useState('');
    const [imageSrc, setImageSrc] = useState(null);
    const [imageUrl, setImageUrl] = useState(null);
    const [sidemenu, setSidemenu] = useState(false);
    const dateInputRef = useRef(null);
    const [loading, setLoading] = useState(false)
    const [role, setRole] = useState()
    const [portal, setPortal] = useState()
    // const handleChange = (e) => {
    //     setDate(e.target.value);
    // };
    const navigate = useNavigate()
    const [form] = Form.useForm();
    const [isChecked, setIsChecked] = useState(false);

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };
    const fileInputRef = useRef(null);
    const location = useLocation();
    const idd = location.state;
    const { id } = useParams()



    const getmhcById = async () => {
        try {

            const res = await axiosInstance.get(`user/getMhc/${id}`)
            const data = res?.data?.data
            // setRole(data)
            // console.log(data, 'role data by id')
            // setPortal(data)

            form.setFieldsValue(data);
        } catch (error) {
            console.log(error)

        }

    }
    useEffect(() => {
        if (id) {

            getmhcById()
        }
        // getAllPortal()
        // getAllRole()
        // getRoleById()
    }, [])
    return (
        <div className='main-section'>
            <div className={sidemenu ? '' : 'sidebar mob-resp'} >
                <Sidebar />
            </div>
            <div className='right-side'>

                <Form form={form}>
                    <Spin size='large' spinning={loading}>


                        <div className='ContactInformation'>
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <Upload
                                    // accept="image/*"
                                    name="avatar"
                                    listType="picture-circle"
                                    className="avatar-uploader"
                                    // onChange={handleChange}
                                    maxCount={1} // Limit to upload only one file
                                    fileList={[]} // Pass empty fileList to remove default file list display
                                    showUploadList={false} // Hide default upload list display
                                >
                                    {/* <Button icon={<UploadOutlined />}>Upload Image</Button>
                     */}
                                    {imageUrl ? (
                                        <img
                                            src={imageUrl}
                                            alt="avatar"
                                            style={{
                                                width: '100%',
                                            }}
                                        />
                                    ) : (
                                        <button
                                            style={{
                                                border: 0,
                                                background: 'none',
                                            }}
                                            type="button"
                                        >
                                            <PlusOutlined />
                                            <div
                                                style={{
                                                    marginTop: 8,
                                                }}
                                            >
                                                Upload
                                            </div>
                                        </button>
                                    )}

                                </Upload>
                                {/* <img className='upload-img' src={imageSrc || Maskgroup} alt="some text" />
                        <input
                            type="file"
                            accept="image/*"
                            style={{ display: 'none' }}
                            ref={fileInputRef}
                            onChange={handleFileChange}
                        /> */}
                            </div>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div style={{ width: "50%", }}>
                                {/* <div className='email-area create-inputs employe-inputs'>
                            <div className='First-Name'>
                                <p>First Name</p>
                                <Form.Item name='firstName'>

                                    <input type='text' />
                                </Form.Item>
                            </div>
                            <div className='First-Name'>
                                <p>Last Name</p>
                                <Form.Item name='lastName'>

                                    <input type='text' />
                                </Form.Item>
                            </div>
                        </div> */}
                                <div className='email-area employe-inputs'>
                                    <p>Company Name </p>
                                    <Form.Item name='companyName'>

                                        <input disabled={true} />
                                    </Form.Item>
                                </div>
                                <div className='email-area employe-inputs'>
                                    <p>Department:</p>
                                    <Form.Item name='Department'>

                                        <input disabled={true} />
                                    </Form.Item>
                                </div>
                                <div className='email-area employe-inputs'>
                                    <p>Mobile Phone (Personal):</p>
                                    <Form.Item name='mobilePhone'>

                                        <input type='text' value="MikeMints@gmail.com" disabled={true} />
                                    </Form.Item>
                                </div>
                                <div className='email-area employe-inputs'>
                                    <p>company Phone Number:</p>
                                    <Form.Item name='companyPhoneNumber'>

                                        <input type='text' value="MikeMints9910" disabled={true} />
                                    </Form.Item>
                                </div>
                                <div className='email-area employe-inputs'>
                                    <p>Company Address (Physical) including City :state zip code</p>
                                    <Form.Item >

                                        <input type='text' disabled={true} />
                                    </Form.Item>
                                </div>

                                <div className='email-area employe-inputs'>
                                    <p>Billing Address:</p>
                                    <Form.Item name='billingAddress'>

                                        <input type='text' disabled={true} />
                                    </Form.Item>
                                </div>
                                <div className='email-area employe-inputs'>
                                    <p>Executive Assistant (EA) / Adminstrative Assistanat Email:</p>
                                    <Form.Item name='eaEmail'>

                                        <input type='text' value="Abc" disabled={true} />
                                    </Form.Item>
                                </div>


                            </div>
                            <div style={{ width: "50%" }}>

                                {
                                    <div className='email-area employe-inputs'>
                                    <p>Company Officer Name:</p>
                                    <Form.Item name='companyOfficerName'>
    
                                        <input type='text' value="Blue Goat" disabled={true}/>
                                    </Form.Item>
                                </div> 
                                }


                                <div className='email-area create-inputs employe-inputs'>
                                    <div className='First-Name'>
                                        <p>Primary Email:</p>
                                        <Form.Item name='primaryEmail'>

                                            <input type='email' value="James" disabled={true} />
                                        </Form.Item>
                                    </div>
                                    <div className='First-Name'>
                                        <p>Seconday Email:</p>
                                        <Form.Item name='secondaryEmail'>

                                            <input type='email' value="112200" disabled={true} />
                                        </Form.Item>
                                    </div>
                                </div>
                                <div className='email-area employe-inputs'>
                                    <p>Mobile Phone (Company):</p>
                                    <Form.Item name=''>

                                        <input type='text' value="33333" disabled={true} />
                                    </Form.Item>
                                </div>
                                <div className='email-area employe-inputs'>
                                    <p>Extension</p>
                                    <Form.Item name='extension'>

                                        <input type='text' value="blue" disabled={true} />
                                    </Form.Item>
                                </div>
                                {
                                    !id && <div className='email-area employe-inputs'>
                                        <p>Company Address</p>
                                        <Form.Item name='companyAddress'>

                                            <input type='text' disabled={true} />
                                        </Form.Item>
                                    </div>
                                }

                                {/* <div className='email-area employe-inputs'>
                        <p>Ethnicity</p>
                        <Form.Item name='ethnicity'>
                        <input type='text'  />

                        </Form.Item>
                    </div> */}
                                <div className='email-area employe-inputs'>
                                    <p>Executive Assistant (EA) / Adminstrative Assistanat Name:</p>
                                    <Form.Item name='eaName'>

                                        <input type='text' value="2114411$" disabled={true} />
                                    </Form.Item>
                                </div>
                                <div className='email-area employe-inputs'>
                                    <p>Executive Assistant (EA) / Adminstrative Assistanat Mobile Number :</p>
                                    <Form.Item name='eaPhoneNumber'>

                                        <input
                                            type='text'
                                            value="www.abc.com"
                                            disabled={true}
                                        />
                                    </Form.Item>
                                </div>
                            </div>
                        </div>
                        <div className='email-area employe-inputsmhc'>
                            <p>Executive Assistant (EA) / Adminstrative Assistanat Company Mobile Number : </p>
                            <Form.Item name='eaCompanyPhoneNumber'>
                                <input type='text' style={{ width: '87%' }} disabled={true} />

                            </Form.Item>
                        </div>


                    </Spin>
                </Form>
            </div>

        </div>
    );
};

export default FormViewMhc;
