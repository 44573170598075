import React, { useState, } from 'react';
import Sidebar from '../../../Components/Sidebar';
import Navbar from '../../../Components/Navbar';
import TraningProfile from '../../../Components/TraningProfile';
import Admin from '../../../Components/Admin';

function UserTraning (){
 //   sidebar start
 const [sidemenu, setSidemenu] = useState(false);
 const handleToggleSidemenu = () => {
     console.log("working");
     setSidemenu(!sidemenu);
 };
 //   side end
  return (
    <>
        <div className='main-section'>
             <div className={sidemenu ? '' : 'sidebar mob-resp'} >
            <Sidebar />
        </div>
        <div className='right-side'>
            <Navbar  navbarname={""} setSidemenu={handleToggleSidemenu}/>
            <TraningProfile />
            <Admin name={'Member Name'} email={'Email'} mhcname={'MHC'} Provider={'Provider'} />
        </div>
    </div>
    </>
  );
};

export default UserTraning;
