import React, { useState, useRef } from 'react';
import GenderSelection from './GenderSelection';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Maskgroup from '../assets/Mask-group.png';

function ConactInformation() {
// eslint-disable-next-line
    const [date, setDate] = useState(''); 
    const [imageSrc, setImageSrc] = useState(null);
    const dateInputRef = useRef(null);
    const handleChange = (e) => {
        setDate(e.target.value);
    };
    const [isChecked, setIsChecked] = useState(false);

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };
    const fileInputRef = useRef(null);

    const handleClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];

        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImageSrc(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };
    return (
        <>
            <div className='ContactInformation'>
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <img className='upload-img' src={imageSrc || Maskgroup} alt="some text" />
                    <input
                        type="file"
                        accept="image/*"
                        style={{ display: 'none' }}
                        ref={fileInputRef}
                        onChange={handleFileChange}
                    />
                    <Button style={{ textAlign: "center" }} onClick={handleClick}>
                        +
                    </Button>
                </div>
                <h2 style={{ textAlign: "center" }}>Contact Information</h2>
            </div>
            <div style={{ display: "flex" }}>
                <div style={{ width: "50%", }}>
                    <div className='email-area create-inputs employe-inputs'>
                        <div className='First-Name'>
                            <p>First Name</p>
                            <input type='text' placeholder='Please enter your First Name' />
                        </div>
                        <div className='First-Name'>
                            <p>Last Name</p>
                            <input type='text' placeholder='Please enter your Last Name' />
                        </div>
                    </div>
                    <div className='email-area employe-inputs'>
                        <p>Phone Number </p>
                        <input type="tel" id="phoneNumber" name="phoneNumber" placeholder="Enter your mobile number" />
                    </div>
                    <div className='email-area employe-inputs'>
                        <p>Address</p>
                        <input type='text' name="address" placeholder="Enter your full address" />
                    </div>
                    <div className='email-area employe-inputs'>
                        <p>Email</p>
                        <input type='email' placeholder='Please enter your email here' />
                    </div>
                    <div className='email-area employe-inputs'>
                        <p>Username</p>
                        <input type='text' placeholder='Username here' />
                    </div>
                    <div className='email-area employe-inputs'>
                        <p>Religion</p>
                        <input type='text' placeholder='Religion here' />
                    </div>
                    <div className='email-area employe-inputs'>
                        <p>Terms:</p>
                        <input type='text' placeholder='Terms here' />
                    </div>
                    <div className='email-area employe-inputs'>
                        <p>Website::</p>
                        <input
                            type='text'
                            placeholder='Website here'
                        />
                    </div>

                </div>
                <div style={{ width: "50%" }}>
                    <div className='email-area employe-inputs'>
                        <p>Company</p>
                        <input type='text' placeholder='Enter Your Company Name Here' />
                    </div>
                    <div className='email-area create-inputs employe-inputs'>
                        <div className='First-Name'>
                            <p>Insurance provider</p>
                            <input type='text' placeholder='enter Insurance provider' />
                        </div>
                        <div className='First-Name'>
                            <p>Insurance Member Id</p>
                            <input type='text' placeholder='Member Id' />
                        </div>
                    </div>
                    <div className='email-area employe-inputs'>
                        <p>Insurance Company Id</p>
                        <input type='text' placeholder='Insurance Company Id' />
                    </div>
                    <div className='email-area employe-inputs'>
                        <p>Title</p>
                        <input type='text' placeholder='Title here' />
                    </div>
                    <div className='email-area employe-inputs'>
                        <p>Password</p>
                        <input type='Password' />
                    </div>
                    <div className='email-area employe-inputs'>
                        <p>Ethnicity</p>
                        <input type='text' placeholder='Ethnicity here' />
                    </div>
                    <div className='email-area employe-inputs'>
                        <p>Payment Stutas</p>
                        <input type='text' placeholder='Payment Stutas here' />
                    </div>
                </div>
            </div>
            <div>
                <div className='date-section ' style={{ margin: "40px auto" }}>
                    <div style={{ display: "flex", gap: "20px" }}>
                        <label style={{ fontSize: "14px", fontWeight: "500" }} htmlFor="countrySelect">Gender</label>
                        <GenderSelection />
                    </div>
                    <div style={{ display: "flex", gap: "20px" }}>
                        <label style={{ fontSize: "14px", fontWeight: "500", marginTop: "3px" }} htmlFor="countrySelect">Date of Birth</label>
                        <input style={{ color: "#0041C4", border: "none" }}
                            type="date"
                            onChange={handleChange}
                            ref={dateInputRef} />
                    </div>
                    <div style={{ display: "flex", gap: "20px" }}>
                        <label style={{ fontSize: "14px", fontWeight: "500" }}>Status:</label>
                        <Form.Check style={{ fontSize: "14px", fontWeight: "500" }}
                            label="Active"
                            type="checkbox"
                            checked={isChecked}
                            onChange={handleCheckboxChange}
                        />
                    </div>
                </div>
                <div className="profile-btn">
                    <button className='sign-button'>Update </button>
                </div>
            </div>
        </>
    );
};

export default ConactInformation;
