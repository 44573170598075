import React, { useState } from 'react';
import Sidebar from '../../../Components/Sidebar';
import Navbar from '../../../Components/Navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const LibraryManagement = () => {
    const [isPopupVisible, setPopupVisible] = useState(false);
   // const [popupUpload, setPopupUpload] = useState(false);

    const handleButtonClick = () => {
        setPopupVisible(true);
    };
    const handleClosePopup = () => {
        setPopupVisible(false);
    };
    //   sidebar start
    const [sidemenu, setSidemenu] = useState(false);
    const handleToggleSidemenu = () => {
        console.log("working");
        setSidemenu(!sidemenu);
    };
    //   side end
    return (
        <>
            <div className='main-section'>
                <div className={sidemenu ? '' : 'sidebar mob-resp'} >
                    <Sidebar />
                </div>
                <div className='right-side'>
                    <Navbar navbarname={"Library"} setSidemenu={handleToggleSidemenu} />
                    <div className='library-management'>
                        <h1>Library Management</h1>
                        <h4>Patient Name:</h4>
                        <p>James Smith</p>
                        <h4>Patient Address:</h4>
                        <p>XYZ Street ABC Road</p>
                        <h4>Patient Condition:</h4>
                        <p>Blood pressure problem</p>
                        <div className='management-input'>
                            <h4>Diagnostic Code</h4>
                            <input type='text' value="ABC101" />
                        </div>
                        <div className='management-input'>
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", margin: "10px 0px" }}>
                                <h4 style={{ margin: "11px 0px 9px 0px" }}>Objective:</h4>
                                <button onClick={handleButtonClick}>Import From Library</button>
                            </div>
                            <input style={{ width: "90%" }} type='text' placeholder='Write Objective here or import it from existing objectives using import from library button' />
                        </div>
                        <div className='management-input'>
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", margin: "10px 0px" }}>
                                <h4 style={{ margin: "11px 0px 9px 0px" }}>Strategy:</h4>
                                <button>Import From Library</button>
                            </div>
                            <input style={{ width: "90%" }} type='text' placeholder='Write Strategy here or import it from existing objectives using import from library button' />
                        </div>
                        <div className='management-input' style={{ marginBottom: "14px" }}>
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", margin: "10px 0px" }}>
                                <h4 style={{ margin: "11px 0px 9px 0px" }}>Goals:</h4>
                                <button>Import From Library</button>
                            </div>
                            <input style={{ width: "90%" }} type='text' placeholder='Write Goal here or import it from existing objectives using import from library button' />
                        </div>
                    </div>


                    {isPopupVisible && (
                        <div className='blur-background'>
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <div className='library-management-Popup'>
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "18px" }}>
                                        <h2>Existing Objective In Library Management System</h2>
                                        <FontAwesomeIcon style={{ cursor: "pointer" }} icon={faTimes} onClick={handleClosePopup} />
                                    </div>
                                    <div className='library-management-Popup-body'>
                                        <div className='management-list'>
                                            <p>This is dummy objective for patients related to blood pressure issues.</p>
                                            <div className='management-list-area'>
                                                <p>Used by 10 Providers</p>
                                                <button>Import +</button>
                                            </div>
                                        </div>
                                        <div className='management-list'>
                                            <p>This is dummy objective for patients related to blood pressure issues.</p>
                                            <div className='management-list-area'>
                                                <p>Used by 10 Providers</p>
                                                <button>Import +</button>
                                            </div>
                                        </div>
                                        <div className='management-list'>
                                            <p>This is dummy objective for patients related to blood pressure issues.</p>
                                            <div className='management-list-area'>
                                                <p>Used by 10 Providers</p>
                                                <button>Import +</button>
                                            </div>
                                        </div>
                                        <div className='management-list'>
                                            <p>This is dummy objective for patients related to blood pressure issues.</p>
                                            <div className='management-list-area'>
                                                <p>Used by 10 Providers</p>
                                                <button>Import +</button>
                                            </div>
                                        </div>
                                        <div className='management-list'>
                                            <p>This is dummy objective for patients related to blood pressure issues.</p>
                                            <div className='management-list-area'>
                                                <p>Used by 10 Providers</p>
                                                <button>Import +</button>
                                            </div>
                                        </div>
                                        <div className='management-list'>
                                            <p>This is dummy objective for patients related to blood pressure issues.</p>
                                            <div className='management-list-area'>
                                                <p>Used by 10 Providers</p>
                                                <button>Import +</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default LibraryManagement;
