import React, { useState } from 'react';
import Sidebar from '../../../Components/Sidebar';
import Navbar from '../../../Components/Navbar';
import backgroundImage from '../../../assets/Ellipse3.png';
import backgroundImage1 from '../../../assets/Ellipse6.png';
import Ellipse5 from './../../../assets/Ellipse5.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

const Chats = () => {


  const containerStyle = {
    backgroundImage: `url(${backgroundImage})`
  };
  const containerStyle1 = {
    backgroundImage: `url(${backgroundImage1})`
  };
  const navigate = useNavigate();
  const navigation = (data) => {
    navigate(data);
  }
  //   sidebar start
  const [sidemenu, setSidemenu] = useState(false);
  const handleToggleSidemenu = () => {
    console.log("working");
    setSidemenu(!sidemenu);
  };
  //   side end
  return (
    <>
      <div className='main-section'>
        <div className={sidemenu ? '' : 'sidebar mob-resp'} >
          <Sidebar />
        </div>
        <div className='right-side'>
          <Navbar navbarname={"Messages"} setSidemenu={handleToggleSidemenu} />
          <div style={{ display: "flex" }}>
            <div className='mobile-view' style={{ width: "25%", backgroundColor: "#e1e1e1b8" }}>
              <div class="people-list" id="people-list">
                <div class="search">
                  <input type="text" placeholder="search" />
                  <i class="fa fa-search"></i>
                </div>
                <ul class="list">
                  <li class="clearfix">
                    <img src={Ellipse5} alt="some text" />
                    <div class="about">
                      <div class="msg-info-name">Mike Mints</div>
                      <div class="status">
                        <FontAwesomeIcon icon={faCircle} style={{ color: "#00ff11", fontSize: "10px" }} /> Online
                      </div>
                    </div>
                  </li>
                </ul>
                <ul class="list">
                  <li class="clearfix">
                    <img src={Ellipse5} alt="some text" />
                    <div class="about">
                      <div class="msg-info-name">Vincent Porter</div>
                      <div class="status">
                        <FontAwesomeIcon icon={faCircle} style={{ color: "#ff0000", fontSize: "10px" }} /> Offline
                      </div>
                    </div>
                  </li>
                </ul>
                <ul class="list">
                  <li class="clearfix">
                    <img src={Ellipse5} alt="some text" />
                    <div class="about">
                      <div class="msg-info-name">Vincent Porter</div>
                      <div class="status">
                        <FontAwesomeIcon icon={faCircle} style={{ color: "#ff0000", fontSize: "10px" }} /> Offline
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <section class="msger">
              <header class="msger-header">
                <div class="msger-header-options">
                  <span><i class="fas fa-cog"></i></span>
                </div>
              </header>

              <main class="msger-chat">
                <div class="msg left-msg">
                  <div
                    class="msg-img"
                    style={containerStyle}
                  ></div>

                  <div class="msg-bubble">
                    <div class="msg-info">
                      <div class="msg-info-name">Mike Mints </div>
                      <div class="msg-info-time">12:45</div>
                    </div>

                    <div class="msg-text">
                      Hi, welcome to SimpleChat! Go ahead and send me a message.  <span role="img" aria-label="Smiling face with smiling eyes">😄</span>
                    </div>
                  </div>
                </div>

                <div class="msg right-msg">
                  <div
                    class="msg-img"
                    style={containerStyle1}
                  ></div>

                  <div class="msg-bubble">
                    <div class="msg-info">
                      <div class="msg-info-name">Vincent Porter</div>
                      <div class="msg-info-time">12:46</div>
                    </div>

                    <div class="msg-text">
                      Hi, I am coming there in few <br />
                      minutes. Please wait!! I am in taxi right now.
                    </div>
                  </div>
                </div>
                <div class="msg left-msg">
                  <div
                    class="msg-img"
                    style={containerStyle}
                  ></div>

                  <div class="msg-bubble">
                    <div class="msg-info">
                      <div class="msg-info-name">Mike Mints </div>
                      <div class="msg-info-time">12:45</div>
                    </div>

                    <div class="msg-text">
                      Hi, welcome to SimpleChat! Go ahead and send me a message.  <span role="img" aria-label="Smiling face with smiling eyes">😄</span>
                    </div>
                  </div>
                </div>

                <div class="msg right-msg">
                  <div
                    class="msg-img"
                    style={containerStyle1}
                  ></div>

                  <div class="msg-bubble">
                    <div class="msg-info">
                      <div class="msg-info-name">Vincent Porter</div>
                      <div class="msg-info-time">12:46</div>
                    </div>

                    <div class="msg-text">
                      Thank you very much, i am waiting here at
                      StarBuck cafe.
                    </div>
                  </div>
                </div>
              </main>
              <form class="msger-inputarea">
                <input type="text" class="msger-input" placeholder="Enter your message..." />
                <button onClick={() => { navigation("/chats") }} type="button" class="msger-send-btn">Send</button>
              </form>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};

export default Chats;
