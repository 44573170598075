import { BrowserRouter, Routes, Route } from "react-router-dom";
import SignIn from "../Pages/Authentication/SignIn";
import SignUp from "../Pages/Authentication/SignUp";
import ChangePassword from "../Pages/Authentication/ChangePassword";
import ForgotPassword from "../Pages/Authentication/ForgotPassword";
import MFA from "../Pages/Authentication/MFA";
import BlueGoatDashboard from "../Pages/Authentication/BlueGoat/BlueGoatDashboard";
import MHC from "../Pages/Authentication/BlueGoat/MHC";
import MHCAdmin from "../Pages/Authentication/BlueGoat/MHCAdmin";
import Client from "../Pages/Authentication/BlueGoat/Client";
import Clientinformation from "../Pages/Authentication/BlueGoat/Clientinformation";
import Divisions from "../Pages/Authentication/BlueGoat/Divisions";
import Providers from "../Pages/Authentication/BlueGoat/Providers";
import Businesses from "../Pages/Authentication/BlueGoat/Businesses";
import ThirdParty from "../Pages/Authentication/BlueGoat/ThirdParty";
import Employees from "../Pages/Authentication/BlueGoat/Employees";
import BusinessAdmin from "../Pages/Authentication/BlueGoat/BusinessAdmin";
import EditEmployer from "../Pages/Authentication/BlueGoat/EditEmployer";
import EditThirdParty from "../Pages/Authentication/BlueGoat/EditThirdParty";
import EditMHC from "../Pages/Authentication/BlueGoat/EditMHC";
import Attendance from "../Pages/Authentication/BlueGoat/Attendance";
import EditEmployees from "../Pages/Authentication/BlueGoat/EditEmployees";
import Messages from "../Pages/Authentication/BlueGoat/Messages";
import RoleManagement from "../Pages/Authentication/BlueGoat/RoleManagement";
import TrainingReport from "../Pages/Authentication/Traning/TrainingReport";
import UserTraning from "../Pages/Authentication/Traning/UserTraning";
import Training from "../Pages/Authentication/Traning/Training";
import Module from "../Pages/Authentication/BlueGoat/Module";
import Library from "../Pages/Authentication/Library/Library";
import SurveyReport from "../Pages/Authentication/Survey/SurveyReport";
import AddTraining from "../Pages/Authentication/BlueGoat/AddTraining";
import MHCDashboard from "../Pages/Authentication/MHC/MHCDashboard";
import Chats from "../Pages/Authentication/BlueGoat/Chats";
import Payment from "../Pages/Authentication/BlueGoat/Payment";
import DivisionsDashboard from "../Pages/Authentication/Divisions/DivisionsDashboard";
import AdminDashboard from "../Pages/Authentication/MHC Admin/AdminDashboard";
import TrainingSchedule from "../Pages/Authentication/Traning/TrainingSchedule";
import UserDetails from "../Pages/Authentication/BlueGoat/UserDetails";
import DiagnosticCode from "./../Pages/Authentication/Library/DiagnosticCode";
import AddNewLibrary from "./../Pages/Authentication/Library/AddNewLibrary";
import LibraryManagement from "./../Pages/Authentication/Library/LibraryManagement";
import Survey from "../Pages/Authentication/Survey/Survey";
import UntitledForm from "../Pages/Authentication/Survey/UntitledForm";
import UntitledFormSecond from "../Pages/Authentication/Survey/UntitledFormSecond";
import NarrativeSummary from "../Pages/Authentication/Survey/NarrativeSummary";
import ModuleCreation from "../Pages/Authentication/Survey/ModuleCreation";
import AssessmentForm from "../Pages/Authentication/Survey/AssessmentForm";
import ModuleManagement from "../Pages/Authentication/BlueGoat/ModuleManagement";
import React from 'react';
import UserDetailsView from "../Pages/Authentication/BlueGoat/UserDetailsView";
import SurveM from "../Pages/Authentication/BlueGoat/SurveM";
import SurveyForm from "../Pages/Authentication/Survey/SurveyForm";
import ResetForm from "../Components/ResetForm";
import MdouleViewForm from "../Components/MdouleViewForm";
import ModuleManagmentView from "../Components/ModuleManagmentView";
import ModuleFormViewById from "../Components/ModuleFormViewById";
import ModuleAssigndList from "../Components/ModuleAssignList";
import SurveyAssignedView from "../Components/SurveyAssigendView";
import ModuleManagementViewL from "../Components/ModuleManagmentViewL";
import SurveyViewFillForm from "../Components/SurveyViewFillForm";
import SurveyManagmentView from "../Pages/Authentication/Survey/SurveyManagementView";
import FormViewMhc from "../Components/FormViewMhc";
const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="" element={<SignIn />} />
        <Route path="/sign-up" element={<SignUp />} />
        <Route path="/change-password" element={<ChangePassword />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/mfa" element={<MFA />} />
        <Route path="/bluegoatdashboard" element={<BlueGoatDashboard />} />
        <Route path="/mhc" element={<MHC />} />
        {/* <Route path="/mhc/:id" element={<MHC />} /> */}
        <Route path="/mhcadmin" element={<MHCAdmin />} />
        <Route path="/client" element={<Client />} />
        <Route path="/client-information" element={<Clientinformation />} />
        <Route path="/divisions" element={<Divisions />} />
        <Route path="/providers" element={<Providers />} />
        <Route path="/thirdparty" element={<ThirdParty />} />
        <Route path="/businesses" element={<Businesses />} />
        <Route path="/employees" element={<Employees />} />
        <Route path="/businessadmin" element={<BusinessAdmin />} />
        <Route path="/edit-employer" element={<EditEmployer />} />
        <Route path="/edit-ThirdParty" element={<EditThirdParty />} />
        <Route path="/edit-MHC" element={<EditMHC />} />
        <Route path="/edit-employees" element={<EditEmployees />} />
        <Route path="/attendance" element={<Attendance />} />
        <Route path="/message" element={<Messages />} />
        <Route path="/chats" element={<Chats />} />
        <Route path="/role-management" element={<RoleManagement />} />
        <Route path="/training-report" element={<TrainingReport />} />
        <Route path="/training" element={<Training />} />
        <Route path="/survey-report" element={<SurveyReport />} />
        <Route path="/module" element={<Module />} />
        <Route path="/library" element={<Library />} />
        <Route path="/add-training" element={<AddTraining />} />
        <Route path="/mhcdashboard" element={<MHCDashboard />} />
        <Route path="/divisionsdashboard" element={<DivisionsDashboard />} />
        <Route path="/admindashboard" element={<AdminDashboard />} />
        <Route path="/training-schedule" element={<TrainingSchedule />} />
        <Route path="/user-traning" element={<UserTraning />} />
        <Route path="/user-details/name/:name" element={<UserDetails />} />
        <Route path="/diagnostic-code" element={<DiagnosticCode />} />
        <Route path="/addnew-library" element={<AddNewLibrary />} />
        <Route path="/library-management" element={<LibraryManagement />} />
        {/* <Route path="/survey" element={<Survey />} /> */}
        <Route path="/survey" element={<SurveM />} />
        <Route path="/survey/management/view/:id" element={<SurveyManagmentView />} />
        <Route path="/survey/assign/view/:id" element={<SurveyAssignedView/>} />
        <Route path="/untitled-form" element={<UntitledForm />} />
        <Route path="/untitled-form-second" element={<UntitledFormSecond />} />
        <Route path="/narrative-summary" element={<NarrativeSummary />} />
        <Route path="/module-creation" element={<ModuleCreation />} />
        <Route path="/assessment-form" element={<AssessmentForm />} />
        <Route path="/assessment-form/list/:id" element={<ModuleAssigndList />} />
        <Route path="/assessment-form/:id" element={<AssessmentForm />} />
        <Route path="/assessment-form/viewId/:id" element={<MdouleViewForm/>} />
        
        <Route path="/assessment-form/view/:id" element={<ModuleManagmentView/>} />
        <Route path="/assessment-form/view/mlist/:id" element={<ModuleManagementViewL/>} />
        <Route path="/assessment-form-survey" element={<SurveyForm/>} />
        <Route path="/assessment-form-survey/viewF/:id" element={<SurveyViewFillForm/>} />
        <Route path="/user-details/:name/:id" element={<UserDetails/>} />
        <Route path="/user-details-mhc/:name/:id" element={<FormViewMhc/>} />
        <Route path="/user-details/view/:id" element={<UserDetailsView />} />
        <Route path="/module-details/:id" element={<MdouleViewForm/>} />
        <Route path="/module-management" element={<ModuleManagement />} />
        <Route path="/payment" element={<Payment />} />
        <Route path="/reset" element={<ResetForm />} />
      </Routes>
    </BrowserRouter>
  );
}

export default Router;