import React, { useState} from 'react';
import Sidebar from '../../../Components/Sidebar';
import Navbar from '../../../Components/Navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faEnvelope, faAddressCard, faCartShopping } from '@fortawesome/free-solid-svg-icons';
import mastercard from './../../../assets/mastercard.png';
import paypal from './../../../assets/paypal.png';
import visa from './../../../assets/visa.png';
function Payment() {
    //   sidebar start
    const [sidemenu, setSidemenu] = useState(false);
    const handleToggleSidemenu = () => {
        console.log("working");
        setSidemenu(!sidemenu);
    };
    //   side end
    return (
        <>
            <div className='main-section'>
                <div className={sidemenu ? '' : 'sidebar mob-resp'} >
                    <Sidebar />
                </div>
                <div className='right-side'>
                    <Navbar navbarname={"Payment"} setSidemenu={handleToggleSidemenu} />
                    <div style={{ marginTop: "22px" }} class="row">
                        <div class="col-75">
                            <div class="container">
                                <form action="/action_page.php">
                                    <div class="row">
                                        <div class="col-50">
                                            <h3>Billing Address</h3>
                                            <label for="fname"><FontAwesomeIcon icon={faUser} />Full Name</label>
                                            <input type="text" id="fname" name="firstname" placeholder="John M. Doe" />
                                            <label for="email"><FontAwesomeIcon icon={faEnvelope} /> Email</label>
                                            <input type="text" id="email" name="email" placeholder="john@example.com" />
                                            <label for="adr"><FontAwesomeIcon icon={faAddressCard} /> Address</label>
                                            <input type="text" id="adr" name="address" placeholder="542 W. 15th Street" />
                                            <label for="fname">Accepted Cards</label>
                                            <div class="icon-container">
                                                <img src={mastercard} alt="some text" />
                                                <img src={paypal} alt="some text" />
                                                <img src={visa} alt="some text" />
                                            </div>
                                        </div>

                                        <div class="col-50">
                                            <h3>Payment</h3>
                                            <label for="ccnum">Credit card number</label>
                                            <input type="text" id="ccnum" name="cardnumber" placeholder="1111-2222-3333-4444" />
                                            <label for="expmonth">Exp Month</label>
                                            <input type="text" id="expmonth" name="expmonth" placeholder="September" />
                                            <div class="row">
                                                <div class="col-50">
                                                    <label for="expyear">Exp Year</label>
                                                    <input type="text" id="expyear" name="expyear" placeholder="2018" />
                                                </div>
                                                <div class="col-50">
                                                    <label for="cvv">CVV</label>
                                                    <input type="text" id="cvv" name="cvv" placeholder="352" />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <input type="submit" value="Continue to checkout" class="btn" />
                                </form>
                            </div>
                        </div>
                        <div class="col-25">
                            <div class="container" style={{ background: "#00B3EB" }}>
                                <h3 style={{ textAlign: "center", color: "#fff" }}>Payment Settings</h3>
                                <h4 style={{ color: "#fff" }}>Basic Plan</h4>
                                <hr />
                                <label>
                                    <input type="checkbox" name="sameadr" /> <a href="monthly-subscription.html">Monthly </a><span class="price">$15</span>

                                </label>
                                <label>
                                    <input type="checkbox" name="sameadr" /> <a href="monthly-subscription.html">Quarterly </a><span class="price">$149 </span>
                                </label>
                                <label>
                                    <input type="checkbox" name="sameadr" /> <a href="monthly-subscription.html">Annual  </a><span class="price">$579 </span>
                                </label>
                                <h4 style={{ color: "#fff" }}> Cart <span class="price" ><FontAwesomeIcon icon={faCartShopping} /> <b>4</b></span></h4>
                                
                                <hr />
                                <p style={{ color: "#fff" }}>Total <span class="price" ><b>$30</b></span></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Payment;
