
import logo from './../../assets/logo.png';
import frame3 from './../../assets/frame3.png';
import './../../App.css';
import React from 'react';
function MFA() {
  return (
    <div className='main-section'>
        <div className='sign-left' >
        <div className='nav-mobile'>
          <img src={logo} alt="some text" />
        </div>
          <div className='sign'>
            <div className='sign-text'>
            <h2 style={{marginTop:"154px"}}>Multi-factor authentication</h2>
            </div>
          <div className='email-area' style={{marginTop:"24px"}}>
            <p>Your account is secured using multi-factor authentication(MFA).
              TO finish signing in, turn on or view your MFA device and type the 
              authentication code below.</p>
            <label style={{ fontSize: '14px',fontWeight:"500",marginTop:"20px" }}>Email: testing@bluegoat.com</label>
            <input type="tel" placeholder='Enter your MFA Code here'/>
            <button className='sign-button' style={{marginTop:"15px"}}>Submit</button>
          </div>
          </div>
        </div>
        <div className='sign-right'>
        <img className='img-logo' src={logo} alt="some text" />
        <img className='img-sign' style={{width:"47%",marginBottom:"14px"}} src={frame3} alt="some text" />
        </div>
    </div>
  );
}

export default MFA;
