import axios from 'axios';

const axiosInstance = axios.create({
  baseURL:process.env.REACT_APP_API_BASE_URL,
  // timeout: 5000, // Adjust the timeout as needed
});

axiosInstance.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem('token');
      if (token) {
        config.headers.authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  
  export default axiosInstance;