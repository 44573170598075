import React, { useState } from 'react';
import Sidebar from '../../../Components/Sidebar';
import Navbar from '../../../Components/Navbar';


const Module = () => {
    //   sidebar start
    const [sidemenu, setSidemenu] = useState(false);
    const handleToggleSidemenu = () => {
        console.log("working");
        setSidemenu(!sidemenu);
    };
    //   side end
    return (
        <>
            <div className='main-section'>
                <div className={sidemenu ? '' : 'sidebar mob-resp'} >
                    <Sidebar />
                </div>
                <div className='right-side'>
                    <Navbar navbarname={"Module"} setSidemenu={handleToggleSidemenu} />
                    <div className='module-text'>
                        <h4>Manage Questionnaire</h4>
                        <div style={{ display: "flex" }}>
                            <div style={{ width: "117px" }}>
                                <p>Created:</p>
                                <p>Modified:</p>
                                <p>Completed:</p>
                                <p>Narrative Created:</p>
                                <p>Narrative Modified:</p>
                            </div>
                            <div>
                                <p>05/29/2019  20:14:55 by Sonja OMalley: </p>
                                <p>05/29/2019  20:14:55 by Sonja OMalley: </p>
                                <p>05/29/2019  20:14:55 by Sonja OMalley: </p>
                                <p>05/29/2019  20:14:55 by Sonja OMalley: </p>
                                <p>05/29/2019  20:14:55 by Sonja OMalley: </p>
                            </div>
                        </div>
                    </div>
                    <div className='module-buttons'>
                        <button className='progress-btn'>Add Progress Note</button>
                        <button className='progress-btn' style={{ background: "#27AE60" }}>Add Treatment Plan</button>
                        <button className='progress-btn' style={{ background: "#8E44AD" }}>Add Assessment</button>
                    </div>
                    <div className='module-box'>
                        <div style={{ minWidth: "770px" }}>
                            <div style={{ display: "flex", width: "100%" }}>
                                <div style={{ width: "23%" }}>
                                    <h4>Document Type</h4>
                                    <p>Assessment 1</p>
                                    <p>Treatment Plan</p>
                                    <p>Discharge Plan</p>
                                </div>
                                <div style={{ width: "35%" }}>
                                    <h4>Provider and Credentials</h4>
                                    <p style={{ marginLeft: "15px" }}>John Havenar LCDC</p>
                                    <p style={{ marginLeft: "15px" }}>John Havenar LCDC</p>
                                    <p style={{ marginLeft: "15px" }}>Evelyn Jones RN</p>
                                </div>
                                <div style={{ width: "22%" }}>
                                    <h4>Completed</h4>
                                    <p>6/4/2019 02:07 PM</p>
                                    <p>6/4/2019 04:00 PM</p>
                                    <p>6/4/2019 04:00 PM</p>
                                </div>
                                <div style={{ width: "20%" }}>
                                    <h4>User</h4>
                                    <p>Mike Mints </p>
                                    <p>Breandon Smithit </p>
                                    <p>David James</p>
                                </div>
                            </div>
                            <div style={{ display: "flex", width: "100%" }}>
                                <div style={{ width: "23%" }}>
                                    <h4>Progress Notes</h4>
                                    <p>Group Note</p>
                                    <p>Individual Note</p>
                                    <p>Psychiatric Note</p>
                                    <p>Nurse Note</p>
                                    <p>Group Note</p>
                                </div>
                                <div style={{ width: "35%" }}>
                                    <h4>Provider and Credentials</h4>
                                    <p style={{ marginLeft: "15px" }}>John Havenar LCDC</p>
                                    <p style={{ marginLeft: "15px" }}>John Havenar LCDC</p>
                                    <p style={{ marginLeft: "15px" }}>Evelyn Jones RN</p>
                                    <p style={{ marginLeft: "15px" }}>Dr Qureshi MD</p>
                                    <p style={{ marginLeft: "15px" }}>Evelyn Jones RN</p>
                                </div>
                                <div style={{ width: "22%" }}>
                                    <h4>Completed</h4>
                                    <p>6/4/2019 02:07 PM</p>
                                    <p>6/4/2019 04:00 PM</p>
                                    <p>6/4/2019 04:00 PM</p>
                                    <p>6/4/2019 04:00 PM</p>
                                    <p>6/4/2019 04:00 PM</p>
                                </div>
                                <div style={{ width: "20%" }}>
                                    <h4>User</h4>
                                    <p>Mike Mints </p>
                                    <p>Breandon Smithit </p>
                                    <p>David James</p>
                                    <p>Breandon Smithit </p>
                                    <p>David James</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Module;
