import React, { useState } from 'react';
import Sidebar from '../../../Components/Sidebar';
import Navbar from '../../../Components/Navbar';

const DiagnosticCode = () => {
    //   sidebar start
    const [sidemenu, setSidemenu] = useState(false);
    const handleToggleSidemenu = () => {
        console.log("working");
        setSidemenu(!sidemenu);
    };
    //   side end
    return (
        <>
            <div className='main-section'>
                <div className={sidemenu ? '' : 'sidebar mob-resp'} >
                    <Sidebar />
                </div>
                <div className='right-side'>
                    <Navbar navbarname={"Library"} setSidemenu={handleToggleSidemenu} />
                    <div className='library-diagnostic'>
                        <h1>Diagnostic Code</h1>
                        <h4>F32.9 Major depressive disorder, single episode</h4>
                        <p>"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                            incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                            exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                            </p> <p>"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                            incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                            exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                            incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                            exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                        <div>
                            <h3>Strategy:</h3>
                            <textarea className='textarea-library'
                                name="textarea"
                                rows="5"
                                cols="30"
                                placeholder="Enter Strategy Here..."></textarea>
                            <h3>Objectives:</h3>
                            <textarea className='textarea-library'
                                name="textarea"
                                rows="5"
                                cols="30"
                                placeholder="Enter Objectives Here..."></textarea>
                                 <div style={{ display: "flex", justifyContent: "end", margin:"18px auto" }}>
                                    <button className='view-btn'>Save</button>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DiagnosticCode;
