import React, { useState, useRef, useEffect } from 'react';
import GenderSelection from './GenderSelection';
// import Form from 'react-bootstrap/Form';
//import Button from 'react-bootstrap/Button';
import Maskgroup from '../assets/Ellipse8.png';
import { Button, Checkbox, Form, Select, Spin, Upload, notification } from 'antd';
import axiosInstance from '../services/axiosInstance';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { UploadOutlined } from '@ant-design/icons';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';


function ProfileMHC({ id, routeName }) {
    console.log(id,'idfffff')

    // const [date, setDate] = useState('');
    const [imageSrc, setImageSrc] = useState(null);
    const [imageUrl, setImageUrl] = useState(null);
    const dateInputRef = useRef(null);
    const [loading, setLoading] = useState(false)
    const [role, setRole] = useState()
    const [portal, setPortal] = useState()
    // const handleChange = (e) => {
    //     setDate(e.target.value);
    // };
    const navigate = useNavigate()
    const [form] = Form.useForm();
    const [isChecked, setIsChecked] = useState(false);

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };
    const fileInputRef = useRef(null);
    const location = useLocation();
    const idd = location.state;
// const {id}=useParams()
// console.log(id)
    // const handleFileChange = (event) => {
    //     const file = event.target.files[0];

    //     if (file) {
    //         const reader = new FileReader();
    //         reader.onloadend = () => {
    //             setImageSrc(reader.result);
    //         };
    //         reader.readAsDataURL(file);
    //     }
    // };
    const handleChange = (info) => {
        if (info.file.status === 'done') {
            getBase64(info.file.originFileObj, (url) => {
                setLoading(false);
                setImageUrl(url); // Set the base64 encoded image URL in state
            });
        }
    };
    const handleFinish = async (value) => {
        setLoading(true)
        const payload = {
            ...value,
            permission: [
                {
                    portal: "662bebaccffacccebf785b9d",
                    // "portalName": "Admission",
                    permission: false,
                    // "__v": 0
                },
                {
                    portal: "662bebc9cffacccebf785ba0",
                    // "portalName": "Intake/Legal",
                    permission: false,
                    // "__v": 0
                },
                {
                    portal: "662bebd7cffacccebf785ba3",
                    // "portalName": "Physician Order",
                    permission: false,
                    // "__v": 0
                },
                {
                    portal: "662bebe4cffacccebf785ba6",
                    // "portalName": "Assessments/ Evalutions",
                    permission: false,
                    // "__v": 0
                },
                {
                    portal: "662bebf6cffacccebf785ba9",
                    // "portalName": "Physician note",
                    permission: false,
                    // "__v": 0
                },
                {
                    portal: "662bec2fcffacccebf785bac",
                    // "portalName": "THERAPY TAB Individual Note",
                    permission: false,
                    // "__v": 0
                },
                {
                    portal: "662bec3ccffacccebf785baf",
                    // "portalName": "THERAPY TAB Group Note",
                    permission: false,
                    // "__v": 0
                },
                {
                    portal: "662bec4acffacccebf785bb2",
                    // "portalName": "Discharge Plan",
                    permission: false,
                    // "__v": 0
                },
                {
                    portal: "662bec56cffacccebf785bb5",
                    // "portalName": "Medication Treatment",
                    permission: false,
                    // "__v": 0
                },
                {
                    portal: "662bec65cffacccebf785bb8",
                    // "portalName": "Group Progress Note",
                    permission: false,
                    // "__v": 0
                },
                {
                    portal: "662bec76cffacccebf785bbb",
                    // "portalName": "VITAL SIGNS TAB",
                    permission: false,
                    // "__v": 0
                },
                {
                    portal: "662bec81cffacccebf785bbe",
                    // portalName: "MHC Management",
                    permission: true,
                    // "__v": 0
                },
                {
                    portal: "662bec91cffacccebf785bc1",
                    // "portalName": "MHC Admin Management",
                    permission: true,
                    // "__v": 0
                },
                {
                    portal: "662bec9ccffacccebf785bc4",
                    // "portalName": "Divisions Management",
                    permission: false,
                    // "__v": 0
                },
                {
                    portal: "662beca8cffacccebf785bc7",
                    // "portalName": "Divisions Admin Management",
                    permission: false,
                    // "__v": 0
                },
                {
                    portal: "662becb3cffacccebf785bca",
                    // "portalName": "Providers Management",
                    permission: false,
                    // "__v": 0
                },
                {
                    portal: "662bfd05cffacccebf785bce",
                    // "portalName": "Client Management",
                    permission: false,
                    // "__v": 0
                },
                {
                    portal: "662bfd14cffacccebf785bd1",
                    // "portalName": "Third-Party Management",
                    permission: false,
                    // "__v": 0
                },
                {
                    portal: "662bfd2dcffacccebf785bd5",
                    // "portalName": "Business Management",
                    permission: false,
                    // "__v": 0
                },
                {
                    portal: "662bfd37cffacccebf785bd8",
                    // "portalName": "Employees Management",
                    permission: false,
                    // "__v": 0
                }
            ]
        }
        // console.log(payload,'payload')

        const updatapayload = { ...value }
        try {
            if (id) {
                const res = await axiosInstance.patch(`user/updateMhc/${id}`, updatapayload)
                if (res) {
                    setLoading(false)
                    notification.success({
                        message: ' Update Successfully',
                        // description: 'Welcome to BlueGoatDashboard!',
                    });
                    form.resetFields()
                    navigate('/MHC')
                }


            } else {


                const res = await axiosInstance.post('user/createMhc', payload)
                if (res) {
                    setLoading(false)
                    notification.success({
                        message: `${routeName} Created Successfully`,
                        // description: 'Welcome to BlueGoatDashboard!',
                    });
                    form.resetFields()
                    navigate(`/${routeName}`);
                }
            }

        } catch (error) {
            const e=error.response.data.message
            setLoading(false)
            notification.error({
                message: `${e}`||'Creation Fail',
                // description: 'Welcome to BlueGoatDashboard!',
            });

        }

    }
    const getUserById = async () => {
        try {

            const res = await axiosInstance.get(`user/userById/${id}`)
            const data = res?.data?.data
            form.setFieldsValue(data);
        } catch (error) {
            console.log(error)

        }

    }
    // const getAllPortal = async () => {
    //     try {

    //         const res = await axiosInstance.get(`portal`)
    //         const data = res?.data
    //         setPortal(data)

    //         // form.setFieldsValue(data);
    //     } catch (error) {
    //         console.log(error)

    //     }

    // }
    // const getAllRole = async () => {
    //     try {

    //         const res = await axiosInstance.get(`roles`)
    //         const data = res?.data
    //         console.log(data, 'role data')
    //         // setPortal(data)

    //         // form.setFieldsValue(data);
    //     } catch (error) {
    //         console.log(error)

    //     }

    // }
    const getmhcById = async () => {
        try {

            const res = await axiosInstance.get(`user/getMhc/${id}`)
            const data = res?.data?.data
            // setRole(data)
            // console.log(data, 'role data by id')
            // setPortal(data)

            form.setFieldsValue(data);
        } catch (error) {
            console.log(error)

        }

    }
    useEffect(() => {
        if (id) {

            getmhcById()
        }
        // getAllPortal()
        // getAllRole()
        // getRoleById()
    }, [])
    return (
        <Form onFinish={handleFinish} form={form}>
            <Spin size='large' spinning={loading}>


                <div className='ContactInformation'>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <Upload
                            // accept="image/*"
                            name="avatar"
                            listType="picture-circle"
                            className="avatar-uploader"
                            onChange={handleChange}
                            maxCount={1} // Limit to upload only one file
                            fileList={[]} // Pass empty fileList to remove default file list display
                            showUploadList={false} // Hide default upload list display
                        >
                            {/* <Button icon={<UploadOutlined />}>Upload Image</Button>
                     */}
                            {imageUrl ? (
                                <img
                                    src={imageUrl}
                                    alt="avatar"
                                    style={{
                                        width: '100%',
                                    }}
                                />
                            ) : (
                                <button
                                style={{
                                    border: 0,
                                    background: 'none',
                                }}
                                type="button"
                            >
                                <PlusOutlined />
                                <div
                                    style={{
                                        marginTop: 8,
                                    }}
                                >
                                    Upload
                                </div>
                            </button>
                            )}
                            
                        </Upload>
                        {/* <img className='upload-img' src={imageSrc || Maskgroup} alt="some text" />
                        <input
                            type="file"
                            accept="image/*"
                            style={{ display: 'none' }}
                            ref={fileInputRef}
                            onChange={handleFileChange}
                        /> */}
                    </div>
                </div>
                <div style={{ display: "flex" }}>
                    <div style={{ width: "50%", }}>
                        {/* <div className='email-area create-inputs employe-inputs'>
                            <div className='First-Name'>
                                <p>First Name</p>
                                <Form.Item name='firstName'>

                                    <input type='text' />
                                </Form.Item>
                            </div>
                            <div className='First-Name'>
                                <p>Last Name</p>
                                <Form.Item name='lastName'>

                                    <input type='text' />
                                </Form.Item>
                            </div>
                        </div> */}
                        <div className='email-area employe-inputs'>
                            <p>Company Name </p>
                            <Form.Item name='companyName'>

                                <input  />
                            </Form.Item>
                        </div>
                        <div className='email-area employe-inputs'>
                            <p>Department:</p>
                            <Form.Item name='Department'>

                                <input  />
                            </Form.Item>
                        </div>
                        <div className='email-area employe-inputs'>
                            <p>Mobile Phone (Personal):</p>
                            <Form.Item name='mobilePhone'>

                                <input type='text' value="MikeMints@gmail.com" />
                            </Form.Item>
                        </div>
                        <div className='email-area employe-inputs'>
                            <p>company Phone Number:</p>
                            <Form.Item name='companyPhoneNumber'>

                                <input type='text' value="MikeMints9910" />
                            </Form.Item>
                        </div>
                        <div className='email-area employe-inputs'>
                            <p>Company Address (Physical) including City :state zip code</p>
                            <Form.Item >

                                <input type='text'  />
                            </Form.Item>
                        </div>

                        <div className='email-area employe-inputs'>
                            <p>Billing Address:</p>
                            <Form.Item name='billingAddress'>

                                <input type='text'/>
                            </Form.Item>
                        </div>
                        <div className='email-area employe-inputs'>
                            <p>Executive Assistant (EA) / Adminstrative Assistanat Email:</p>
                            <Form.Item name='eaEmail'>

                                <input type='text' value="Abc" />
                            </Form.Item>
                        </div>


                    </div>
                    <div style={{ width: "50%" }}>

                        {
                            routeName === 'MHC' ? <div className='email-area employe-inputs'>
                                <p>Company Officer Name:</p>
                                <Form.Item name='companyOfficerName'>

                                    <input type='text' value="Blue Goat" />
                                </Form.Item>
                            </div> : <div className='email-area employe-inputs'>
                                <p> Select Company</p>
                                <Form.Item name='associatedWith' key="associatedWith">

                                    {/* <input type='text' value="Blue Goat" /> */}
                                    <Select
                                        placeholder="Please Enter Your Company"
                                        style={{ width: "100%", height: "50px", border: '1px solid rgba(0, 0, 0, 0.50)', borderRadius: "13px" }}>
                                        {role?.map(
                                            (value) => (
                                                <Select.Option style={{ borderRadius: '10px' }}
                                                    key={value._id}
                                                    value={value._id}
                                                >
                                                    {`${value.firstName} ${value.lastName}`}
                                                </Select.Option>
                                            )
                                        )}
                                    </Select>
                                </Form.Item>
                            </div>
                        }


                        <div className='email-area create-inputs employe-inputs'>
                            <div className='First-Name'>
                                <p>Primary Email:</p>
                                <Form.Item name='primaryEmail'>

                                    <input type='email' value="James" />
                                </Form.Item>
                            </div>
                            <div className='First-Name'>
                                <p>Seconday Email:</p>
                                <Form.Item name='secondaryEmail'>

                                    <input type='email' value="112200" />
                                </Form.Item>
                            </div>
                        </div>
                        <div className='email-area employe-inputs'>
                            <p>Mobile Phone (Company):</p>
                            <Form.Item name=''>

                                <input type='text' value="33333" />
                            </Form.Item>
                        </div>
                        <div className='email-area employe-inputs'>
                            <p>Extension</p>
                            <Form.Item name='extension'>

                                <input type='text' value="blue" />
                            </Form.Item>
                        </div>
                        {
                            !id && <div className='email-area employe-inputs'>
                                <p>Company Address</p>
                                <Form.Item name='companyAddress'>

                                    <input type='text'  />
                                </Form.Item>
                            </div>
                        }

                        {/* <div className='email-area employe-inputs'>
                        <p>Ethnicity</p>
                        <Form.Item name='ethnicity'>
                        <input type='text'  />

                        </Form.Item>
                    </div> */}
                        <div className='email-area employe-inputs'>
                            <p>Executive Assistant (EA) / Adminstrative Assistanat Name:</p>
                            <Form.Item name='eaName'>

                                <input type='text' value="2114411$" />
                            </Form.Item>
                        </div>
                        <div className='email-area employe-inputs'>
                            <p>Executive Assistant (EA) / Adminstrative Assistanat Mobile Number :</p>
                            <Form.Item name='eaPhoneNumber'>

                                <input
                                    type='text'
                                    value="www.abc.com"
                                />
                            </Form.Item>
                        </div>
                    </div>
                </div>
                        <div className='email-area employe-inputsmhc'>
                            <p>Executive Assistant (EA) / Adminstrative Assistanat Company Mobile Number : </p>
                            <Form.Item name='eaCompanyPhoneNumber'>
                                <input type='text' style={{width:'87%'}}/>

                            </Form.Item>
                        </div>
                
                    <div className="profile-btn">
                        <Button className='sign-button' htmlType='submit'>Save </Button>
                    </div>
            </Spin>
        </Form>
    );
};

export default ProfileMHC;
