import React, { useState, useRef, useEffect } from 'react';
import GenderSelection from './GenderSelection';
// import Form from 'react-bootstrap/Form';
//import Button from 'react-bootstrap/Button';
import Maskgroup from '../assets/Ellipse8.png';
import { Button, Checkbox, Form, Select, Spin, Upload, notification } from 'antd';
import axiosInstance from '../services/axiosInstance';
import { useLocation, useNavigate } from 'react-router-dom';
import { UploadOutlined } from '@ant-design/icons';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { FaCaretDown } from 'react-icons/fa';


function ProfileComponent({ id, routeName }) {
    console.log(routeName)
    // const [date, setDate] = useState('');
    const [imageSrc, setImageSrc] = useState(null);
    const [imageUrl, setImageUrl] = useState(null);
    const dateInputRef = useRef(null);
    const [loading, setLoading] = useState(false)
    const [role, setRole] = useState()
    const [portal, setPortal] = useState()
    // const handleChange = (e) => {
    //     setDate(e.target.value);
    // };
    const navigate = useNavigate()
    const [form] = Form.useForm();
    const [isChecked, setIsChecked] = useState(false);
    const user=localStorage.getItem('user')
    const getUser = JSON.parse(user)
    
    const roleName=getUser?.roles?.role
    const companyname=getUser?.company?.companyName

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };
    const fileInputRef = useRef(null);
    const location = useLocation();
    const idd = location.state;

    // const handleFileChange = (event) => {
    //     const file = event.target.files[0];

    //     if (file) {
    //         const reader = new FileReader();
    //         reader.onloadend = () => {
    //             setImageSrc(reader.result);
    //         };
    //         reader.readAsDataURL(file);
    //     }
    // };
    const handleChange = (info) => {
        if (info.file.status === 'done') {
            getBase64(info.file.originFileObj, (url) => {
                setLoading(false);
                setImageUrl(url); // Set the base64 encoded image URL in state
            });
        }
    };
    const handleFinish = async (value) => {
        setLoading(true)
        let rolid
        if(routeName==='MHCAdmin'){
            rolid='5'

        } else if(routeName==='divisions'){
            rolid='12'
        } else if(routeName==='providers'){
            rolid='7'
        }
         else if(routeName==='client-information'){
            rolid='9'
        }
         else if(routeName==='thirdparty'){
            rolid='8'
        }
        
         else if(routeName==='businesses'){
            rolid='10'
        }
         else if(routeName==='businessadmin'){
            rolid='11'
        }
         else if(routeName==='employees'){
            rolid='15'
        }
        
       
        const payload = {
            ...value, roleId: rolid,
            permission: [
                {
                    portal: "662bebaccffacccebf785b9d",
                    // "portalName": "Admission",
                    permission: false,
                    // "__v": 0
                },
                {
                    portal: "662bebc9cffacccebf785ba0",
                    // "portalName": "Intake/Legal",
                    permission: false,
                    // "__v": 0
                },
                {
                    portal: "662bebd7cffacccebf785ba3",
                    // "portalName": "Physician Order",
                    permission: false,
                    // "__v": 0
                },
                {
                    portal: "662bebe4cffacccebf785ba6",
                    // "portalName": "Assessments/ Evalutions",
                    permission: false,
                    // "__v": 0
                },
                {
                    portal: "662bebf6cffacccebf785ba9",
                    // "portalName": "Physician note",
                    permission: false,
                    // "__v": 0
                },
                {
                    portal: "662bec2fcffacccebf785bac",
                    // "portalName": "THERAPY TAB Individual Note",
                    permission: false,
                    // "__v": 0
                },
                {
                    portal: "662bec3ccffacccebf785baf",
                    // "portalName": "THERAPY TAB Group Note",
                    permission: false,
                    // "__v": 0
                },
                {
                    portal: "662bec4acffacccebf785bb2",
                    // "portalName": "Discharge Plan",
                    permission: false,
                    // "__v": 0
                },
                {
                    portal: "662bec56cffacccebf785bb5",
                    // "portalName": "Medication Treatment",
                    permission: false,
                    // "__v": 0
                },
                {
                    portal: "662bec65cffacccebf785bb8",
                    // "portalName": "Group Progress Note",
                    permission: false,
                    // "__v": 0
                },
                {
                    portal: "662bec76cffacccebf785bbb",
                    // "portalName": "VITAL SIGNS TAB",
                    permission: false,
                    // "__v": 0
                },
                {
                    portal: "662bec81cffacccebf785bbe",
                    // portalName: "MHC Management",
                    permission: true,
                    // "__v": 0
                },
                {
                    portal: "662bec91cffacccebf785bc1",
                    // "portalName": "MHC Admin Management",
                    permission: true,
                    // "__v": 0
                },
                {
                    portal: "662bec9ccffacccebf785bc4",
                    // "portalName": "Divisions Management",
                    permission: false,
                    // "__v": 0
                },
                {
                    portal: "662beca8cffacccebf785bc7",
                    // "portalName": "Divisions Admin Management",
                    permission: false,
                    // "__v": 0
                },
                {
                    portal: "662becb3cffacccebf785bca",
                    // "portalName": "Providers Management",
                    permission: false,
                    // "__v": 0
                },
                {
                    portal: "662bfd05cffacccebf785bce",
                    // "portalName": "Client Management",
                    permission: false,
                    // "__v": 0
                },
                {
                    portal: "662bfd14cffacccebf785bd1",
                    // "portalName": "Third-Party Management",
                    permission: false,
                    // "__v": 0
                },
                {
                    portal: "662bfd2dcffacccebf785bd5",
                    // "portalName": "Business Management",
                    permission: false,
                    // "__v": 0
                },
                {
                    portal: "662bfd37cffacccebf785bd8",
                    // "portalName": "Employees Management",
                    permission: false,
                    // "__v": 0
                }
            ]
        }
     
        

        const updatapayload = { ...value, roleId: rolid }
        try {
            if (id) {
                const res = await axiosInstance.patch(`user/updateUser/${id}`, updatapayload)
                if (res) {
                    setLoading(false)
                    if (routeName) {
                        notification.success({
                            message: `${routeName} Updated Successfully`,
                            // description: 'Welcome to BlueGoatDashboard!',
                        });
                    } else {
                        notification.success({
                            message: 'Updated Successfully',
                            // description: 'Welcome to BlueGoatDashboard!',
                        });
                    }
                    form.resetFields()
                    navigate(`/${routeName}`)
                }


            } else {


                const res = await axiosInstance.post('user/sign-up', payload)
                if (res) {
                    setLoading(false)
                    if(routeName){

                        notification.success({
                            message: `${routeName} created  Successful`,
                            // description: 'Welcome to BlueGoatDashboard!',
                        });
                        form.resetFields()
                        navigate(`/${routeName}`);
                    }else{
                        notification.success({
                            message: 'Created Successfully',
                            // description: 'Welcome to BlueGoatDashboard!',
                        });
                    }
                }
            }

        } catch (error) {
            console.log(error,'error')
            setLoading(false)
            notification.error({
                message:`${error?.response?.data?.message}` ||' Not Created  Successful',
                // description: 'Welcome to BlueGoatDashboard!',
            });

        }

    }
    const getUserById = async () => {
        try {

            const res = await axiosInstance.get(`user/userById/${id}`)
            const data = res?.data?.data
            form.setFieldsValue(data);
        } catch (error) {
            console.log(error)

        }

    }
    const getsingleMhcAdmin = async () => {
        try {

            const res = await axiosInstance.get(`api/user/getByRole/${id}`)
            const data = res?.data
            setPortal(data)

            form.setFieldsValue(data);
        } catch (error) {
            console.log(error)

        }

    }
    const getAllRole = async () => {
        try {

            const res = await axiosInstance.get(`roles`)
            const data = res?.data
            console.log(data, 'role data')
            // setPortal(data)

            // form.setFieldsValue(data);
        } catch (error) {
            console.log(error)

        }

    }
    const getRoleById = async () => {
        try {

            const res = await axiosInstance.get(`user/getMhc`)
            const data = res?.data?.data
            setRole(data)
            // setPortal(data)

            // form.setFieldsValue(data);
        } catch (error) {
            console.log(error)

        }


    }
    useEffect(() => {
        if (id) {
          

            getUserById()
        }
        // getAllPortal()
        getAllRole()
        getRoleById()
    }, [])
    return (
        <Form onFinish={handleFinish} form={form}>
            <Spin size='large' spinning={loading}>


                <div className='ContactInformation'>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <Upload
                            // accept="image/*"
                            name="avatar"
                            listType="picture-circle"
                            className="avatar-uploader"
                            onChange={handleChange}
                            maxCount={1} // Limit to upload only one file
                            fileList={[]} // Pass empty fileList to remove default file list display
                            showUploadList={false} // Hide default upload list display
                        >
                            {/* <Button icon={<UploadOutlined />}>Upload Image</Button>
                     */}
                            {imageUrl ? (
                                <img
                                    src={imageUrl}
                                    alt="avatar"
                                    style={{
                                        width: '100%',
                                    }}
                                />
                            ) : (
                                <button
                                style={{
                                    border: 0,
                                    background: 'none',
                                }}
                                type="button"
                            >
                                <PlusOutlined />
                                <div
                                    style={{
                                        marginTop: 8,
                                    }}
                                >
                                    Upload
                                </div>
                            </button>
                            )}
                            
                        </Upload>
                        {/* <img className='upload-img' src={imageSrc || Maskgroup} alt="some text" />
                        <input
                            type="file"
                            accept="image/*"
                            style={{ display: 'none' }}
                            ref={fileInputRef}
                            onChange={handleFileChange}
                        /> */}
                    </div>
                </div>
                <div style={{ display: "flex" }}>
                    <div style={{ width: "50%", }}>
                        <div className='email-area create-inputs employe-inputs'>
                            <div className='First-Name'>
                                <p>First Name</p>
                                <Form.Item name='firstName'>

                                    <input type='text' />
                                </Form.Item>
                            </div>
                            <div className='First-Name'>
                                <p>Last Name</p>
                                <Form.Item name='lastName'>

                                    <input type='text' />
                                </Form.Item>
                            </div>
                        </div>
                        <div className='email-area employe-inputs'>
                            <p>Phone Number </p>
                            <Form.Item name='phoneNumber'>

                                <input type="tel" id="phoneNumber" name="phoneNumber" value="232423214" />
                            </Form.Item>
                        </div>
                        <div className='email-area employe-inputs'>
                            <p>Address</p>
                            <Form.Item name='address'>

                                <input type='text' name="address" value="Abc" />
                            </Form.Item>
                        </div>
                        <div className='email-area employe-inputs'>
                            <p>Email</p>
                            <Form.Item name='email'>

                                <input type='email' value="MikeMints@gmail.com" />
                            </Form.Item>
                        </div>
                        <div className='email-area employe-inputs'>
                            <p>Username</p>
                            <Form.Item name='username'>

                                <input type='text'  />
                            </Form.Item>
                        </div>
                        <div className='email-area employe-inputs'>
                            <p>Religion</p>
                            <Form.Item name='religion'>

                                <input type='text'  />
                            </Form.Item>
                        </div>

                        <div className='email-area employe-inputs'>
                            <p>Terms:</p>
                            <Form.Item name='terms'>

                                <input type='text' value="Abc" />
                            </Form.Item>
                        </div>


                    </div>
                    <div style={{ width: "50%" }}>

                        {
                            routeName === 'MHC' ? <div className='email-area employe-inputs'>
                                <p>Company</p>
                                <Form.Item name='company'>

                                    <input type='text' value="Blue Goat" disabled={roleName==='BG Admin'?false:true}/>
                                </Form.Item>
                            </div> : <div className='email-area employe-inputs'>
                                <p> Select Company</p>
                                <Form.Item name='company' key="company" initialValue={roleName==="BG Admin"?'':getUser?.company?._id}>

                                    {/* <input type='text' value="Blue Goat" /> */}
                                    <Select
                                     suffixIcon={<FaCaretDown style={{fontSize:"25px",color:"#000000"}}/>}
                                    initialValue={roleName==="BG Admin"?'':getUser?.company?._id}
                                    disabled={roleName==='BG Admin'?false:true}
                                        placeholder="Please Enter Your Company"
                                        style={{ width: "100%", height: "50px", borderRadius: "13px" }}>
                                        {role?.map(
                                            (value) => (
                                                <Select.Option style={{ borderRadius: '10px' }}
                                                    key={value._id}
                                                    value={value._id}
                                                >
                                                    {value.companyName}
                                                </Select.Option>
                                            )
                                        )}
                                    </Select>
                                </Form.Item>
                            </div>
                        }


                        <div className='email-area create-inputs employe-inputs'>
                            <div className='First-Name'>
                                <p>Insurance provider</p>
                                <Form.Item name='insuranceProvider'>

                                    <input type='text' value="James" />
                                </Form.Item>
                            </div>
                            <div className='First-Name'>
                                <p>Insurance Member Id</p>
                                <Form.Item name='insuranceMemberId'>

                                    <input type='text' value="112200" />
                                </Form.Item>
                            </div>
                        </div>
                        <div className='email-area employe-inputs'>
                            <p>Insurance Company Id</p>
                            <Form.Item name='insuranceCompanyId'>

                                <input type='text' value="33333" />
                            </Form.Item>
                        </div>
                        <div className='email-area employe-inputs'>
                            <p>Title</p>
                            <Form.Item name='title'>

                                <input type='text' value="blue" />
                            </Form.Item>
                        </div>
                        {
                            !id && <div className='email-area employe-inputs'>
                                <p>Password</p>
                                <Form.Item name='password'>

                                    <input type='Password'  />
                                </Form.Item>
                            </div>
                        }

                        {/* <div className='email-area employe-inputs'>
                        <p>Ethnicity</p>
                        <Form.Item name='ethnicity'>
                        <input type='text'  />

                        </Form.Item>
                    </div> */}
                        <div className='email-area employe-inputs'>
                            <p>Payment Stutas</p>
                            <Form.Item name='paymentStatus'>

                                <input type='text' value="2114411$" />
                            </Form.Item>
                        </div>
                        <div className='email-area employe-inputs'>
                            <p>Website:</p>
                            <Form.Item name='website'>

                                <input
                                    type='text'
                                    value="www.abc.com"
                                />
                            </Form.Item>
                        </div>
                        <div className='email-area employe-inputs'>
                            <p>Ethnicity</p>
                            <Form.Item name='ethnicity'>
                                <input type='text' />

                            </Form.Item>
                        </div>
                    </div>
                </div>
                <div>
                    <div className='date-section ' style={{ margin: "40px auto" }}>
                        <div style={{ display: "flex", gap: "20px" }}>
                            <label style={{ fontSize: "14px", fontWeight: "500", marginTop: '4px' }} htmlFor="countrySelect">Gender</label>
                            <form style={{ display: "flex", gap: "14px" }} >
                                <Form.Item name="gender">
                                    <select style={{ border: "none", color: "#0041C4" }}
                                    // id="countrySelect"
                                    // value={value}
                                    // onChange={handleChange}
                                    // className="custom-select"
                                    // name={name}
                                    >
                                        <option value="">-- Select --</option>
                                        <option value="Male"> <span role="img" aria-label="Smiling face with smiling eyes">👨</span> Male</option>
                                        <option value="Female"> <span role="img" aria-label="Smiling face with smiling eyes">👩</span> Female</option>
                                    </select>

                                    {/* <GenderSelection /> */}
                                </Form.Item>
                            </form>
                        </div>
                        <div style={{ display: "flex", gap: "20px" }}>
                            <label style={{ fontSize: "14px", fontWeight: "500", marginTop: "3px" }} htmlFor="countrySelect">Date of Birth</label>
                            <Form.Item name='dateOfBirth'>

                                <input style={{ color: "#0041C4", border: "none" }}
                                    type="date"
                                    ref={dateInputRef} />
                            </Form.Item>
                        </div>
                        <div style={{ display: "flex", gap: "20px" }}>
                            <label style={{ fontSize: "14px", fontWeight: "500" }}>Status:</label>
                            <Form.Item valuePropName="checked" initialValue={false} name='status'>

                                <Checkbox style={{ fontSize: "14px", fontWeight: "500" }}

                                />
                            </Form.Item>
                        </div>


                    </div>
                    <div className="profile-btn">
                        <Button className='sign-button' htmlType='submit'>Update </Button>
                    </div>
                </div>
            </Spin>
        </Form>
    );
};

export default ProfileComponent;
