import React from 'react';
//import { useState } from 'react'
import Ellipse9 from './../assets/Ellipse9.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

function Notifications({ setIsVisible }) {

    const handleClose = () => {
        setIsVisible(false);
    };
    return (
        <>
            <div className='blur-background'>
                <div className='notification'>
                    <div className='header'>
                        <header>Notifications (2)</header>
                        <FontAwesomeIcon style={{ cursor: "pointer" }} icon={faTimes} onClick={handleClose} />
                    </div>
                    <hr style={{ margin: "0" }} />
                    <div style={{ overflow: "scroll", height: "400px" }}>
                        <div className='Avatar-area'>
                            <img src={Ellipse9} alt="some text" />
                            <div className='Avatar-text'>
                                <h3>Ashley Edward</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sequi, aliquam aliquid!</p>
                                <p style={{ color: "#767676" }}>23m ago</p>
                            </div>
                        </div>
                        <div className='Avatar-area'>
                            <img src={Ellipse9} alt="some text" />
                            <div className='Avatar-text'>
                                <h3>Ashley Edward</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sequi, aliquam aliquid!</p>
                                <p style={{ color: "#767676" }}>23m ago</p>
                            </div>
                        </div>
                        <div className='Avatar-area'>
                            <img src={Ellipse9} alt="some text" />
                            <div className='Avatar-text'>
                                <h3>Ashley Edward</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sequi, aliquam aliquid!</p>
                                <p style={{ color: "#767676" }}>23m ago</p>
                            </div>
                        </div>
                        <div className='Avatar-area'>
                            <img src={Ellipse9} alt="some text" />
                            <div className='Avatar-text'>
                                <h3>Ashley Edward</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sequi, aliquam aliquid!</p>
                                <p style={{ color: "#767676" }}>23m ago</p>
                            </div>
                        </div>
                        <div className='Avatar-area'>
                            <img src={Ellipse9} alt="some text" />
                            <div className='Avatar-text'>
                                <h3>Ashley Edward</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sequi, aliquam aliquid!</p>
                                <p style={{ color: "#767676" }}>23m ago</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default Notifications;