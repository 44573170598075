import React, { useState } from 'react';
import Sidebar from '../../../Components/Sidebar';
import Navbar from '../../../Components/Navbar';
import BasicTab from '../../../Components/BaiscTab';
import BasicTab2 from '../../../Components/BaiscTab2';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import Admin from '../../../Components/Admin';
import downloadimg from '../../../assets/download-img.png';

library.add(faUser);

const TrainingReport = () => {
  //   sidebar start
  const [sidemenu, setSidemenu] = useState(false);
  const handleToggleSidemenu = () => {
    console.log("working");
    setSidemenu(!sidemenu);
  };
  //   side end
  return (
    <>
      <div className='main-section'>
        <div className={sidemenu ? '' : 'sidebar mob-resp'} >
          <Sidebar />
        </div>
        <div className='right-side'>
          <Navbar navbarname={"Training Report"} setSidemenu={handleToggleSidemenu} />
          <BasicTab />
          <BasicTab2 />
          <Admin name={'Name'} email={'Email'} />
          <div className='download-report'>
            <img src={downloadimg} alt="some text" />
            <p>Download Report</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default TrainingReport;
