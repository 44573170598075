import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import axiosInstance from '../services/axiosInstance';
import { Button, Form, Select, notification } from 'antd';
import { useNavigate } from 'react-router-dom';
import { FaCaretDown } from 'react-icons/fa';
//import { RiEyeFill, RiEyeOffFill } from 'react-icons/ri';

const namesWithIcons = [
    { name: 'Mental Health Company' },
    { name: 'Client' },
    { name: 'Provider' },
    // { name: 'Checkboxes', icon: faSquareCheck },
    // Add more names with corresponding icons
];
function AddNewSurvey({ setIsVisible, handleGetAll }) {
    const [type,setType]=useState()
    // const [password, setPassword] = useState('');
    // const [showPassword, setShowPassword] = useState(false);
    const [form] = Form.useForm()
    const navigate = useNavigate()

    // const handleTogglePassword = () => {
    //   setShowPassword((prevShowPassword) => !prevShowPassword);
    // };

    const handleClose = () => {
        setIsVisible(false);
    };

    const handleCreateModule = async (value) => {
        try {
            const res = await axiosInstance.post(`survey/createSurvey`, value)
            if (res && res.data) {

                // handleGetAll(res.data)
                // handleClose()

                navigate(`/assessment-form-survey`, { state: { data: res?.data } })
                notification.success({
                    message: 'Survey Create Successfuly',
                    // description: 'Welcome to BlueGoatDashboard!',
                });
            }

        } catch (error) {
            notification.error({
                message: 'Survey not Created ',
                // description: 'Welcome to BlueGoatDashboard!',
            });

        }

        // handleGetAll()
    }
    const getAllType= async ()=>{
        try {
            const res=await axiosInstance.get("moduleType")
            const data=res?.data?.data
            setType(data)
            
        } catch (error) {
            console.log (error)
            
        }
       

    }
    useEffect(()=>{
        getAllType()

    },[])
    return (
        <Form form={form} onFinish={handleCreateModule}>
            <div className='blur-background'>
                <div className='new-user'>
                    <div className='header'>
                        <h4>Add Survey</h4>
                        <FontAwesomeIcon style={{ cursor: "pointer" }} icon={faTimes} onClick={handleClose} />
                    </div>
                    <hr style={{ margin: "0" }} />
                    <div>
                        <div className='email-area employe-inputs'>
                            
                            <div className='user-inputs'>
                                <p> Survey Form Name</p>
                                <Form.Item name='name'>

                                    <input type='text' placeholder='Please enter your Module Name' />
                                </Form.Item>
                            </div>
                            <div className='user-inputs'>
                                <p>Description</p>
                                <Form.Item name='description'>

                                    <input type='text' placeholder='Please enter your Description' />
                                </Form.Item>
                            </div>
                            <div style={{ display: "flex", justifyContent: "center", gap: "11px", margin: "25px auto" }}>
                                <button onClick={handleClose} style={{ width: "35%", height: "42px" }} className='cancel-btn'>Cancel</button>
                                <Button style={{ width: "35%", height: "42px" }} className='apply-btn' htmlType='submit'>Create</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </Form>
    );
};

export default AddNewSurvey;
