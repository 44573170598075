import React, { useState } from 'react';
import Sidebar from '../../../Components/Sidebar';
import Ellipse8 from './../../../assets/Ellipse8.png';
import Ellipse3 from './../../../assets/Ellipse3.png';
//import Ellipse2 from './../../../assets/Ellipse2.png';
import Ellipse9 from './../../../assets/Ellipse9.png';
import Navbar from '../../../Components/Navbar';
import { useNavigate } from 'react-router-dom';

const Messages = () => {
    const navigate = useNavigate();
    const signIn = (data) => {
      navigate(data);
    }
    //   sidebar start
  const [sidemenu, setSidemenu] = useState(false);
  const handleToggleSidemenu = () => {
      console.log("working");
      setSidemenu(!sidemenu);
  };
  //   side end
  return (
    <>
        <div className='main-section'>
             <div className={sidemenu ? '' : 'sidebar mob-resp'} >
            <Sidebar />
        </div>
        <div className='right-side'>
            <Navbar  navbarname={"Messages"} setSidemenu={handleToggleSidemenu}/>
            <div style={{display:"flex", margin:"40px",gap:"11px"}}>
                <button className='Add-MHC' style={{backgroundColor:"#00C495"}}>
                    New  Messages (115)
                </button>
                <button className='Add-MHC'style={{backgroundColor:"#D03A3A", width:"110px"}}>
                    Unread (28)
                </button>
            </div>
            <div className='message-area'>
                <div style={{width:"65%",display:"flex", gap:"18px"}}>
                    <img src={Ellipse8} alt="some text" />
                    <div className='ellipsis-text'>
                        <h4>Mike Mints </h4>
                        <p>Hi ! We are educational instituions. We are looking for a treatment for our school childrens and management course at
                        the present...... </p>
                    </div>
                </div>
                <button onClick={()=>{signIn("/chats")}} className='view-btn'>  View</button>
            </div>
            <div className='message-area'>
                <div style={{width:"65%",display:"flex", gap:"18px"}}>
                    <img src={Ellipse3} alt="some text" />
                    <div className='ellipsis-text'>
                        <h4>Joe Victor </h4>
                        <p>Hi ! We are educational instituions. We are looking for a treatment for our school childrens and management course at
                        the present...... </p>
                    </div>
                </div>
                <button onClick={()=>{signIn("/chats")}} className='view-btn'>  View</button>
            </div>
            <div className='message-area'>
                <div style={{width:"65%",display:"flex", gap:"18px"}}>
                    <img src={Ellipse9} alt="some text" />
                    <div className='ellipsis-text'>
                        <h4>Breandon Smithit </h4>
                        <p>Hi ! We are educational instituions. We are looking for a treatment for our school childrens and management course at
                        the present...... </p>
                    </div>
                </div>
                <button onClick={()=>{signIn("/chats")}} className='view-btn'>  View</button>
            </div>
            <div className='message-area'>
                <div style={{width:"65%",display:"flex", gap:"18px"}}>
                    <img src={Ellipse3} alt="some text" />
                    <div className='ellipsis-text'>
                        <h4>Joe Victor</h4>
                        <p>Hi ! We are educational instituions. We are looking for a treatment for our school childrens and management course at
                        the present...... </p>
                    </div>
                </div>
                <button onClick={()=>{signIn("/chats")}} className='view-btn'>  View</button>
            </div>
            <div className='message-area'>
                <div style={{width:"80%",display:"flex", gap:"18px"}}>
                    <img src={Ellipse9} alt="some text" />
                    <div className='ellipsis-text'>
                        <h4>Mike Mints </h4>
                        <p>Hi ! We are educational instituions. We are looking for a treatment for our school childrens and management course at
                        the present...... </p>
                    </div>
                </div>
                <button onClick={()=>{signIn("/chats")}} className='view-btn'>  View</button>
            </div>
        
        </div>
    </div>
    </>
  );
};

export default Messages;
