import React, { useState } from 'react';
import Sidebar from '../../../Components/Sidebar';
import Navbar from '../../../Components/Navbar';
import ProfileComponent from '../../../Components/ProfileComponent';
import { useParams } from 'react-router-dom';
import ProfileComponentView from '../../../Components/ProfileComponentView';

function UserDetailsView (){
 //   sidebar start
 const [sidemenu, setSidemenu] = useState(false);
 const handleToggleSidemenu = () => {
     console.log("working");
     setSidemenu(!sidemenu);
 };
 //   side end
 const {id}=useParams()
  return (
    <>
        <div className='main-section'>
             <div className={sidemenu ? '' : 'sidebar mob-resp'} >
            <Sidebar />
        </div>
        <div className='right-side'>
            <Navbar  navbarname={""} setSidemenu={handleToggleSidemenu}/>
            <ProfileComponentView id={id}/>
            
        </div>
    </div>
    </>
  );
};

export default UserDetailsView;
