import { Button, Form } from 'antd';
import React, { useState } from 'react';
import { FaLock, FaUnlock } from 'react-icons/fa';
import axiosInstance from '../services/axiosInstance';
import { useNavigate } from 'react-router-dom';

// PasswordInput component with integrated lock icon
const PasswordInput = ({ label, value, onChange }) => {
  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <label>
      {label}:
      <div style={{ position: "relative" }}>
        <input
          type={passwordVisible ? 'text' : 'password'}
          value={value}
          onChange={onChange}
        />
        <span onClick={togglePasswordVisibility} style={{ cursor: 'pointer', position: "absolute", top: "10px", right: "21px" }}>
          {passwordVisible ? <FaUnlock /> : <FaLock />}
        </span>
      </div>
    </label>
  );
};

const ChangePasswordForm = () => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');

  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordVisiblet, setPasswordVisiblet] = useState(false);
const navigate=useNavigate()
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };
  const togglePasswordVisibilityt = () => {
    setPasswordVisiblet(!passwordVisiblet);
  };
  const handleChangePassword = () => {
    if (newPassword !== confirmNewPassword) {
      alert("New password and confirm password don't match");
      return;
    }

    // console.log('Changing password:', oldPassword, 'to', newPassword);
    // Add your password change logic here
  };
 const handleSubmit= async (value)=>{
  if (value.newPassword !== value.confirmPassword
    ) {
    alert("New password and confirm password don't match");
    return;
  }
  try {
    const res =await axiosInstance.patch('user/loginResetPassword',value)
    if(res && res.data){
     navigate("/reset")
    }
    
} catch (error) {
  console.log('error')
    
}


 }
  return (
    <Form style={{ margin: "40px auto" }} onFinish={handleSubmit}>
      {/* <PasswordInput
        label={<label style={{ fontSize: '14px',fontWeight:"500" }}>Old Password</label>}
        value={oldPassword}
        onChange={(e) => setOldPassword(e.target.value)}
      /> */}
      <br />
      <label>
        <label style={{ fontSize: '14px', fontWeight: "500" }}> New Password</label>
        <Form.Item name="newPassword">

        <div style={{ position: "relative" }}>
          <input
            type={passwordVisible ? 'text' : 'password'}
          // value={value}
          // onChange={onChange}
          />
          <span onClick={togglePasswordVisibility} style={{ cursor: 'pointer', position: "absolute", top: "10px", right: "29px" }}>
            {passwordVisible ? <FaUnlock /> : <FaLock />}
          </span>
        </div>
        </Form.Item>
      </label>

      {/* <PasswordInput
        label={<label style={{ fontSize: '14px',fontWeight:"500" }}>New Password</label>}
        value={newPassword}
        onChange={(e) => setNewPassword(e.target.value)}
      /> */}
      <br />

      {/* <PasswordInput
        label={<label style={{ fontSize: '14px', fontWeight: "500" }}>Confirm New Password</label>}
        value={confirmNewPassword}
        onChange={(e) => setConfirmNewPassword(e.target.value)}
      /> */}
      <label>
        <label style={{ fontSize: '14px', fontWeight: "500" }}>Confirm New Password</label>
          <Form.Item name="confirmPassword">
          <div style={{ position: "relative" }}>
          <input
            type={passwordVisiblet ? 'text' : 'password'}
          // value={value}
          // onChange={onChange}
          />
          <span onClick={togglePasswordVisibilityt} style={{ cursor: 'pointer', position: "absolute", top: "10px", right: "29px" }}>
            {passwordVisiblet ? <FaUnlock /> : <FaLock />}
          </span>
        </div>
          </Form.Item>
       
      </label>
      <br />
      <div style={{ display: "flex",  gap: "20px" }}>
        {/* <button className='close-btn' >close</button> */}
        <Button className='password-btn' htmlType='submit'>Submit</Button>
      </div>

    </Form>
  );
};

export default ChangePasswordForm;
